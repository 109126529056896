import Cookies from 'js-cookie'
import type { NextPageContext } from 'next'
import { parseReqCookies } from './parseReqCookie'

export const getCookie = (key: string, req?: NextPageContext['req']) =>
  req ? parseReqCookies(req)[key] : Cookies.get(key)

export const removeCookie = (key: string) => typeof window !== 'undefined' && Cookies.remove(key)

export const setCookie = (key: string, val: string) =>
  typeof window !== 'undefined' && Cookies.set(key, val)
