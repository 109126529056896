import { GraphQLClient, Variables } from 'graphql-request'
import { API_URL } from 'config'
import { TypedDocumentNode } from 'urql'

const api = new GraphQLClient(API_URL)

interface Options {
  token?: string
}

// https://github.com/jasonkuhrt/graphql-request/blob/HEAD/examples/typescript-typed-document-node.ts
export const request = async <T = unknown>(
  query: string | TypedDocumentNode<T>,
  variables: Variables,
  { token }: Options
): Promise<T> => {
  return api.request<T>({
    document: query,
    variables,
    requestHeaders: {
      authorization: `Bearer ${token}`
    }
  })
}

export const publicRequest = async <T>(
  query: string | TypedDocumentNode<T>,
  variables?: Variables
): Promise<T> => {
  return api.request(query, variables)
}
