import { ImageAsset, PostAsset, ResizedAssetFormat } from 'graphql/types'
import { LAYOUT_BREAKPOINTS } from 'lib/theme'
import { makeAutoObservable } from 'mobx'
import { Post } from './Post'

export interface PostImageAsset extends PostAsset {
  media: ImageAsset
}

interface OptionalProps {
  // currently viewing this asset for post
  viewing: boolean
}

type FeedPostImageAssetParams = PostImageAsset & Partial<OptionalProps>

export interface FeedPostImageAsset extends PostImageAsset, OptionalProps {}
export class FeedPostImageAsset {
  parentPost: Post

  constructor(data: FeedPostImageAssetParams, parent: Post) {
    this.parentPost = parent
    this.media = data.media
    this.ordinal = data.ordinal
    this.viewing = data.viewing ?? false
    makeAutoObservable(this)
  }

  get heightPercentOfWidth() {
    return ((this.media?.originalHeight ?? 0) / (this.media?.originalWidth ?? 0)) * 100 ?? 100
  }
  get heightPercentOfWidthThreads() {
    return ((this.media?.originalHeight ?? 0) / (this.media?.originalWidth ?? 0)) * 100 ?? 100
  }

  mediaFormatsForGrid(width: number) {
    switch (true) {
      // Desktop grid is fixed width/height uses specific small thumb
      case width > LAYOUT_BREAKPOINTS.large:
        return this.media.formats?.find((f) => f.format === ResizedAssetFormat.SMALL_THUMB)
      case width > LAYOUT_BREAKPOINTS.medium:
        return this.media.formats?.find((f) => f.format === ResizedAssetFormat.LARGE_THUMB)
      // Responsive grid uses standard large thumb.
      default:
        return this.media.formats?.find((f) => f.format === ResizedAssetFormat.XSMALL_THUMB)
    }
  }

  mediaSrcForGrid(width: number) {
    const resizeAsset = this.mediaFormatsForGrid(width)
    return resizeAsset?.url ?? this.media.url
  }

  fallbackMediaSrcForGrid(width: number) {
    const resizeAsset = this.mediaFormatsForGrid(width)
    return resizeAsset?.fallbackUrl ?? this.media.fallbackUrl
  }

  setViewing(viewing: boolean) {
    this.viewing = viewing
  }
}
