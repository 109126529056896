import { gql } from 'urql'

export const CORE_PLAN_FIELDS = gql`
  fragment PlanParts on Plan {
    planId
    description
    interval
    duration
    price
    colour
  }
`
