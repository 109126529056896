import { gql } from '@urql/core'
import { Post } from 'graphql/types'

export const TOGGLE_LIKE_POST = gql`
  mutation ToggleLikePost($input: ToggleLikePostInput!) {
    toggleLikePost(input: $input) {
      postId
      isLiked
      likeCount
    }
  }
`

export type ToggleLikePostVars = {
  input: {
    postId: string
    liked: boolean
  }
}

export interface ToggleLikePostResponse {
  toggleLikePost: Pick<Post, 'postId' | 'isLiked' | 'likeCount'>
}
