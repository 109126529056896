import { Network, TokenOptions, TokenSymbol, mapTokensByChainId } from '@nsfw-app/crypto'
import { KnownIcon } from 'common/Icon'
import { Chain } from 'viem'

export const listEnabledTokens = (chain: Chain): TokenOptions[] => {
  // console.log(`listEnabledTokens(${chain.id})`, mapTokensByChainId[chain.id as Network])
  return mapTokensByChainId[chain.id as Network]
}

export const getIconForToken = (symbol?: TokenSymbol): KnownIcon => {
  return `Badge${symbol}` as KnownIcon
}
