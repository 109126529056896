import { TypedDocumentNode } from 'urql'
import { urqlClient } from './client'

export const query = <Resp, Args = null>(
  gql: TypedDocumentNode,
  params?: Args | null,
  client = urqlClient,
) => {
  return client
    ?.query<Resp>(gql, {
      ...params,
      // Fix URQL doing weird shit that causes repeat requests (same gql & params) to hang for ever...
      // TODO: remove URQL for a simple GQL client now that we're using mobx for all state storage.
      key: Math.floor(Math.random() * Date.now()),
    })
    .toPromise()
}
