import { gql } from 'urql'

export const QUERY_MESSAGE_PACKS = gql`
  query QueryMessagePacks {
    messagePacks {
      __typename
      messagePackId
      description
      credits
      title
      description
    }
  }
`
