import { PostAsset, ResizedAssetFormat } from 'graphql/types'
import { LAYOUT_BREAKPOINTS } from 'lib/theme'
import { NSFW_EVENT } from 'lib/tracking/types'
import { autorun, makeAutoObservable, when } from 'mobx'
import { VideoAsset } from '../graphql/types'
import { PaginatedPosts } from './PaginatedPosts'
import { Post } from './Post'

export interface PostVideoAsset extends PostAsset {
  media: VideoAsset
}

interface OptionalProps {
  // currently viewing this asset for post
  viewing: boolean
  lastDuration: number
}

type FeedPostVideoAssetParams = PostVideoAsset & Partial<OptionalProps>

export interface FeedPostVideoAsset extends FeedPostVideoAssetParams {}
export class FeedPostVideoAsset {
  parentPost: Post
  lastDuration: number
  isWatched = false

  constructor(data: FeedPostVideoAssetParams, parent: Post) {
    this.parentPost = parent
    this.media = data.media
    this.ordinal = data.ordinal
    this.viewing = data.viewing ?? false
    this.lastDuration = data.lastDuration ?? 0
    makeAutoObservable(this)
    when(
      () => this.isWatched === true,
      () => {
        const post = this.parentPost
        post.root?.analytics.track(NSFW_EVENT.PLAY_VIDEO_COMPLETE, {
          feedType: post.parentFeed instanceof PaginatedPosts ? post.parentFeed.type : 'PROFILE',
          postId: post.postId
        })
      }
    )
  }

  get heightPercentOfWidth() {
    return ((this.media?.originalHeight ?? 0) / (this.media?.originalWidth ?? 0)) * 100 ?? 100
  }

  mediaFormatsForGrid(width: number) {
    switch (true) {
      // // Desktop grid is fixed width/height uses specific small thumb
      case width > LAYOUT_BREAKPOINTS.large:
        return this.media.formats?.find((f) => f.format === ResizedAssetFormat.SMALL_THUMB)
      case width > LAYOUT_BREAKPOINTS.medium:
        return this.media.formats?.find((f) => f.format === ResizedAssetFormat.LARGE_THUMB)
      // Responsive grid uses standard large thumb.
      default:
        return this.media.formats?.find((f) => f.format === ResizedAssetFormat.XSMALL_THUMB)
    }
  }

  mediaSrcForGrid(width: number) {
    const resizeAsset = this.mediaFormatsForGrid(width)
    return resizeAsset?.url ?? this.media.url
  }

  fallbackMediaSrcForGrid(width: number) {
    const resizeAsset = this.mediaFormatsForGrid(width)
    return resizeAsset?.fallbackUrl ?? this.media.fallbackUrl
  }

  setViewing(viewing: boolean) {
    this.viewing = viewing
  }
  setIsWatched(isWatched: boolean) {
    this.isWatched = isWatched
  }

  setLastDuration(lastDuration: number) {
    this.isWatched = this.lastDuration > lastDuration
    this.lastDuration = lastDuration
  }
}
