import { makeAutoObservable } from 'mobx'
import { Earnings } from 'graphql/types'
import { DataStore } from 'interfaces'
import { GqlApiError } from 'lib/gql/GqlApiError'

export class UserEarningsStore implements DataStore<Earnings> {
  private _isFetching = false
  private _data?: Earnings = undefined
  private _error?: GqlApiError = undefined

  constructor(data: Partial<UserEarningsStore>) {
    if (data.isFetching) this.isFetching = data.isFetching
    makeAutoObservable(this)
  }

  get isFetching() {
    return this._isFetching
  }

  set isFetching(v: boolean) {
    this._isFetching = v
  }

  get data() {
    return this._data
  }

  set data(earnings: Earnings | undefined) {
    this._data = earnings
    if (earnings) this.isFetching = false
  }

  get error() {
    return this._error
  }

  set error(error: GqlApiError | undefined) {
    this._error = error
    if (error) this.isFetching = false
  }
}
