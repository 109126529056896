import { gql } from 'urql'
import { CORE_POST_ASSET_FIELDS } from './postAssets.fragment'

export const CORE_PRODUCT_FIELDS = gql`
  ${CORE_POST_ASSET_FIELDS}
  fragment ProductParts on ProductUnion {
    ... on MessagePackProduct {
      __typename
      type
      creator {
        displayName
        username
        bio
        avatar {
          url
        }
      }
      pack {
        messagePackId
        credits
        title
        description
      }
    }
    ... on SubscriptionProduct {
      __typename
      type
      creator {
        displayName
        username
        bio
        avatar {
          url
        }
      }
      plan {
        # title
        interval
        duration
      }
      # this is returning null for some subscriptions
      subscription {
        createdAt
        expiresAt
      }
    }
    ... on WithdrawalProduct {
      __typename
      type
      creator {
        displayName
        username
        bio
        avatar {
          url
        }
      }
    }
    ... on ExclusivePostProduct {
      __typename
      type
      post {
        postId
        description
        asset {
          ...PostAssetParts
        }
      }
      creator {
        displayName
        username
        bio
        avatar {
          url
        }
      }
    }
    ... on TipProduct {
      __typename
      type
      post {
        postId
        description
        asset {
          ...PostAssetParts
        }
      }
      creator {
        displayName
        username
        bio
        avatar {
          url
        }
      }
    }
    ... on DonationProduct {
      __typename
      type
      creator {
        displayName
        username
        bio
        avatar {
          url
        }
      }
    }
  }
`
