import { SCREENS } from './constants'

export type ActiveScreen = (typeof SCREENS)[keyof typeof SCREENS]

export enum PaymentOptions {
  TIP_POST = 'tip:post',
  TIP_PROFILE = 'tip:profile',
  SUBSCRIBE_PROFILE = 'subscribe:profile',
  EXCLUSIVE_POST = 'exclusive-post',
  MESSAGE_PACK = 'message-pack'
}
