import { gql } from 'urql'

// Note: these counts are an expensive query
export const PROFILE_STAT_FIELDS = gql`
  fragment ProfileStatFields on Profile {
    followersCount
    activeSubscriberCount
    followingCount
    postsCount
  }
`
