import { TokenSymbol } from '@nsfw-app/crypto'
import { toast } from 'components/common/Toast'
import { PaymentOptions } from 'components/modals/PaymentModal/types'
import { BigNumberish } from 'ethers'
import { PaymentStatus } from 'graphql/types'
import { makeAutoObservable } from 'mobx'
import { RootStore } from './RootStore'
import { TransactionCTA, TransactionStatus } from './transaction.store'
import { Account } from 'viem'

export class ContractStore {
  root: RootStore

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
  }

  /**
   * Returns signers token balance
   */
  async getTokenBalance(tokenSymbol: TokenSymbol): Promise<BigNumberish> {
    const network = this.root.wallets.network
    const signerAddress = this.root.wallets.connected?.address

    if (!network) throw new Error('No connected network found')
    if (!signerAddress) throw new Error('No signer address found')

    const { contract } = await this.root.contractRegistry.instance(tokenSymbol, network)

    try {
      // @ts-ignore
      const balance = await contract.read.balanceOf([signerAddress])
      console.log('getTokenBalance()', balance)
      return balance
    } catch (err) {
      console.error('unable to get token balance', err)
      return 0n
    }
  }

  /**
   * Send the transaction to the blockchain
   */
  async transfer() {
    const order = this.root.transaction.order
    if (!order) throw new Error('No current transaction order found')

    // const { gasLimit, gasPrice } = this.root.transaction.transferEstimate || {}
    // if (!(gasLimit && gasPrice)) throw new Error('No transfer estimate available')

    console.debug('Attempting transfer....')

    this.root.transaction.setCheckoutState({
      isProcessing: true,
      buttonLabel: TransactionCTA.PENDING_USER_CONFIRMATION,
      status: TransactionStatus.PENDING_USER_CONFIRMATION
    })

    // ---- PREPARE
    const account = this.root.wallets.connected?.address as `0x${string}` | Account
    if (!account) throw new Error('No connected account found')

    const chain = this.root.wallets.network
    if (!chain) throw new Error('No connected network found')
    const { client, address, abi, wallet } = await this.root.contractRegistry.instance(
      order.token.symbol,
      chain
    )

    try {
      // START -----
      const { request } = await client.simulateContract({
        address,
        abi,
        functionName: 'transfer',
        args: [order.recipient, this.root.transaction.estimatedTokens],
        account
        // maxFeePerGas: gasLimit
      })

      console.log('rawTxn')
      console.log(request)

      const transactionHash = await wallet.writeContract(request)

      try {
        const paymentMutationsInput = {
          orderId: order.orderId,
          transactionHash: transactionHash
        }
        await this.root.transaction.recordPayment(paymentMutationsInput)

        this.root.transaction.setCheckoutState({
          isProcessing: true,
          buttonLabel: TransactionCTA.PROCESSING,
          status: TransactionStatus.SENDING_TOKENS,
          transactionHash: transactionHash
        })

        // provider.on('poll', this.root.transaction.setNumberOfConfirmations)
        // await provider.waitForTransaction(transactionHash, 1)

        await client.waitForTransactionReceipt({
          hash: transactionHash,
          confirmations: 1
        })

        /**
         * !!! IMPORTANT !!!
         * - must be awaited before completing checkout
         * - otherwise post.isVisible is false (specifically confirmPayment)
         */
        const payment = await this.root.transaction.confirmPayment(paymentMutationsInput)

        if (payment?.status !== PaymentStatus.FLAGGED) {
          if (this.root.transaction.paymentType === PaymentOptions.MESSAGE_PACK) {
            this.root.user.fetchMessageCredits()
          }

          if (this.root.transaction.paymentType === PaymentOptions.SUBSCRIBE_PROFILE) {
            this.root.user.fetchSubscribersList()
          }
        }

        this.root.transaction.setCheckoutState({
          isProcessing: false,
          isCompleted: true,
          status:
            payment?.status === PaymentStatus.FLAGGED
              ? TransactionStatus.FLAGGED
              : TransactionStatus.CONFIRMED,
          buttonLabel: TransactionCTA.CONFIRMED,
          numberOfConfirmations: this.root.transaction.REQUIRED_CONFIRMATIONS
        })
      } catch (error) {
        console.error('Error confirming payment', error)
        const msg = 'Something went wrong confirming your payment. Please contact support.'

        toast(msg)
        this.root.transaction.setCheckoutState({
          isProcessing: false,
          buttonLabel: TransactionCTA.ERROR,
          status: TransactionStatus.ERROR
        })
      }
    } catch (error) {
      const message = 'Failed to send transaction. Please try again or contact support.'

      toast({
        message,
        type: 'error'
      })
      this.root.transaction.setCheckoutState({
        isProcessing: false,
        buttonLabel: TransactionCTA.PAY,
        status: TransactionStatus.AWAITING_PAYMENT
      })
    }

    // Stop listening to polling events -> used to retrieve number of confirmations
    // provider.off('poll')
  }

  async approveAllowance() {
    console.log('approveAllowance()')
    console.log('approveAllowance()')
    console.log('approveAllowance()')
    const order = this.root.transaction.order
    if (!order) throw new Error('No current transaction order found')

    console.debug('Attempting to approve allowances')

    this.root.transaction.setCheckoutState({
      isProcessing: true,
      buttonLabel: TransactionCTA.AWAITING_APPROVAL
    })

    const account = this.root.wallets.connected?.address as `0x${string}` | Account
    if (!account) throw new Error('No connected account found')

    const chain = this.root.wallets.network
    if (!chain) throw new Error('No connected network found')
    const { address, abi, wallet, client } = await this.root.contractRegistry.instance(
      order.token.symbol,
      chain
    )

    try {
      const { request } = await client.simulateContract({
        address,
        abi,
        functionName: 'approve',
        args: [order.recipient, this.root.transaction.estimatedTokens],
        account
      })

      console.log('rawTxn')
      console.log(request)

      const transactionHash = await wallet.writeContract(request)

      this.root.transaction.setCheckoutState({
        isProcessing: true,
        isApprovingTokens: true,
        buttonLabel: TransactionCTA.APPROVING_TOKENS,
        status: TransactionStatus.APPROVING_TOKENS
      })

      // https://viem.sh/docs/actions/public/waitForTransactionReceipt.html#waitfortransactionreceipt
      await client.waitForTransactionReceipt({
        hash: transactionHash,
        confirmations: 1
      })

      this.root.transaction.setCheckoutState({
        isProcessing: false,
        isApprovingTokens: false,
        hasRequiredAllowance: true,
        buttonLabel: TransactionCTA.PAY,
        status: TransactionStatus.AWAITING_PAYMENT
      })
    } catch (error) {
      const message = 'Failed to send transaction. Please try again or contact support.'

      toast({
        message,
        type: 'error'
      })
      this.root.transaction.setCheckoutState({
        isProcessing: false,
        buttonLabel: TransactionCTA.APPROVE_TOKENS
      })
    }
  }

  async allowance(
    tokenSymbol: TokenSymbol,
    recipient: Account,
    signer: Account
  ): Promise<BigNumberish> {
    const chain = this.root.wallets.network
    if (!chain) throw new Error('No connected network found')
    const { contract } = await this.root.contractRegistry.instance(tokenSymbol, chain)

    try {
      // @ts-ignore
      const allowance = await contract.read.allowance([signer, recipient])
      console.log('allowance()', allowance)
      return allowance
    } catch (err) {
      console.log(err)
      return 0n
    }
  }
}
