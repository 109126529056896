import { gql } from 'urql'

export const REQUEST_APPROVAL = gql`
  mutation RequestApproval {
    requestApproval {
      approvalId
      approvalStatus
      applicationUrl
      createdAt
      uid
    }
  }
`
