import { gql } from '@urql/core'

export const FOLLOW_PROFILE = gql`
  mutation FollowProfile($profileId: String!) {
    followProfile(profileId: $profileId) {
      isFollowing
    }
  }
`

export type FollowProfileVars = {
  profileId: string
}
