import { gql } from '@urql/core'
import {
  CORE_POST_WITH_ASSET_FORMATS_FIELDS,
  CORE_PROFILE_FIELDS,
  PROFILE_STAT_FIELDS
} from 'graphql/fragments'
import { Profile } from 'models/Profile'

export const VIEW_PROFILE = gql`
  ${CORE_PROFILE_FIELDS}
  ${PROFILE_STAT_FIELDS}
  ${CORE_POST_WITH_ASSET_FORMATS_FIELDS}
  query ViewProfile(
    $username: String
    $postFilter: PostFilter
    $postOptions: PaginationOptions
  ) {
    viewProfile(username: $username) {
      tags
          {
            tagId
            value
            key
            slug
          }
      ...ProfileParts
      ...ProfileStatFields
      posts(filter: $postFilter, options: $postOptions) {

        ... on PaginatedResult {
          __typename
          cursor
          hasNextPage
          items {

            ... on Post {

              ...PostWithAssetFormats
            }
          }
        }
      }
    }
  }
`

export interface ViewProfileResponse {
  viewProfile: Profile
}
