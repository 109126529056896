import { gql } from 'graphql-request'
import { Account } from 'graphql/types'

export const ONBOARDING_FIELDS = gql`
  fragment OnboardingParts on AccountMetadata {
    onboarding {
      ... on CreatorOnboarding {
        __typename
        hasVerifiedEmail
        setUsername
        setDisplayName
        acceptedTerms
        completedOnboarding
        approvals {
          uid
          approvalStatus
          createdAt
          applicationUrl
          requestedAt
          approvalId
          provider
        }
        setupMessaging
        updatedBio
        uploadedAvatar
        createdSubscriptionPlan
        uploadedFirstPost
        firstWithdrawal
        updatedBio
      }
      ... on FanOnboarding {
        __typename
        uploadedAvatar
        updatedBio
        hasLinkedWallet
        firstLike
        firstFollow
        firstTip
        firstExclusivePost
        firstSubscription
        updatedBio
      }
    }
  }
`

export const ME_ONBOARDING = gql`
  ${ONBOARDING_FIELDS}
  query QueryMeOnboarding {
    me {
      metadata {
        ...OnboardingParts
      }
    }
  }
`
export const QUERY_ME_REFERRALS = gql`
  query QueryReferrals($options: PaginationOptions) {
    me {
      referrals(options: $options) {
        hasNextPage
        cursor
        items {
          ... on Referral {
            __typename
            referralId
            type
            status
            rewards
            startedAt
            rewardAddress
            rewardAmountUSD
            transactionHash
            completedAt
            profile {
              username
              displayName
              avatar {
                url
              }
            }
          }
        }
      }
    }
  }
`

export const QUERY_ME_SUBSCRIBERS_LIST = gql`
  query QueryMeSubscribersList {
    me {
      metadata {
        subscribedProfiles
      }
    }
  }
`

export interface MeResponse {
  me: Account
}
