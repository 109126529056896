import { toast } from 'components/common/Toast'
import { REFERRAL_CODE } from 'config'
import { ErrorCodes } from 'config/errorCodes'
import { ProfileSettingsScreen } from 'containers/ProfileSettingsModal/constants'
import { QUERY_MESSAGE_PACKS } from 'graphql/queries/messagePacks'
import { MessagePack } from 'graphql/types'
import { gqlRequest } from 'lib/gql/gqlRequest'
import { isBrowser } from 'lib/nextjs'
import { NSFW_EVENT } from 'lib/tracking/types'
import { AbstractUploader } from 'lib/uploader'
import { makeAutoObservable, reaction } from 'mobx'
import { Button } from 'primitives/Button'
import { Text } from 'primitives/Text'
import { AppBannerKeys } from 'shared/Banner/types'
import { isServer } from '../lib/nextjs/isServer'
import { FeedPostVideoAsset } from './FeedPostVideoAsset'
import { RootStore } from './RootStore'

interface AppErrorActions {
  primary?: React.ReactNode
  secondary?: React.ReactNode
  forceResponse?: boolean
}

export interface ApplicationError {
  title: string | JSX.Element
  description: string | JSX.Element
  actions?: AppErrorActions
}

interface AppRouteHistory {
  lastPath?: string
  lastRoute?: string
}

type AppBannerVariants = 'error' | 'warning' | 'info' | 'success' | 'default'

interface AppBanner {
  key: AppBannerKeys
  variant?: AppBannerVariants
  title: string
  opts: {
    // Action JSX slot
    onClick?: () => void

    // Action label
    actionLabel?: string

    // Pinned banner are displays before unpinned banners
    pinned?: boolean
    // Prevent banner from being dismissible
    disableDismiss?: boolean
    // router.asPath strings where the banner should not show
    disabledPages?: string[]
  }
}

const PINNED_BANNER_KEYS: AppBannerKeys[] = [AppBannerKeys.KYC_APPROVAL]
const DISABLE_DISMISS_BANNER_KEYS: AppBannerKeys[] = []

/**
 * Helper to disable banners on specific pages
 */
const DISABLED_BANNER_PAGES = {
  [AppBannerKeys.KYC_APPROVAL]: [],
  [AppBannerKeys.LINK_WALLET]: [],
  [AppBannerKeys.ADD_NAME]: [],
  [AppBannerKeys.REFERRAL_CODE]: [],
  [AppBannerKeys.COMPLETE_ONBOARDING]: [],
  [AppBannerKeys.GET_STARTED]: []
}

const formatApplicationError = (
  errorCode: ErrorCodes
): {
  title: ApplicationError['title']
  description: ApplicationError['description']
} => {
  switch (errorCode) {
    case ErrorCodes.DELETE_POST:
      return {
        title: 'Delete post?',
        description: 'Are you sure you would like to delete this post?'
      }
    case ErrorCodes.WALLET_INVALID_NETWORK:
      return {
        title: 'Invalid network selected',
        description:
          //TODO: Define accepted network
          'Please switch to a supported network in order to continue. '
      }
    case ErrorCodes.LOGIN_WEAK_PASSWORD:
      return {
        title: 'Account restricted',
        description:
          'Your account has been restricted inline with our new security policy. Please reset your password.'
      }
    case ErrorCodes.WALLET_NOT_LINKED:
      return {
        title: 'Wallet not linked',
        description: 'Please link your wallet in order to continue.'
      }
    case ErrorCodes.WALLET_IN_USE:
      return {
        title: 'Unable to link wallet to account',
        description: 'This address is already linked to another NSFW account.'
      }
    case ErrorCodes.WALLET_NOT_CONNECTED:
      return {
        title: 'Wallet not connected',
        description: 'Please connect your wallet in order to continue.'
      }
    case ErrorCodes.PENDING_WITHDRAWAL:
      return {
        title: 'Pending withdrawal',
        description:
          'You have an existing withdrawal in progress. Please allow up to 5 minutes for processing.'
      }
    case ErrorCodes.WITHDRAWAL_MIN_AMOUNT:
      return {
        title: 'Withdrawal unavailable',
        description: 'Please enter an amount bigger than 25$'
      }
    case ErrorCodes.EMPTY_WALLET:
      return {
        title: 'Empty wallet',
        description: 'Your wallet has no available funds for withdrawal.'
      }
    case ErrorCodes.EMPTY_WITHDRAWAL_WALLET:
      return {
        title: 'Withdrawal unavailable',
        description:
          'Withdrawals are currently paused. Please the creator channel for updates or try again later.'
      }
    case ErrorCodes.KYC_RENEWAL:
      return {
        title: 'KYC renewal required',
        // TODO: support line break
        description: (
          <>
            <Text type="body3" css={{ marginBottom: 12, color: '$gray800' }}>
              Your KYC will need to be resubmitted inline with our ongoing compliance requirements.
            </Text>
            <Text type="body3" css={{ color: '$gray800' }}>
              You will not be able to post until your renewal has been approved.
            </Text>
          </>
        )
      }
    case ErrorCodes.DEFAULT:
      return {
        title: 'Something went wrong',
        description: 'Please try again'
      }
    default:
      throw new Error(`Unable to formatApplicationError(type: ${errorCode})`)
  }
}

/**
 * Store responsible for general UI and application state
 */
export class UiStore {
  root: RootStore
  lang = 'en-US'
  lastPath?: string = undefined
  // Keep track of users last page path for route aware state handling
  routeHistory: AppRouteHistory = {
    lastPath: undefined, // e.g /post/62942a53f08096aa6eae61cd
    lastRoute: undefined // e.g /post/[postId]
  }
  showCreatePost = false
  videoModalAsset?: FeedPostVideoAsset = undefined
  muteMedia = true
  showPaymentModal = false
  showPaymentErrorModal = false
  showAuthModal = false
  appError?: ApplicationError = undefined
  showAppErrorModal = false
  showProfileSettingsModal = false
  profileSettingModalScreen: ProfileSettingsScreen = 'GENERAL_SETTINGS'
  showWithdrawalModal = false
  showClaimRewardModal = false
  isMobile = false
  showBurgerMenu = false
  showConnectModal = false
  showNotifications = false
  showNetworkSelector = false
  getingStartedModal = false
  appBanner: AppBanner[] = []
  showRefferalModal = false
  messagePacks: MessagePack[] = []
  showPostModal = false
  referralCode?: string = undefined
  cancelPostUploadsSignal = new EventTarget()
  isWithAuthedPage = false // page is a withAuthedPage
  // Quick hack to implement handleGatedAction with privy linkWallet hook
  onGatedActionCbs: (() => unknown)[] = []

  constructor(data: Partial<UiStore> = {}, root: RootStore) {
    this.root = root
    this.hydrate(data)
    makeAutoObservable(this)

    AbstractUploader.setGlobalCancelSignal(this.cancelPostUploadsSignal)

    reaction(
      () => this.referralCode && this.root.user.isLoggedOut,
      (showReferralBanner) => {
        if (showReferralBanner) {
          this.displayBanner({
            key: AppBannerKeys.REFERRAL_CODE,
            title: `Referral code detected: ${this.referralCode}`,
            action: () => console.log('TODO: handle connect modal with referral code'),
            actionLabel: 'Signup'
          })
        }
      },
      { fireImmediately: isBrowser() }
    )

    reaction(
      () => this.root.user.account,
      async (account, prevAccount) => {
        // Clear banners
        if (account !== prevAccount) {
          this.hideBanner()
        }
      },
      { fireImmediately: isBrowser() }
    )
  }

  hydrate(data: Partial<UiStore> = {}) {
    this.lang = data.lang ?? this.lang
    this.isMobile = data.isMobile ?? this.isMobile
    this.referralCode = this.initReferralCode(data.referralCode)
    this.isWithAuthedPage = data.isWithAuthedPage ?? false
    return this
  }

  setRouteHistory(state: AppRouteHistory) {
    this.routeHistory = state
  }
  openPostModal() {
    this.showPostModal = true
  }
  closePostModal(shouldClearPost = true) {
    this.showPostModal = false
    if (shouldClearPost) {
      setTimeout(() => {
        this.root.feed.selectedPost = undefined
      }, 250) // delay for modal fadeOut
    }
  }

  openCreatePost() {
    if (this.root.user.requiresKYCApproved) {
      toast({
        message: 'You must be verified to post',
        type: 'error'
      })
      return
    }
    this.showCreatePost = true
    this.root.analytics.track(NSFW_EVENT.POST_DRAFT, {
      profileId: this.root.user.profileId
    })
  }

  closeCreatePost() {
    this.showCreatePost = false
  }

  togglePaymentModal(show = !this.showPaymentModal) {
    this.showPaymentModal = show
  }

  toggleWithdrawalModal() {
    this.showWithdrawalModal = !this.showWithdrawalModal
  }

  toggleClaimRewardModal() {
    this.showClaimRewardModal = !this.showClaimRewardModal
  }

  toggleMuteMedia(val = !this.muteMedia) {
    this.muteMedia = val
  }

  setApplicationError(code: ErrorCodes, actions?: AppErrorActions) {
    this.appError = {
      ...formatApplicationError(code),
      actions
    }
    this.showAppErrorModal = true
  }

  closeAppErrorModal() {
    this.appError = undefined
    this.showAppErrorModal = false
  }

  setVideoModalAsset(asset?: FeedPostVideoAsset) {
    this.videoModalAsset = !this.videoModalAsset && asset ? asset : undefined
  }

  openAuthModal() {
    this.showAuthModal = true
  }

  closeAuthModal() {
    this.showAuthModal = false
  }

  closeConnectModal() {
    this.showConnectModal = false
  }

  registerGatedActionCb(cb: () => unknown) {
    this.onGatedActionCbs.push(cb)
  }

  handleGatedAction() {
    if (this.root.user?.account) return false

    for (const cb of this.onGatedActionCbs) {
      cb()
    }
    return true
  }

  openProfileSettingsModal(screen: ProfileSettingsScreen = 'GENERAL_SETTINGS') {
    this.profileSettingModalScreen = screen
    this.showProfileSettingsModal = true
    if (!screen) {
      this.root.analytics.track(NSFW_EVENT.SETTINGS_MODAL_OPEN, {
        profileId: this.root.user.profileId
      })
    }
  }

  setProfileSettingsModalScreen(screen?: ProfileSettingsScreen) {
    if (screen) this.profileSettingModalScreen = screen
  }

  closeProfileSettingsModal() {
    this.showProfileSettingsModal = false
  }

  openBurgerMenu() {
    this.showBurgerMenu = true
  }
  closeBurgerMenu() {
    this.showBurgerMenu = false
  }

  openNotifications() {
    this.showNotifications = true
  }
  closeNotifications() {
    this.showNotifications = false
  }
  openNetworkSelector() {
    this.showNetworkSelector = true
  }
  closeNetworkSelector() {
    this.showNetworkSelector = false
  }

  displayBanner({
    key,
    title,
    variant = 'default',
    actionLabel = 'Continue',
    action
  }: {
    key: AppBannerKeys
    variant?: AppBannerVariants
    title: string
    actionLabel?: string
    action?: () => void
  } & Partial<AppBanner['opts']>) {
    const existingBanner = this.appBanner?.find((e) => e.key === key)
    if (!existingBanner) {
      this.appBanner?.push({
        key,
        title,
        variant,
        opts: {
          onClick: action,
          actionLabel,
          pinned: PINNED_BANNER_KEYS.includes(key),
          disableDismiss: DISABLE_DISMISS_BANNER_KEYS.includes(key),
          disabledPages: DISABLED_BANNER_PAGES[key]
        }
      })
    }
  }

  hideBanner(key?: AppBannerKeys) {
    if (key && this.appBanner) {
      this.appBanner = this.appBanner.filter((banner) => banner.key !== key)
    } else {
      this.appBanner = []
    }
  }

  openGettingStartedModal() {
    this.getingStartedModal = true
  }

  closeGettingStartedModal() {
    this.getingStartedModal = false
  }

  openRefferalModal() {
    this.showRefferalModal = true
  }

  closeRefferalModal() {
    this.showRefferalModal = false
  }

  removeReferralCode() {
    localStorage.removeItem(REFERRAL_CODE)
    this.referralCode = undefined
  }

  async getMessagePacks() {
    const { data, error } = await gqlRequest<{ messagePacks: MessagePack[] }>(QUERY_MESSAGE_PACKS)

    if (data) {
      this.messagePacks = data.messagePacks
    }
    if (error) {
      console.log(error)
      toast('Could not get packs')
    }
  }

  private initReferralCode(code?: string) {
    if (isServer()) return

    if (code) {
      localStorage.setItem(REFERRAL_CODE, code)
      return code
    }

    return localStorage.getItem(REFERRAL_CODE) ?? undefined
  }
}
