import { Approval, ApprovalStatus, OnboardingUnion } from 'graphql/types'
import { DataStore } from 'interfaces'
import { GqlApiError } from 'lib/gql/GqlApiError'
import { makeAutoObservable, toJS } from 'mobx'
import { isCreatorOnboarding } from '../interfaces/guards'

const NOT_STARTED = [ApprovalStatus.REQUESTED, ApprovalStatus.PENDING]

/**
 * When a user has submitted their application, they are in a pending state
 */
const PENDING_STATUSES = [ApprovalStatus.SUBMITTED]

const APPROVED_STATUSES = [ApprovalStatus.APPROVED]

const REJECTED_STATUSES = [ApprovalStatus.REJECTED]

export class UserOnboardingStore implements DataStore<OnboardingUnion> {
  private _isFetching = false
  private _data?: OnboardingUnion = undefined
  private _error?: GqlApiError = undefined

  constructor(data: Partial<UserOnboardingStore>) {
    if (data.isFetching) this.isFetching = data.isFetching
    if (data.data) this._data = data.data
    makeAutoObservable(this)
  }

  get isFetching() {
    return this._isFetching
  }

  set isFetching(v: boolean) {
    this._isFetching = v
  }

  get data() {
    return this._data
  }

  set data(data: OnboardingUnion | undefined) {
    this._data = data
    if (data) this.isFetching = false
  }

  get error() {
    return this._error
  }

  set error(error: GqlApiError | undefined) {
    this._error = error
    if (error) this.isFetching = false
  }

  get creatorApprovals() {
    return isCreatorOnboarding(this.data) ? this.data.approvals : []
  }

  get application() {
    const notStarted = this.creatorApprovals.filter((o) =>
      [...NOT_STARTED, ...REJECTED_STATUSES].includes(o.approvalStatus)
    )
    if (notStarted.length > 1) throw new Error('Multiple approvals not started')
    return notStarted.length === 1 ? notStarted[0] : undefined
  }

  get status() {
    switch (true) {
      case this.isPending:
        return ApprovalStatus.PENDING
      case this.isApproved:
        return ApprovalStatus.APPROVED
      case this.isRejected:
        return ApprovalStatus.REJECTED
      default:
        return undefined
    }
  }

  get isPending() {
    console.log('isPending()')
    return this.creatorApprovals.some((o) => PENDING_STATUSES.includes(o.approvalStatus))
  }

  get isApproved() {
    console.log('isApproved()')
    return this.creatorApprovals.some((approval) =>
      APPROVED_STATUSES.includes(approval.approvalStatus)
    )
  }

  get isRejected() {
    console.log('isRejected()')
    return this.creatorApprovals.some((approval) =>
      REJECTED_STATUSES.includes(approval.approvalStatus)
    )
  }

  appendCreatorApprovals(newApprovals: Approval | Approval[]) {
    if (isCreatorOnboarding(this.data)) {
      this.data.approvals = [
        ...(Array.isArray(newApprovals) ? newApprovals : [newApprovals]),
        ...this.data.approvals
      ]
    }
  }
}
