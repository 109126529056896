import { gql } from 'urql'

export const LIST_PLAN_OPTIONS = gql`
  query ListUnconfiguredPlans {
    listUnconfiguredPlans {
      __typename
      id
      title
      interval
      duration
    }
  }
`
