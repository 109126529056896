import { NextPageContext } from 'next'
import { getSelectorsByUserAgent } from 'react-device-detect'

// react-device-detect does not type these for some reason, even though it would be easily
// typed & has other types..
// https://github.com/duskload/react-device-detect/blob/54ce6cfbed0479691c45aa0371e4afdfaaae74f8/src/lib/buildSelectors.js#L4
interface Selectors {
  // Only care about isMobile for now... add more as required...
  isMobile: boolean
}

// Leverage react-device-detect which nicely wraps [ua-parser-js](https://www.npmjs.com/package/ua-parser-js)
export const detectDevice = (ctx?: NextPageContext) => {
  const userAgent = ctx?.req
    ? ctx.req.headers['user-agent']
    : navigator.userAgent

  if (!userAgent) {
    console.warn('No user-agent found on req headers: ', ctx?.req?.headers)
    return { isMobile: false }
  }

  return getSelectorsByUserAgent(userAgent) as Selectors
}
