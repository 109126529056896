import { gql } from '@urql/core'

export const VIEW_ORDER_QUERY = gql`
  query ViewOrderQuery($orderId: String!) {
    viewOrder(orderId: $orderId) {
      orderId
      customer {
        profileId
        displayName
      }
      chainId
      status
      signer
      recipient
      token {
        symbol
        contract
      }
      currencyCode
      total
      payments {
        ... on CryptoPayment {
          __typename
          paymentId
          status
          transactionHash
          signer
          recipient
          token {
            symbol
            contract
          }
          createdAt
          completedAt
        }
      }
      createdAt
    }
  }
`
