import { gql } from 'urql'

export const CLAIM_REFERRAL_REWARD = gql`
  mutation ClaimReferralReward($input: ClaimReferralRewardInput!) {
    claimReferralReward(input: $input) {
      referralId
      type
      status
    }
  }
`
