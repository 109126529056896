import { makeAutoObservable } from 'mobx'
import { RootStore } from './RootStore'
import type { GroupChannel } from 'lib/sendbird/interface'

// Manage Chat feature account state & transactions.
export class ChatStore {
  root: RootStore

  // Setting the channelUrl toggles the ChatDrawer & loads the channel.
  channel: GroupChannel | null = null

  isChatListOpen = false

  // Use cannot send messages (no credits, etc)
  isChatLocked = false

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
  }

  setChannel(channel: GroupChannel | null) {
    this.channel = channel
  }

  toggleChatList(isOpen?: boolean) {
    this.isChatListOpen = isOpen ?? !this.isChatListOpen
  }

  toggleChatLocked(isLocked?: boolean) {
    this.isChatLocked = isLocked ?? !this.isChatLocked
  }
}
