import { gql } from '@urql/core'
import { Post } from 'models/Post'

export const LOAD_MORE_COMMENTS = gql`
  query ViewPostComments($postId: String!, $options: PaginationOptions!) {
    viewPost(postId: $postId) {
      comments(options: $options) {
        hasNextPage
        cursor
        items {
          ... on Comment {
            __typename
            commentId
            createdBy
            createdAt
            text
            postId
            author {
              displayName
              username
              avatar {
                url
              }
            }
          }
        }
      }
    }
  }
`

export interface LoadMoreCommentsResponse {
  viewPost: Post
}
