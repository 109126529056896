import { FEED_PAGE_LIMIT } from 'config'
import { FEED, FeedResponse } from 'graphql/queries'
import { FeedType, SortBy, SortDirection } from 'graphql/types'
import { makeAutoObservable } from 'mobx'
import { Post } from './Post'
import { RootStore } from './RootStore'
import { publicRequest, request } from 'lib/gql/client'
import { FeedBySort } from './feed.store'

export class PaginatedPosts {
  // Quick field to differenciate type PostParentFeedStore (see guards.ts isProfileFeedStore).
  _feedType: 'profile-feed' | 'activity-feed' = 'activity-feed'
  root: RootStore
  type: FeedBySort
  loading = false
  posts: Post[] = []
  hasNextPage = true
  error?: string = undefined
  scrollIndex = 0
  cursor?: string = '0'

  constructor(root: RootStore, data: Partial<PaginatedPosts> = {}) {
    this.root = root
    this.hydrate(data)
    makeAutoObservable(this)
  }

  hydrate({
    type,
    loading,
    posts,
    hasNextPage,
    error,
    scrollIndex,
    cursor
  }: Partial<PaginatedPosts> = {}) {
    this.type = type ?? 'LATEST:CREATED_AT'
    if (loading !== undefined) this.loading = loading
    if (hasNextPage !== undefined) this.hasNextPage = hasNextPage
    if (cursor) this.cursor = cursor
    if (error) this.error = error
    if (scrollIndex) this.scrollIndex = scrollIndex
    if (posts && posts.length > 0) {
      // ??
      // TODO: Fix types
      // @ts-ignore
      this.posts = posts.map((post) => new Post(post, this.root, this))
    }
    return this
  }

  // returning a new array instance causes jolt in virtual feed so mutating instead
  appendItems(newPosts: Post[]) {
    this.posts.push(...newPosts)
    return this.posts
  }

  // Reset feed; reload first page.
  async resetFeed() {
    return this._fetchFeed({ cursor: '0' })
  }

  async fetchFeed(limit = FEED_PAGE_LIMIT, cursor = this.cursor) {
    return this._fetchFeed({ limit, cursor })
  }

  async _fetchFeed({ limit = FEED_PAGE_LIMIT, cursor = this.cursor } = {}) {
    const initialFetch = Boolean(cursor === '0' && this.posts.length)
    if (!initialFetch && (this.loading || !this.hasNextPage)) {
      return this.posts
    }

    this.loading = true

    const [feedType, sortBy] = this.type.split(':') as [FeedType, SortBy]

    console.log('-----')
    console.log('-----')
    console.log('-----')
    console.log('fetchFeed', feedType, sortBy)

    const resp = this.root.user.isLoggedIn
      ? // Is logged in
        await request<FeedResponse>(
          FEED,
          {
            type: feedType,
            feedOpts: {
              limit,
              lastRecord: cursor,
              sortBy: sortBy || 'CREATED_AT',
              sortDirection: SortDirection.DESC
            }
          },
          {
            token: this.root.user.token
          }
        )
      : // Not logged in, sort by random
        await publicRequest<FeedResponse>(FEED, {
          type: feedType,
          feedOpts: {
            limit,
            lastRecord: cursor,
            sortBy: 'RANDOM',
            sortDirection: SortDirection.DESC
          }
        })

    // TODO: Fix types
    // @ts-ignore
    const newPosts = (resp?.feed.items || []).map((p) => new Post(p, this.root, this))

    if (initialFetch) {
      this.posts = newPosts
      // See useRestoreScroll which watches for this value and scrolls to position 0
      this.setScrollIndex(-1)
    } else {
      this.appendItems(newPosts)
    }
    this.cursor = resp?.feed.cursor
    this.hasNextPage = Boolean(resp?.feed.hasNextPage && newPosts.length > 0)
    this.loading = false

    // set/reset error state
    // GQL error can return for individual posts, it seems they're excluded from the feed so we
    // continue to show posts.
    this.error = !resp || !resp ? 'Could not retrieve posts' : undefined

    return this.posts
  }

  setScrollIndex(scrollIndex: number) {
    this.scrollIndex = scrollIndex
  }
}
