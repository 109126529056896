import { Wallet } from 'config/wallets'
import { PaymentOptions } from 'components/modals/PaymentModal/types'
import { TokenSymbol, AccountType, FeedType, PostType, ReportItem } from 'graphql/types'
import { ShareSocialType } from 'interfaces'
import { FeedBySort } from 'models/feed.store'

export enum NSFW_EVENT {
  //Onboarding (creators)
  ONBOARDING_START = 'onboarding:start',
  ONBOARDING_NAME = 'onboarding:name',
  ONBOARDING_DATE_OF_BIRTH = 'onboarding:dob',
  ONBOARDING_USERNAME = 'onboarding:username',
  ONBOARDING_MOBILE = 'onboarding:mobile',
  ONBOARDING_MOBILE_VERIFY = 'onboarding:mobile-verify',
  ONBOARDING_EMAIL_PASSWORD = 'onboarding:email-password',
  ONBOARDING_COMPLETE = 'onboarding:complete',

  //Feeds
  FEED_VIEW = 'feeds:view',
  FEED_POST_VIEW = 'feeds:post:view',
  FEED_POST_LIKE = 'feeds:post:like',
  FEED_POST_SHARE = 'feeds:post:share',
  FEED_POST_COMMENTS_VIEW = 'feeds:post:comments:view',
  FEED_POST_COMMENT_ADD = 'feeds:post:comment:add',
  FEED_POST_COMMENT_SHOW_MORE = 'feeds:post:comment:show',
  FEED_POST_COMMENT_PROFILE_VIEW = 'feeds:post:comment:profile:view',
  FEED_POST_PROFILE_FOLLOW = 'feeds:post:profile:follow',
  FEED_POST_PROFILE_VIEW = 'feeds:post:profile:view',

  //Login / Logout
  LOGIN_ATTEMPT = 'login:attempt',
  LOGIN_COMPLETE = 'login:complete',
  FORGOT_PASSWORD_ATTEMPT = 'login:recover-account:attempt',
  FORGOT_PASSWORD_SUCCESS = 'login:recover-account:success',

  //Wallet Connect
  WALLET_CONNECT_START = 'wallet:connect:start',
  WALLET_CONNECT_SELECT = 'wallet:connect:select',
  WALLET_CONNECTED = 'wallet:connect:connected',
  WALLET_LINKED = 'wallet:linked',
  WALLET_LOGOUT = 'wallet:logout',

  //Search
  SEARCH_CLICK = 'nav:search',
  SEARCH_QUERY = 'search:query',
  SEARCH_PROFILE_FOLLOW = 'search:profile:follow',
  SEARCH_PROFILE_CLICK = 'search:profile:click-to-visit',

  //Pages
  VIEW_PURCHASES = 'purchases:view',
  VIEW_ACTIVE_SUBSCRIPTIONS = 'subscriptions:active:view',
  VIEW_EXPIRED_SUBSCRIPTIONS = 'subscriptions:expired:view',
  VIEW_EARNINGS = 'earnings:view',
  VIEW_SALES = 'sales:view',
  VIEW_SCHEDULED_POSTS = 'schedules-posts:view',
  VIEW_PROFILE_PAGE = 'profile:view',
  VIEW_PROFILE_FOLLOWERS = 'profile:followers',
  VIEW_PROFILE_FOLLOWING = 'profile:following',
  VIEW_ANALYTICS = 'analytics:view',
  VIEW_SECURITY = 'kyc:approvals:view',

  //Payments
  ORDER_CHECKOUT = 'order:checkout',
  ORDER_SUMMARY = 'order:summary',
  ORDER_COMPLETED = 'order:completed',
  ORDER_CANCELED = 'order:canceled',

  //Withdrawals
  WITHDRAWAL_START = 'withdrawal:start',
  WITHDRAWAL_SUMMARY = 'withdrawal:summary',
  WITHDRAWAL_COMPLETED = 'withdrawal:completed',
  WITHDRWAL_CANCELED = 'withdrawal:canceled',

  //Posts
  POST_DRAFT = 'post:draft',
  POST_ASSET_UPLOAD = 'asset:upload',
  POST_PUBLISHED = 'post:publish',
  POST_DISCARDED = 'post:discard',

  //Profile
  PROFILE_FOLLOW = 'profile:follow',
  PROFILE_SHARE = 'profile:share',

  // Report
  REPORT_START = 'report:start',
  REPORT_COMPLETE = 'report:completed',

  //Footer
  FOOTER_TWITTER_CLICK = 'social:twitter:click',
  FOOTER_DISCORD_CLICK = 'social:discord:click',
  FOOTER_TELEGRAM_CLICK = 'social:telegram:click',
  FOOTER_PRIVACY_POLICY_CLICK = 'privacy-policy:view',

  //Profile settings
  SETTINGS_MODAL_OPEN = 'profile:settings:open',
  SETTINGS_UPDATE_AVATAR = 'profile:settings:avatar',
  SETTINGS_UPDATE_DISPLAY_NAME = 'profile:settings:display-name',
  SETTINGS_UPDATE_BIO = 'profile:settings:bio',
  SETTINGS_UPDATE_USERNAME = 'profile:settings:username',
  SETTINGS_UPDATE_PASSWORD = 'profile:settings:password',
  SETTINGS_UPDATE_EMAIL = 'profile:settings:email',
  SETTINGS_LOGOUT = 'profile:settings:logout',

  //Suggestions
  SUGGESTIONS_PROFILE_FOLLOW = 'suggestions:profile:follow',
  SUGGESTIONS_PROFILE_CLICK = 'suggestions:profile:click-to-visit',

  //Videos
  PLAY_VIDEO = 'feeds:post:video:play',
  PLAY_VIDEO_DURATION = 'feeds:post:video:duration',
  PLAY_VIDEO_COMPLETE = 'feeds:post:video:complete',
  VIDEO_CONTROLS = 'feeds:post:video:controls',

  //Links
  HOW_TO_PAY_CLICK = 'links:view:how-to-pay',

  // KYC
  KYC_REQUESTED = 'kyc:request-approval'
}

type GeneralEventProps = {
  userType: 'creator' | 'admirer' | 'guest' | 'fan' | AccountType
}

type OnboardingEventsProps = {
  userType: 'creator' | 'admirer' | 'guest' | 'fan' | AccountType
}

export enum ControlType {
  FULLSCREEN = 'fullScreen',
  MUTE = 'mute',
  UNMUTE = 'unmute',
  SCRUBBER = 'scrubber'
}

export type EventPropsUnion = GeneralEventProps &
  OnboardingEventsProps &
  PaymentEventProps &
  FeedEventProps &
  FeedPostEventProps &
  ProfileEventProps &
  FeedPostCommentEventProps &
  WithdrawalEventProps &
  SearchQueryEventProps &
  CreateDraftPostEventProps &
  ConnectWalletEventProps &
  ReportEventProps &
  VideoControlsEventProps
//   PostContentEventProps &
//   VideoEventProps &
//   VideoDurationEventProps &
//   VideoCompleteEventProps &
//   PaymentSuccessEventProps &
//   WithdrawalEventProps

export interface FeedEventProps extends GeneralEventProps {
  feedType?: FeedType | 'PROFILE' | FeedBySort
  page?: number
  ordinal?: number
}

export interface FeedPostEventProps extends FeedEventProps {
  postId: string
  sharedSocial: ShareSocialType
}

export interface ProfileEventProps extends FeedPostEventProps {
  profileId: string
  username: string
}

export interface FeedPostCommentEventProps extends FeedPostEventProps {
  commentLength: number
}

export interface SearchQueryEventProps extends FeedPostEventProps {
  query: string
  hasResults: boolean
}

export interface PaymentEventProps extends FeedPostEventProps {
  profileId: string
  paymentToken: string
  walletType: Wallet
  paymentType: PaymentOptions
  paymentValue: string
  postType: PostType
}

export interface WithdrawalEventProps extends ProfileEventProps {
  walletBalance: number
  withdrawalAddress: string
  withdrawalNetwork: number
  withdrawalCurrency: TokenSymbol
  withdrawalAmount: string
}

interface CreateDraftPostEventProps extends FeedPostEventProps {
  isScheduled: boolean
  scheduledAt?: Date | null
}

export interface ConnectWalletEventProps extends PaymentEventProps {
  chainId: number
}

export interface ReportEventProps extends ProfileEventProps {
  reportedItemId: string
  reportedItemType: ReportItem
  reason: string
}

export interface VideoControlsEventProps extends FeedEventProps {
  controlType: `${ControlType}`
  durationSecs: number
}
