import { gql } from 'urql'

export const CORE_POST_ASSET_FIELDS = gql`
  fragment PostAssetParts on PostAsset {
    ordinal
    media {
      __typename
      ... on ImageAsset {
        url
        originalWidth
        originalHeight
        aspectRatio
      }
      ... on VideoAsset {
        url
        fallbackUrl
        duration
        originalWidth
        originalHeight
        aspectRatio
      }
    }
  }
`

export const POST_ASSET_FORMATS_FIELDS = gql`
  fragment PostAssetFormatsParts on PostAsset {
    ordinal
    media {
      __typename
      ... on ImageAsset {
        url
        fallbackUrl
        originalWidth
        originalHeight
        aspectRatio
        formats {
          format
          url
          fallbackUrl
          width
          height
        }
      }
      ... on VideoAsset {
        url
        fallbackUrl
        duration
        originalWidth
        originalHeight
        aspectRatio
        formats {
          format
          url
          fallbackUrl
          width
          height
        }
      }
    }
  }
`
