import { toSecondsElapsed } from 'lib/date'
import { DateTime } from 'luxon'
import {
  AbstractUploader,
  CommonOptions,
  DEFAULT_CHUNK_SIZE,
  MAX_CHUNK_SIZE,
  MIN_CHUNK_SIZE,
  ChunkedUploadResponse,
  WithAccessToken,
} from './abstract-uploader'

interface UploadOptions {
  file: File
  fileExtension: string
  chunkSize?: number
}

export interface VideoUploaderOptionsWithAccessToken
  extends CommonOptions,
    UploadOptions,
    WithAccessToken {}

export interface UploadProgressEvent {
  uploadedBytes: number
  totalBytes: number
  chunksCount: number
  chunksBytes: number
  currentChunk: number
  currentChunkUploadedBytes: number
}

export class VideoUploader extends AbstractUploader<UploadProgressEvent> {
  private uploadId: string
  private file: File
  private chunkSize: number
  private chunksCount: number
  private fileSize: number
  private fileName: string
  private fileExtension: string
  private postId: string
  private mimeType: string

  constructor(options: VideoUploaderOptionsWithAccessToken) {
    super(options)

    if (!options.file) {
      throw new Error("'file' is missing")
    }
    if (!options.fileExtension) {
      throw new Error("'fileExtension' is missing")
    }
    if (!options.postId) {
      throw new Error("'postId' is missing")
    }

    if (
      options.chunkSize &&
      (options.chunkSize < MIN_CHUNK_SIZE || options.chunkSize > MAX_CHUNK_SIZE)
    ) {
      throw new Error(
        `Invalid chunk size. Minimal allowed value: ${
          MIN_CHUNK_SIZE / 1024 / 1024
        }MB, maximum allowed value: ${MAX_CHUNK_SIZE / 1024 / 1024}MB.`
      )
    }

    this.chunkSize = options.chunkSize || DEFAULT_CHUNK_SIZE
    this.file = options.file
    this.fileSize = this.file.size
    this.fileName = options.videoName || this.file.name
    this.fileExtension = options.fileExtension
    this.mimeType = options.mimeType || this.file.type
    this.postId = options.postId
    this.uploadId = options.uploadId

    this.chunksCount = Math.ceil(this.fileSize / this.chunkSize)

    if (!this.mimeType) {
      throw new Error('No mime-type provided/inferred')
    }
  }

  public upload(signal?: EventTarget): Promise<ChunkedUploadResponse> {
    return new Promise(async (resolve, reject) => {
      const cancel = () => reject(new Error('Cancelled'))

      // Cancel individual upload
      signal?.addEventListener('cancel', () => cancel())
      // Cancel all uploads
      AbstractUploader.globalCancelSignal?.addEventListener('cancel', () =>
        cancel()
      )

      try {
        console.log('starting upload..')
        const startAt = DateTime.now()
        for (let i = 0; i < this.chunksCount; i++) {
          await this.uploadCurrentChunk(i, signal)
        }
        const completedAt = DateTime.now()
        const diff = toSecondsElapsed(startAt, completedAt)
        console.log(this.fileSize, diff.toObject())
        const completeSignal = await this.sendCompleteSignal()
        return resolve(completeSignal)
      } catch (err) {
        reject(err)
      }
    })
  }

  private sendCompleteSignal() {
    return this.xhrWithRetrier({
      body: this.createUploadCompleteFormData(
        this.uploadId,
        this.fileName,
        this.fileExtension,
        this.fileSize,
        this.mimeType,
        this.postId
      ),
    })
  }

  private uploadCurrentChunk(
    chunkNumber: number,
    signal?: EventTarget
  ): Promise<ChunkedUploadResponse> {
    const firstByte = chunkNumber * this.chunkSize
    const computedLastByte = (chunkNumber + 1) * this.chunkSize
    const lastByte =
      computedLastByte > this.fileSize ? this.fileSize : computedLastByte
    const chunksCount = Math.ceil(this.fileSize / this.chunkSize)

    const progressEventToUploadProgressEvent = (
      event: ProgressEvent
    ): UploadProgressEvent => {
      return {
        uploadedBytes: event.loaded + firstByte,
        totalBytes: this.fileSize,
        chunksCount: this.chunksCount,
        chunksBytes: this.chunkSize,
        currentChunk: chunkNumber + 1,
        currentChunkUploadedBytes: event.loaded,
      }
    }

    return this.xhrWithRetrier(
      {
        onProgress: (event) =>
          this.onProgressCallbacks.forEach((cb) =>
            cb(progressEventToUploadProgressEvent(event))
          ),
        body: this.createFormData(
          this.uploadId,
          this.file,
          this.fileName,
          this.fileSize,
          this.fileExtension,
          this.mimeType,
          this.postId,
          firstByte,
          lastByte
        ),
        parts: {
          currentPart: chunkNumber + 1,
          totalParts: chunksCount,
        },
      },
      signal
    )
  }
}
