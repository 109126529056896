import { gql } from '@urql/core'

export const CREATE_WITHDRAWAL_MUTATION = gql`
  mutation CreateWithdrawal($input: CreateWithdrawalInput!) {
    createWithdrawal(input: $input) {
      orderId
      chainId
      status
      signer
      recipient
      currencyCode
      total
      createdAt
      token {
        symbol
        contract
      }
      # customer {
      #   profileId
      # }
      # products {
      #   type
      #   post {
      #     postId
      #   }
      #   creator {
      #     profileId
      #   }
      # }
    }
  }
`
