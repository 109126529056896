import { gql } from '@urql/core'
import { ImageAsset } from 'graphql/types'

export const UPDATE_AVATAR = gql`
  mutation UpdateAvatar($image: Upload!) {
    updateAvatar(image: $image) {
      avatar {
        url
      }
    }
  }
`
export type UpdateAvatarVars = {
  image: File
}

export interface UpdateAvatarResponse {
  updateAvatar: {
    avatar: ImageAsset
  }
}
