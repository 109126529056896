import omit from 'lodash/omit'
import { Nullable } from 'lib/types'
import { APIError, APIErrorFields, getAPIError } from './APIError'

interface RequestOpts extends RequestInit {
  token?: string
  basic?: string
  headers?: Record<string, string>
}

const getContentType = (type?: string) => {
  switch (type) {
    case 'multipart/form-data':
      return undefined
    default:
      return 'application/json'
  }
}

export async function fetcher<T extends unknown>(
  input: RequestInfo,
  opts: RequestOpts = {}
) {
  // To support simply setting `'Content-Type': undefined` for multipart boundary
  const contentType = getContentType(
    opts.headers && opts.headers['Content-Type']
  )

  const auth = opts.token
    ? `Bearer ${opts.token}`
    : opts.basic
    ? `Basic ${opts.basic}`
    : undefined

  const init: RequestInit = omit(opts, ['headers.Content-Type'])

  const headers = {
    ...(contentType ? { 'Content-Type': contentType } : {}),
    ...(auth ? { Authorization: auth } : {}),
    ...init?.headers,
  }

  const response = await fetch(input, {
    ...init,
    headers,
  })

  const data: T = await response.json()

  if (response.status === 200) {
    return data
  }

  console.error(
    `Error fetching input="${input}" status=${response.status} msg=${response.statusText}`,
    opts
  )

  throw new APIError({
    status: response.status,
    message: response.statusText,
  })
}

// When using catch is not preffered
export async function inlineFetcher<T extends unknown>(
  input: RequestInfo,
  opts: RequestOpts = {}
) {
  // null values for Next.js props json serialization
  let data: T | null = null
  let error: Nullable<APIErrorFields> = { status: null, message: null }
  try {
    data = await fetcher(input, opts)
  } catch (err) {
    error = getAPIError(err)
  }
  return { data, error }
}

// When uploading a file and needing to ascertain progress
export const progressFetcher = (
  input: RequestInfo,
  init: RequestInit | undefined,
  onProgress: (n: number) => void
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open('post', String(input), true)

    const headers = (init?.headers || {}) as Record<string, string>
    Object.entries(headers).forEach(([key, value]) =>
      xhr.setRequestHeader(key, value)
    )

    let progress = 0
    let interval: NodeJS.Timer

    xhr.onload = () => {
      clearInterval(interval)
      progress = 100
      onProgress(progress)
      setTimeout(() => {
        resolve({
          ok: true,
          text: () => Promise.resolve(xhr.responseText),
          json: () => Promise.resolve(JSON.parse(xhr.responseText)),
        } as Response)
      }, 1000)
    }

    xhr.onerror = (e) => {
      console.error(e)
      reject()
    }

    if (xhr.upload) {
      xhr.upload.onprogress = (event) => {
        const percent = (event.loaded / event.total) * 100
        progress = percent * 0.9
        onProgress(progress)
      }
      xhr.upload.onloadend = (event) => {
        console.log('loaded')
        interval = setInterval(() => {
          progress++
          onProgress(progress > 99 ? 99 : progress)
        }, 1000)
      }
    }

    xhr.send(init?.body as XMLHttpRequestBodyInit)
  })
}
