import { toast as sonnerToast } from 'sonner'

interface Options {
  err?: Error | unknown
  message: string | React.ReactNode
  type: 'success' | 'error' | 'info'
  duration?: number
  action?: {
    label: string
    onClick: () => void
  }
}

export const toast = (opts: string | Options) => {
  if (typeof opts === 'string') {
    const message = opts
    sonnerToast(message)
  } else {
    const { message, type, ...rest } = opts

    switch (type) {
      case 'success':
        sonnerToast.success(message, rest)
        break
      case 'error':
        sonnerToast.error(message, rest)
        break
      case 'info':
        sonnerToast.info(message, rest)
        break
      default:
        sonnerToast(message, rest)
        break
    }
  }
}
