import { VIEW_PROFILE } from 'graphql/queries/viewProfile'
import { ViewProfileQuery, ViewProfileQueryVariables } from 'graphql/types'
import { makeAutoObservable } from 'mobx'
import { ProfileProps, Profile } from '../Profile'
import { RootStore } from '../RootStore'
import { publicRequest } from 'lib/gql/client'

export class ProfilesCache {
  root: RootStore

  profiles: Map<string, Profile> = new Map()

  constructor(profiles: Profile[], root: RootStore) {
    this.root = root

    // Hydrate profiles
    for (const profile of profiles) {
      this.profiles.set(profile.profileId, profile)
    }

    makeAutoObservable(this)
  }

  get(id?: string): Profile | undefined {
    if (!id) return
    return this.profiles.get(id)
  }

  set(profile: ProfileProps) {
    const instance = new Profile(profile, this.root)
    this.profiles.set(profile.profileId, instance)
    return instance
  }

  /**
   * Returns a profile by username
   * @param username
   * @returns
   */
  getByUsername(username?: string): Profile | undefined {
    if (!username) return
    for (const [_, profile] of this.profiles) {
      if (profile.username === username) return profile
    }
  }

  load(profile: ProfileProps) {
    let instance = this.get(profile.profileId)
    if (instance) {
      instance.hydrate(profile)
    } else {
      instance = this.set(profile)
    }
    return instance
  }

  delete(profile?: Profile) {
    if (profile && this.profiles.has(profile.profileId)) {
      this.profiles.delete(profile.profileId)
    }
  }

  deleteById(id?: string) {
    this.delete(this.get(id))
  }

  async fetch(username: string, variables?: Omit<ViewProfileQueryVariables, 'username'>) {
    const resp = await publicRequest<ViewProfileQuery>(VIEW_PROFILE, {
      username,
      ...variables
    })

    if (!resp) {
      throw new Error('Profile not found')
    }

    if (resp.viewProfile) {
      return this.load(resp.viewProfile as ProfileProps)
    }
  }
}
