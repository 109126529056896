import type { TypedDocumentNode } from 'urql'
import { IncomingMessage } from 'http'
import { print } from 'graphql/language/printer'
import { getCookie } from 'lib/nextjs/nextCookie'
import { fetcher } from 'lib/api/fetcher'
import { API_URL, TOKEN_COOKIE } from 'config'
import { GqlApiError, GqlError } from './GqlApiError'

export const gqlRequest = async <Data, Vars extends {} = {}>(
  gql: TypedDocumentNode,
  variables: Vars | null = null,
  req?: IncomingMessage
) => {
  const token = getCookie(TOKEN_COOKIE, req)

  const { data, errors } = await fetcher<{
    data: Data | undefined
    errors: GqlError[] | undefined
  }>(API_URL, {
    method: 'POST',
    body: JSON.stringify({ query: print(gql), variables }),
    token
  })

  return {
    data,
    error: errors ? new GqlApiError(errors) : undefined
  }
}
