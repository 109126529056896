import { NextPageContext } from 'next'
import { isBrowser } from './isBrowser'
import absoluteUrl from './nextAbsoluteUrl'

// Helper to form canonical URLs server-side/client-side
export const pageCanonical = (path?: string, ctx?: NextPageContext) => {
  const { origin } = absoluteUrl(ctx?.req)
  const pathname = !path && isBrowser() ? window.location.pathname : path
  return `${origin}${pathname}`
}
