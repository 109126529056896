import { gql } from '@urql/core'
import { CORE_COMMENT_FIELDS } from 'graphql/fragments'

export const ADD_COMMENT = gql`
  ${CORE_COMMENT_FIELDS}
  mutation AddComment($input: CreateCommentInput!) {
    addComment(input: $input) {
      ...CommentParts
    }
  }
`
