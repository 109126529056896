import { gql } from 'urql'

export const CORE_PROFILE_FIELDS = gql`
  fragment ProfileParts on Profile {
    __typename
    accountType
    avatar {
      url
    }
    bio
    displayName
    profileId
    isFollowing
    referralUrl
    username
    messaging {
      rate
      unit
      audience
    }
  }
`
