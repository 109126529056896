import { DEFAULT_IMAGE_QUALITY } from 'config'
import { ImageLoaderProps } from 'next/image'

// For next/image srcsets generation with image manipulation API.
// NOTE: this is a placeholder, needs to be updated with cloudinary API.
// https://nextjs.org/docs/api-reference/next/image#loader
export const imageLoader =
  (url?: string) =>
  ({ width, src, quality }: ImageLoaderProps) => {
    if (!url || typeof url !== 'string') return src

    return `${src}?width=${width}&quality=${quality || DEFAULT_IMAGE_QUALITY}`
  }
