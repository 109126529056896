import { TokenOptions } from '@nsfw-app/crypto'
import { makeAutoObservable } from 'mobx'

// AA: Good read --- https://dev.to/shevchenkonik/react-typescript-mobx-4mei
// AA: Good read --- https://blog.mselee.com/posts/2019/06/08/using-mobx-with-react-hooks-typescript/
export class PaymentPreferences {
  token?: TokenOptions

  constructor() {
    makeAutoObservable(this)
  }

  setCurrency = (currency: TokenOptions) => {
    this.token = currency
  }
}
