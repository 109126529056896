import { gql } from 'urql'

export const UPDATE_MESSAGE_PREFERENCES = gql`
  mutation MessagingPreferences($input: MessagingPreferencesInput!) {
    messagingPreferences(input: $input) {
      messaging {
        rate
        unit
        audience
      }
    }
  }
`
