import { toast } from 'components/common/Toast'
import { DEFAULT_HOME_ROUTE, TOKEN_COOKIE } from 'config'
import { UPDATE_BIO } from 'graphql/mutations/profiles/updateBio'
import { UPDATE_DISPLAY_NAME_MUTATION } from 'graphql/mutations/profiles/updateDisplayName'
import Cookies from 'js-cookie'
import { makeAutoObservable, reaction } from 'mobx'
import Router from 'next/router'

import { AUTHENTICATE_WITH_PRIVY } from 'graphql/mutations'
import { CREATE_TAG } from 'graphql/mutations/createTag'
import {
  CHANGE_USERNAME,
  DELETE_ACCOUNT,
  SEND_MOBILE_VERIFICATION_MUTATION,
  UPDATE_MESSAGE_PREFERENCES,
  VERIFY_MOBILE
} from 'graphql/mutations/profiles'
import { REQUEST_APPROVAL } from 'graphql/mutations/requestApproval'
import { SEND_MESSAGE } from 'graphql/mutations/sendMessage'
import { UPDATE_PRONOUNS } from 'graphql/mutations/updatePronouns'
import { UPDATE_TAGS } from 'graphql/mutations/updateTags'
import {
  AuthenticateWithPrivyMutation,
  ChangeUsernameMutation,
  CreateTagMutation,
  QueryReferralsQuery,
  Tag,
  TagKey,
  TagsQuery,
  TagsQueryVariables,
  UpdateBioMutation,
  UpdateTagsMutation
} from 'graphql/types'

import { User as PrivyUser } from '@privy-io/react-auth'
import { parse } from 'graphql'
import {
  ME_ONBOARDING,
  MeResponse,
  ONBOARDING_FIELDS,
  QUERY_ME_REFERRALS,
  QUERY_ME_SUBSCRIBERS_LIST,
  QUERY_TAGS
} from 'graphql/queries'
import { QUERY_EARNINGS, QUERY_EARNINGS_BY_MONTH } from 'graphql/queries/earnings'
import { MESSAGE_SETTINGS } from 'graphql/queries/messageSettings'
import {
  Account,
  AccountType,
  Approval,
  Audience,
  ImageAsset,
  MessageSettings,
  MobileVerificationResult,
  MutationMessagingPreferencesArgs,
  MutationSendMobileVerificationArgs,
  MutationUpdateDisplayNameArgs,
  MutationVerifyMobileArgs,
  Profile,
  QueryEarningsQuery,
  QueryEarningsQueryVariables,
  Referral,
  SendMessageInput,
  SendMobileVerificationInput,
  SortBy,
  SortDirection,
  VerifyMobileInput
} from 'graphql/types'
import { publicRequest, request } from 'lib/gql/client'
import { gqlRequest } from 'lib/gql/gqlRequest'
import { utmCampaign } from 'lib/helpers'
import { isBrowser, isServer } from 'lib/nextjs'
import { poll } from 'lib/poll'
import { NSFW_EVENT } from 'lib/tracking/types'
import { query } from 'lib/urql/query'
import { Button } from 'primitives/Button'
import { Flex } from 'primitives/Flex'
import { AppBannerKeys } from 'shared/Banner/types'
import { gql } from 'urql'
import { NotificationStore } from './Notifications'
import { RootStore } from './RootStore'
import { UserEarningsStore } from './UserEarningsStore'
import { UserOnboardingStore } from './UserOnboardingStore'
import { UserSuggestions } from './UserSuggestions'

const UNREAD_COUNT_POLL_DURATION_MIN = 1

interface UserStoreProps {
  account?: Account
  token?: string
}

export class UserStore {
  root: RootStore

  /**
   * NSFW+ user account data
   */
  account?: Account = undefined

  /**
   * Privy account data
   */
  privyUser?: PrivyUser = undefined

  // Because account can be partially hydrated (via ssr withAuthedPage), to prevent fetching account
  // on every client page navigation, just adding a quick field here to detect that we've run this
  // fetch on the client. Could be replaced with a DataStore pattern for account e.g UserAccountStore.
  accountInitialized = false
  isFetching = false
  suggestions: UserSuggestions
  notifications: NotificationStore
  earnings?: UserEarningsStore = undefined
  onboarding?: UserOnboardingStore = undefined

  /**
   * NSFW+ bearer token
   */
  token?: string = undefined

  messaging: MessageSettings = {}
  // TODO: refactor to DataStore
  referrals: Referral[] = []

  constructor(data: UserStoreProps = {}, root: RootStore) {
    this.root = root
    this.suggestions = new UserSuggestions(root)
    this.notifications = new NotificationStore(root)
    makeAutoObservable(this)
    // Hydrate needs to run after observables are set in this store to interact with flow fetchers.
    // TODO: review the hydrate pattern - there are 2 phases now
    // - 1. init before attaching observables (do we really need to do this?)
    // - 2. hydrate observables with server or async client fetched data AFTER decorating stores with mobx.
    this.hydrate(data)

    reaction(
      () => this.isLoggedIn,
      (isLoggedIn) => {
        if (isLoggedIn) {
          poll(
            () => this.notifications.fetchUnread(),
            UNREAD_COUNT_POLL_DURATION_MIN * 60 * 1000,
            () => !this.isLoggedIn
          )
        }
      },

      { fireImmediately: isBrowser() }
    )

    reaction(
      () => this.onboarding?.data,
      (onboardingData) => {
        if (!onboardingData) return

        if (this.requiresKYCApproved && !this.root.onboarding.showOnboardingModal) {
          this.root.onboarding.continueOnboardingBanner()
        }

        // Show the get started banner if onboarding is complete
        if (!this.requiresKYCApproved && !this.root.onboarding.isGettingStartedComplete) {
          this.root.onboarding.getStartedBanner()
        }
      },
      { fireImmediately: isBrowser() }
    )
  }

  hydrate({ account, token }: UserStoreProps = {}) {
    if (account) this.account = account
    this.token = token || Cookies.get(TOKEN_COOKIE)
    this.clientHydrate()
    return this
  }

  private clientHydrate() {
    if (isServer()) return

    if (this.token) {
      if (!this.accountInitialized) this.fetchInitialAccount()
    }
  }

  get type() {
    switch (true) {
      case this.accountType === AccountType.CREATOR:
        return 'creator'
      case this.accountType === AccountType.FAN:
        return 'fan'
      // DEPRECATED
      case this.accountType === AccountType.ADMIRER:
        return 'admirer'
      default:
        return 'guest'
    }
  }

  get accountType() {
    return this.account?.accountType
  }

  get isCreator() {
    return this.accountType === AccountType.CREATOR
  }

  get isFan() {
    return this.accountType === AccountType.ADMIRER || this.accountType === AccountType.FAN
  }

  get avatar() {
    return this.account?.avatar
  }

  get avatarUrl() {
    return this.account?.avatar?.url
  }

  get name() {
    return this.account?.displayName || this.username
  }

  get displayName() {
    return this.account?.displayName
  }

  get username() {
    return this.account?.username
  }

  get email() {
    return this.account?.email
  }

  get bio() {
    return this.account?.bio
  }

  get pronouns() {
    return this.account?.pronouns
  }

  get profileId() {
    // yes the account userId is the same as the profileId
    return this.account?.userId
  }

  get isLoggedIn() {
    return Boolean(this.token)
  }

  get isLoggedOut() {
    return !this.token
  }

  get messageCredits() {
    return this.account?.messageCredits ?? 0
  }

  /**
   * @computed Does the user need to renew their KYC details?
   */
  get requiresKYCApproved() {
    return (
      // Only applies to creators
      this.isCreator &&
      // We need to have an approval
      this.onboarding?.creatorApprovals.filter((a) => a.approvalStatus === 'APPROVED').length === 0
    )
  }

  get tags() {
    return this.account?.tags || []
  }

  // biome-ignore lint/suspicious/useGetterReturn: <explanation>
  get referralLink() {
    if (isBrowser() && this.username && this.account?.referralCode) {
      const queryString = utmCampaign(this.username, 'refferals', 'interface', {
        nsfwReferrer: this.account.referralCode
      })
      return `https://${window.location.host}/${this.username}?${queryString}`
    }
  }

  setMessageCredits(credits: number) {
    if (!this.account) throw new Error('Cannot set credits for a user that has no account')

    this.account.messageCredits = credits
  }

  isSubscribed(profileId: string) {
    return this.account?.metadata?.subscribedProfiles?.includes(profileId)
  }

  async applyLogin(token?: string | null) {
    if (!token) return
    // Set token as cookie
    this.token = token
    Cookies.set(TOKEN_COOKIE, token)
    // Hydrate account state
    return this.fetchInitialAccount()
  }

  logout(redirect?: string) {
    this.root.wallets.connectedWallet?.disconnect()
    this.account = undefined
    this.token = undefined
    Cookies.remove(TOKEN_COOKIE)
    localStorage.clear()
    if (redirect) {
      Router.push(redirect)
    }
    // store.ui is hydrated with isWithAuthedPage by the withAuthedPage HOC as a quick logout redirection solution.
    else if (this.root.ui.isWithAuthedPage) {
      Router.push(DEFAULT_HOME_ROUTE)
    }
  }

  updateAvatar(newAvatar: ImageAsset) {
    if (this.account) {
      this.account.avatar = newAvatar
      this.root.profiles.get(this.account.userId)?.setAvatar(newAvatar)

      this.root.analytics.track(NSFW_EVENT.SETTINGS_UPDATE_AVATAR, {
        profileId: this.profileId
      })
    }
  }

  setPrivyUser(privyUser: PrivyUser) {
    this.privyUser = privyUser
  }

  async authenticateWithPrivy(accessToken: string): Promise<boolean> {
    const resp = await publicRequest<AuthenticateWithPrivyMutation>(AUTHENTICATE_WITH_PRIVY, {
      input: {
        accessToken
      }
    })
    console.log('resp')
    const token = resp.authenticateWithPrivy.token

    if (!token) {
      toast({
        message: 'Error authenticating account',
        type: 'error'
      })
      return false
    }

    await this.applyLogin(token).then((account) => {
      this.root.analytics.track(NSFW_EVENT.WALLET_LINKED, {
        userType: account?.accountType,
        walletType: this.root.wallets.connectedWallet?.walletClientType
        // chainId: walletData.chainId
      })

      // Remove referral code if it was successfully applied
      if (account?.referralCode === this.root.ui.referralCode) {
        this.root.ui.removeReferralCode()
      }
    })

    return true
  }

  /**
   * !!!!! IMPORTANT !!!!!
   *
   * * - Should only be called once on initial client hydration or login.
   * * - Do not implement this method.
   * * - Create specific fetchers for refetching account data as seen in public methods below.
   */
  async fetchInitialAccount() {
    if (this.isFetching) return this.account
    this.isFetching = true

    const resp = await request<MeResponse>(
      gql`
      ${ONBOARDING_FIELDS}
      query InitialLoadMeQuery {
        me {
          userId
          username
          displayName
          accountType
          email
          pronouns
          tags {
            tagId
            value
          }
          avatar {
            url
          }
          bio
          referralCode
          messageCredits
          wallets {
            address
            title
            provider
            isLinked
          }
          metadata {
            ...OnboardingParts
          }
        }
      }
    `,
      {},
      { token: this.token }
    )

    this.isFetching = false
    if (resp?.me) {
      console.log('fetchInitialAccount()', resp.me)

      this.account = resp.me
      this.onboarding = new UserOnboardingStore({
        data: resp.me.metadata.onboarding
      })
      this.accountInitialized = true
    } else {
      console.log('Error fetching account', resp)
      // TODO: this could maybe give a more useful error message for logout reason, i.e invalid token.
      toast({
        message: 'Error fetching account',
        type: 'error'
      })
      this.logout()
    }

    return this.account
  }

  async fetchMessageCredits() {
    const { data, error } = await gqlRequest<MeResponse>(
      gql`
        query FetchMessageCredits {
          me {
            messageCredits
          }
        }
      `
    )
    if (data?.me && this.account) {
      this.account.messageCredits = data.me.messageCredits
    } else {
      console.log('Error fetching credits', error)
      toast({
        message: 'Error fetching credits',
        type: 'error'
      })
    }
  }

  async updatePronouns(newPronouns: string) {
    try {
      await request(
        UPDATE_PRONOUNS,
        {
          pronouns: newPronouns
        },
        { token: Cookies.get(TOKEN_COOKIE) }
      )
      // FIXME: This should not be getting called...
      this.fetchInitialAccount()
    } catch (error) {
      console.log('error', error)
    }
  }

  async updateTags(tagIds: string[]) {
    try {
      const resp = await request<UpdateTagsMutation>(
        UPDATE_TAGS,
        {
          tagIds: tagIds
        },
        { token: Cookies.get(TOKEN_COOKIE) }
      )
      return resp.updateTags
    } catch (error) {
      return error
    }
  }

  async createTag(tagKey: string, value: string, emoji?: string): Promise<Tag | unknown> {
    try {
      const resp = await request<CreateTagMutation>(
        CREATE_TAG,
        {
          input: {
            key: tagKey,
            value,
            emoji
          }
        },
        { token: Cookies.get(TOKEN_COOKIE) }
      )
      return resp.createTag
    } catch (error) {
      return error
    }
  }

  async fetchTags(post: TagKey, _query: string): Promise<Tag[]> {
    const { data, error } = await query<TagsQuery, TagsQueryVariables>(QUERY_TAGS, {
      input: { key: post, query: _query }
    })

    if (error) {
      console.error('Error fetching tags', error)
      throw error
    }

    if (!this.account) throw new Error('Cannot fetch tags before account is initialized')

    if (data) {
      return JSON.parse(JSON.stringify(data.tags.items))
    }

    return []
  }

  /**
   * TODO: Move to a separate store
   */
  async fetchWallets() {
    const { data, error } = await gqlRequest<MeResponse>(
      gql`
        query QueryUserWallets {
          me {
            wallets {
              address
              title
              provider
              isLinked
            }
          }
        }
      `
    )

    if (error) {
      console.error('Error fetching user wallets', error)
      throw error
    }

    if (!this.account) throw new Error('Cannot fetch wallets before account is initialized')

    if (data) {
      this.account.wallets = data.me.wallets
    }

    return this.account.wallets
  }

  /**
   * Fetched when account is set in the UiStore on client hydrate (see comment for reasoning)
   */
  async fetchOnboarding() {
    this.onboarding = new UserOnboardingStore({ isFetching: true })

    const { data, error } = await gqlRequest<MeResponse>(parse(ME_ONBOARDING))

    if (error) {
      console.error('Error fetching user approvals', error)
      this.onboarding.error = error
    }
    if (data) {
      this.onboarding.data = data.me.metadata.onboarding
    }

    this.root.onboarding.calculateGettingStarted()

    return this.onboarding
  }

  async requestKYCApproval() {
    const { data, error } = await gqlRequest<{ requestApproval: Approval }>(REQUEST_APPROVAL, {})

    if (!data || error) {
      console.error('KYC renewal error', error)
      toast({
        message: 'Error requesting KYC. Please contact support.',
        type: 'error'
      })
      throw new Error('KYC renewal error')
    }
    this.onboarding?.appendCreatorApprovals(data.requestApproval)
  }

  private openInfoModalFirstTime() {
    // Check if there are any boolean values in the onboarding that are false
    const filterBoolOnboarding = Object.entries(this.onboarding?.data ?? {})
      .filter(([_key, value]) => typeof value === 'boolean' && value === false)
      .map(([key, value]) => ({ key, value }))

    const isOnboardingComplete = filterBoolOnboarding.length === 0

    if (typeof window !== 'undefined') {
      if (localStorage.getItem('infoModal') === 'open' || isOnboardingComplete) {
        return
      }
      this.root.ui.openGettingStartedModal()
      localStorage.setItem('infoModal', 'open')
    }
  }

  async fetchEarnings(byMonth = false) {
    this.earnings = new UserEarningsStore({ isFetching: true })

    const { data, error } = await gqlRequest<QueryEarningsQuery, QueryEarningsQueryVariables>(
      byMonth ? QUERY_EARNINGS_BY_MONTH : QUERY_EARNINGS,
      null
    )

    if (error || !data) {
      console.error('Unable to fetch user earnings', error)
      this.earnings.error = error
      toast({
        message: error?.message ?? 'Error fetching earnings',
        type: 'error'
      })
    }

    if (data?.earnings) {
      // TODO: type `Earnings['sales]` and `QueryEarningsQuery['sales']` mismatch
      // @ts-expect-error
      this.earnings.data = data.earnings
    }

    return this.earnings
  }

  // PROFILE SETTINGS MUTATIONS

  /**
   * Update displayName on the account
   */
  async updateDisplayName(displayName: string) {
    if (!this.account) throw new Error('Cannot update displayName without an account')

    const { error, data } = await gqlRequest<
      { updateDisplayName: { displayName: string } },
      MutationUpdateDisplayNameArgs
    >(UPDATE_DISPLAY_NAME_MUTATION, { displayName })

    if (error || !data) {
      console.error('error updating display name', error)
      throw error
    }

    this.root.analytics.track(NSFW_EVENT.SETTINGS_UPDATE_DISPLAY_NAME, {
      profileId: this.profileId
    })

    // Hide update display name banner
    if (this.account.displayName?.includes('...')) {
      this.root.ui.hideBanner(AppBannerKeys.ADD_NAME)
    }

    this.account.displayName = data.updateDisplayName.displayName
    const profileInstance = this.root.profiles.get(this.account.userId)
    if (profileInstance) {
      profileInstance.displayName = data.updateDisplayName.displayName
    }

    return this.account.displayName
  }

  async updateBio(bio: string) {
    if (!this.account) throw new Error('Cannot update bio without an account')

    const resp = await request<UpdateBioMutation>(
      UPDATE_BIO,
      { bio },
      { token: Cookies.get(TOKEN_COOKIE) }
    )

    this.root.analytics.track(NSFW_EVENT.SETTINGS_UPDATE_BIO, {
      profileId: this.profileId
    })

    this.account.bio = resp.updateBio.bio
    const profileInstance = this.root.profiles.get(this.account.userId)

    if (profileInstance) {
      profileInstance.bio = resp.updateBio.bio
    }

    return this.account.bio
  }

  async changeUsername(username: string) {
    console.log('changeUsername()', username)

    if (!this.account) throw new Error('Cannot update username without an account')

    const resp = await request<ChangeUsernameMutation>(
      CHANGE_USERNAME,
      {
        input: { username }
      },
      {
        token: Cookies.get(TOKEN_COOKIE)
      }
    )

    this.root.analytics.track(NSFW_EVENT.SETTINGS_UPDATE_USERNAME, {
      profileId: this.profileId
    })

    this.account.username = resp.changeUsername.username
    const profileInstance = this.root.profiles.get(this.account.userId)
    if (profileInstance) {
      profileInstance.username = resp.changeUsername.username
    }
    return this.username
  }

  // Update Message Preferences
  async updateMessagePreferences(audience: Audience, rate: number) {
    if (!this.account) throw new Error('Cannot update bio without an account')

    const { error, data } = await gqlRequest<
      { messagingPreferences: { messaging: MessageSettings } },
      MutationMessagingPreferencesArgs
    >(UPDATE_MESSAGE_PREFERENCES, { input: { audience, rate } })

    if (data) {
      this.messaging = data.messagingPreferences.messaging
    }

    if (error || !data) {
      console.error('error updating messages', error)
      throw error
    }
  }

  // Phone Number Update
  async sendMobileVerification(input: SendMobileVerificationInput) {
    const { error, data } = await gqlRequest<
      { mobile: MobileVerificationResult },
      MutationSendMobileVerificationArgs
    >(SEND_MOBILE_VERIFICATION_MUTATION, { input })

    // TODO: Handle errrors
    if (error) {
      console.error('SendMobileVerification mutation error: ', error)
    }

    if (data) {
      return data
    }
  }

  /**
   * @deprecated No longer used in signup flow
   */
  async verifyMobile(input: VerifyMobileInput) {
    const { error, data } = await gqlRequest<{ verifyMobile: boolean }, MutationVerifyMobileArgs>(
      VERIFY_MOBILE,
      {
        input
      }
    )

    // TODO: Handle errrors
    if (error) {
      console.error('VerifyMobile mutation error: ', error)
    }

    if (data) {
      return data
    }
  }

  /**
   * Helper function to check if a wallet has been registered to a NSFW+ account
   */
  isWalletRegistered(address?: string) {
    if (address && this.account?.wallets && this.account.wallets.length > 0) {
      console.info(
        'Checking if wallet is linked',
        this.account.wallets.map((w) => [w.address, w.isLinked])
      )
      const wallet = this.account.wallets.find(
        // ! me() query address for wallets can sometimes be persisted uppercased
        (wallet) => wallet.address.toLowerCase() === address.toLowerCase()
      )
      return Boolean(wallet?.isLinked)
    }

    return false
  }

  async deleteAccount() {
    const { error } = await gqlRequest(DELETE_ACCOUNT, {})

    if (error) {
      console.error('DeleteAccount mutation error: ', error)
      throw error
    }

    this.logout(DEFAULT_HOME_ROUTE)
  }

  async fetchMessagePreferences() {
    const { data, error } = await gqlRequest<{ viewProfile: Profile }, { profileId?: string }>(
      MESSAGE_SETTINGS,
      {
        profileId: this.profileId
      }
    )

    if (error) {
      console.error('Error fetching message preferences', error)
      return
    }

    if (data?.viewProfile) {
      this.messaging = data?.viewProfile?.messaging ?? this.messaging
    }
  }

  async fetchRefferals() {
    const { data, error } = await gqlRequest<QueryReferralsQuery>(parse(QUERY_ME_REFERRALS), {
      options: {
        limit: 10,
        lastRecord: 0,
        sortBy: SortBy.CREATED_AT,
        sortDirection: SortDirection.DESC
      }
    })

    if (!data || error) {
      console.error('Error fetchin referrals', error)
      return
    }
    if (data?.me.referrals) {
      this.referrals = data.me.referrals.items as Referral[]
    }
  }

  async sendMessage(input: SendMessageInput) {
    const { data, error } = await gqlRequest<{ sendMessage: number }>(SEND_MESSAGE, { input })
    if (data && this.account) {
      this.account.messageCredits = data.sendMessage
    }
    if (!data || error) {
      console.error('ERROR submitting message sent to API', error)
      throw error ? error : new Error('Error confirming message sent')
    }
  }

  async fetchSubscribersList(): Promise<void> {
    const { data, error } = await gqlRequest<MeResponse>(parse(QUERY_ME_SUBSCRIBERS_LIST))
    if (error || !data) {
      console.error('Error fetching user approvals')
      return
    }
    if (data && this.account) {
      this.account.metadata.subscribedProfiles = data.me.metadata.subscribedProfiles
    }
  }
}
