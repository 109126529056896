import packageJson from '../../package.json'

const appVersion = () => {
  return process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
    ? `${
        packageJson.version
      }-${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA.slice(0, 7)}`
    : packageJson.version
}

export default appVersion
