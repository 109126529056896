import { gql } from 'urql'

export const SEND_MOBILE_VERIFICATION_MUTATION = gql`
  mutation SendMobileVerification($input: SendMobileVerificationInput!) {
    sendMobileVerification(input: $input) {
      verificationId
      expiresAt
    }
  }
`
export const SEND_UPDATE_MOBILE_VERIFICATION_MUTATION = gql`
  mutation SendUpdateMobileVerification($input: SendMobileVerificationInput!) {
    sendUpdateMobileVerification(input: $input) {
      verificationId
      expiresAt
    }
  }
`

export const VERIFY_MOBILE = gql`
  mutation VerifyMobile($input: VerifyMobileInput!) {
    verifyMobile(input: $input)
  }
`

export const UPDATE_MOBILE_MUTATION = gql`
  mutation UpdateMobile($input: UpdateMobileInput!) {
    updateMobile(input: $input) {
      userId
      mobile
    }
  }
`
