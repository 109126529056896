import { gql } from 'urql'

export const QUERY_NOTIFICATIONS = gql`
  query QueryNotifications($options: PaginationOptions) {
    notifications(options: $options) {
      cursor
      hasNextPage
      items {
        ... on Notification {
          notificationId
          post {
            asset {
              media {
                __typename
                ... on ImageAsset {
                  url
                }
                ... on VideoAsset {
                  url
                }
              }
            }
          }
          actor {
            accountType
            profileId
            username
            displayName
            avatar {
              url
            }
          }
          title
          userId
          notificationType
          createdAt
          postId
          isRead
          paymentAmount
          paymentProductType
          paymentCurrency
          withdrawalAmount
          withdrawalTxn
          withdrawalCurrency
        }
      }
    }
  }
`
export const QUERY_UNREAD_NOTIFICATIONS = gql`
  query UnreadNotificationsCount {
    unreadNotificationsCount
  }
`
