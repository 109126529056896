import { createContext, ProviderProps, useCallback, useEffect } from 'react'
import { RootStore, initializeStore } from 'models/RootStore'
import Router from 'next/router'

export const RootStoreContext = createContext<null | RootStore>(null)

type StoreProviderProps = ProviderProps<Partial<RootStore> | undefined>

export const StoreProvider = ({ children, value = {} }: StoreProviderProps) => {
  const store = initializeStore(value)

  const handleRouterChange = useCallback(() => {
    store.ui.setRouteHistory({
      lastPath: Router.asPath, // e.g /post/62942a53f08096aa6eae61cd
      lastRoute: Router.route // e.g /post/[postId]
    })
    // Close the post modal when the client navigates away from the current page
    store.ui.closePostModal(false)
  }, [store.ui])

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouterChange)
    return () => {
      Router.events.off('routeChangeStart', handleRouterChange)
    }
  }, [handleRouterChange])

  return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>
}
