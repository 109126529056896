import { gql } from 'graphql-request'

export const CREATE_TAG = gql`
   mutation CreateTag($input: TagInput!) {
     createTag(input: $input) {
       key
       slug
       value
       tagId
     }
   }
 `
