import { AnalyticsBrowser } from '@segment/analytics-next'
import { makeAutoObservable, reaction } from 'mobx'
import { EventPropsUnion, NSFW_EVENT } from 'lib/tracking/types'
import appVersion from 'lib/nextjs/appVersion'
import { RootStore } from './RootStore'
import { isServer } from 'lib/nextjs'
import debounce from 'lodash/debounce'

const DEFAULT_WRITE_KEY = 'tpaMHil2qOu68gwiCS2yGTDXQdcUJ7he'
const WRITE_KEY = process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY || DEFAULT_WRITE_KEY

export class Analytics {
  root: RootStore
  api: AnalyticsBrowser

  constructor(root: RootStore) {
    this.root = root
    this.api = AnalyticsBrowser.load({ writeKey: WRITE_KEY })

    makeAutoObservable(this)

    // --- subscribe to account changes in the UserStore
    reaction(
      () => this.root.user?.account,
      (account) => {
        if (account) {
          this.identify()
          this.track(NSFW_EVENT.LOGIN_COMPLETE)
        }
      },
    )
  }

  identify() {
    if (!this.root.user.account) {
      return console.error('Warning: Cannot identify user without a current session')
    }

    this.api.identify(this.root.user.account.userId, {
      name: this.root.user.name,
      userType: this.root.user.type,
      email: this.root.user.email,
    })
  }

  track(event: NSFW_EVENT, props: Partial<EventPropsUnion> = {}): void {
    if (isServer()) {
      return console.error(
        'Warning: Tracking events server-side not supported; use reactions/effects/user-events to run tracking scripts',
      )
    }
    // universal event properties
    const universal = {
      userType: this.root.user.type,
      appVersion: appVersion(),
    }

    const properties = {
      ...universal,
      ...props,
      url: window.document.location.href || undefined,
      path: window.document.location.pathname || undefined,
      referrer: window.document.referrer || undefined,
      host: window.document.location.host || undefined,
      hostname: window.document.location.hostname || undefined,
      search: window.document.location.search || undefined,
    }

    // Normalise userType if passed in explicity (not derived from user store)
    // - Used in onboarding from query param
    properties.userType = properties.userType?.toLowerCase()

    this.api.track(event, properties)
  }

  debouncedTrack = debounce(
    (event: NSFW_EVENT, props: Partial<EventPropsUnion> = {}) => {
      this.track(event, props)
    },
    // If this value need to change, create local debounced track() method for specific usage
    1000,
  )
}
