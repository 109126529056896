import type { AddEthereumChainParameter } from '@web3-react/types'

import { CHAINS } from 'config/chains'

export interface NetworkListItem {
  id: number
  name: string
  symbol: string
}

export type NetworkList = NetworkListItem[]

interface BasicChainInformation {
  urls: string[]
  name: string
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
}

export interface ExtendedChainInformation extends BasicChainInformation {
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
  default?: boolean
}

export type ChainListItem = BasicChainInformation | ExtendedChainInformation

export interface ChainList {
  // note: js object keys are stringified
  [chainId: string]: ChainListItem
}

export interface ChainIdUrls {
  // note: js object keys are stringified
  [chainId: string]: BasicChainInformation['urls']
}

export const hasExtendedChainInfo = (chain: ChainListItem): chain is ExtendedChainInformation => {
  return 'blockExplorerUrls' in chain
}

export const chainIdAsInt = (chainId: string | number) => {
  let _chainId = String(chainId)

  if (_chainId.includes('eip155:')) {
    _chainId = _chainId.replace('eip155:', '')
  }
  return parseInt(String(_chainId), 10)
}

export const isValidChain = (chainId?: number | string) => {
  if (!chainId) return false
  const _chainId = chainIdAsInt(chainId)

  console.log(`Valid (${process.env.NEXT_PUBLIC_NSFW_ENV}) chains:`, Object.keys(CHAINS))
  return Object.keys(CHAINS).includes(String(_chainId))
}

export const getChainById = (chainId: string | number) => CHAINS[chainId]
