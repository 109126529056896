import { isBrowser } from 'lib/nextjs'
import { makeAutoObservable, reaction } from 'mobx'

import { Analytics } from './Analytics'
import { ContractsRegistry } from './cache/contracts.registry'
import { ChatStore } from './ChatStore'
import { CreatorPlans } from './CreatorPlans'
import { ContractStore } from './contract.store'
import { FeedStore } from './feed.store'
import { PaymentPreferences } from './PaymentPreferences'
import { ProfilesCache } from './cache/profiles.cache'
import { TransactionStore } from './transaction.store'
import { UiStore } from './UiStore'
import { UserStore } from './UserStore'
import { WithdrawalStore } from './withdrawal.store'
import { enableStaticRendering } from 'mobx-react-lite'
import { isServer } from 'lib/nextjs'
import { WalletsStore } from './wallets.store'
import { OnboardingStore } from './onboarding.store'
import { AccountStore } from './account.store'
import { NetworkStore } from './network.store'

// Configure mobx for SSR
enableStaticRendering(isServer())

export class RootStore {
  account: AccountStore
  analytics: Analytics
  creatorPlans: CreatorPlans
  contractActions: ContractStore
  contractRegistry: ContractsRegistry
  feed: FeedStore
  paymentPreferences: PaymentPreferences
  transaction: TransactionStore
  ui: UiStore
  user: UserStore
  withdrawal: WithdrawalStore
  network: NetworkStore
  wallets: WalletsStore
  chat: ChatStore
  onboarding: OnboardingStore

  /**
   * App-wide caches
   */
  profiles: ProfilesCache

  lockHydrate = false

  // Create new instances of all the stores, any app store state will be lost via react context storage
  constructor({ feed, ui, user }: Partial<RootStore>) {
    this.account = new AccountStore(this)
    this.profiles = new ProfilesCache([], this)
    this.user = new UserStore(user, this)
    this.analytics = new Analytics(this)
    this.ui = new UiStore(ui, this)
    // FIXME: Types
    // @ts-ignore
    this.feed = new FeedStore(this, feed)
    this.contractRegistry = new ContractsRegistry(this)
    this.paymentPreferences = new PaymentPreferences()
    this.contractActions = new ContractStore(this)
    this.creatorPlans = new CreatorPlans(this)
    this.withdrawal = new WithdrawalStore(this)
    this.wallets = new WalletsStore(this)
    this.chat = new ChatStore(this)
    this.onboarding = new OnboardingStore(this)
    this.network = new NetworkStore(this)
    this.transaction = new TransactionStore(this)
    makeAutoObservable(this)

    reaction(
      () => this.user.account,
      (account, prevAccount) => {
        // Reinit the whole store on user logout to prevent any state bleed between users
        this.lockHydrate = Boolean(prevAccount && !account)
      }
    )
  }

  // Load new state into existing app store instances
  // As this function is an action, observers will be notified (same for nested load functions)
  hydrate({ feed, ui, user }: Partial<RootStore>) {
    try {
      // FIXME: Types
      // @ts-ignore
      this.feed = this.feed.hydrate(feed)
    } catch (err) {
      console.log('Error hydrating feed')
      console.log(err)
    }

    try {
      this.ui = this.ui.hydrate(ui)
    } catch (err) {
      console.log('Error hydrating feed')
      console.log(err)
    }

    try {
      this.user = this.user.hydrate(user)
    } catch (err) {
      console.log('Error hydrating user')
      console.log(err)
    }
    return this
  }
}

export let rootStore: RootStore | undefined

export function initializeStore(initialData: Partial<RootStore>) {
  try {
    const shouldHydrate = isBrowser() && rootStore && !rootStore.lockHydrate
    console.info(`${shouldHydrate ? 'Hydrating' : 'Initializing'} store...`)
    rootStore = shouldHydrate
      ? // Preserve store state on Next.js client router navigation
        rootStore!.hydrate(initialData)
      : // For SSG and SSR always create a new store
        new RootStore(initialData)
    console.log(`Store ${shouldHydrate ? 'Hydrated' : 'Initialized'}.`)
  } catch (err) {
    console.log('initializeStore() error')
    console.log(err)
    console.log('Starting new RootStore...')
    rootStore = new RootStore(initialData)
  }

  return rootStore
}
