import { makeAutoObservable, toJS } from 'mobx'
import { RootStore } from './RootStore'
import { ConnectedWallet } from '@privy-io/react-auth'
import { findChain } from 'config/chains'
import { Chain, createWalletClient, custom } from 'viem'
import { chainIdAsInt } from 'lib/web3/chains'
import { base, baseSepolia } from 'viem/chains'

const DEFAULT_NETWORK = process.env.NEXT_PUBLIC_NSFW_ENV === 'beta' ? base : baseSepolia

export class WalletsStore {
  root: RootStore

  isConnecting = false
  wallets: ConnectedWallet[] = []

  connectedWallet?: ConnectedWallet = undefined

  /**
   * The network the wallet is connected to
   */
  network: Chain = DEFAULT_NETWORK

  constructor(root: RootStore) {
    this.root = root
    console.log('Initializing WalletsStore...')
    makeAutoObservable(this)
  }

  setWallets(wallets: ConnectedWallet[]) {
    this.wallets = wallets

    // Connect eagerly
    this.connectEagerly()
  }

  async connectEagerly() {
    try {
      // Note: this is also set prior by ConnectModal.tsx `authenticateUser` effect. Setting again to for consistency if this method is called from elsewhere in the future.
      this.setIsConnecting(true)
      console.log('Connecting wallet eagerly...')

      const wallet = this.getLinkedWallet()
      if (!wallet) throw new Error('CANNOT EAGERLY CONNECT: No privy address matched')
      console.log('CONNECT EAGER WALLET: ', toJS(wallet))

      const isConnected = await wallet.isConnected()
      console.log(`Wallet connected: ${isConnected}`)

      if (!isConnected) {
        await wallet.loginOrLink()
      }

      this.setConnectedWallet(wallet)
      this.setWalletNetwork(chainIdAsInt(wallet.chainId))
      this.setIsConnecting(false)
    } catch (err) {
      console.error(err)
      this.setIsConnecting(false)
    }
  }

  setIsConnecting(bool: boolean) {
    this.isConnecting = bool
  }

  setConnectedWallet(wallet: ConnectedWallet) {
    this.connectedWallet = wallet
  }

  setWalletNetwork(chainId: number) {
    this.network = findChain(chainId)
    console.log(`Connected network: ${this.network?.name} (${this.network?.id})`)
  }

  get connected() {
    return this.connectedWallet
  }

  get chainId() {
    return this.connectedWallet?.chainId ? chainIdAsInt(this.connectedWallet?.chainId) : undefined
  }

  /**
   * Attempt to switch the network
   * @param chainId
   */
  async switchNetwork(chainId: number) {
    try {
      console.log(`Switching network to ${chainId}`)
      const client = await this.addNetwork(chainId)
      await client.switchChain({ id: chainId })
    } catch (err) {
      console.log('Error switching network', err)
    }
  }

  async addNetwork(chainId: number) {
    const newChain = findChain(chainId)
    if (!newChain) throw new Error('Chain metadata not found')

    const { client } = await this.makeClient(newChain)
    await client.addChain({ chain: newChain })
    return client
  }

  async makeClient(chain = this.network) {
    const provider = await this.resolveProvider()
    if (!provider) throw new Error('Unable to resolve provider.')

    if (!chain) throw new Error('No connected network found')

    return {
      chain,
      provider,
      client: createWalletClient({
        chain: chain,
        transport: custom(provider)
      })
    }
  }

  async resolveProvider() {
    return this.root.wallets.connected?.getEthereumProvider()
  }

  // this.wallets are the list of CONNECTED wallets.
  // privy user may have multiple wallets linked and the `.wallet` object can change during render cycles causing inconsistent matching.
  // This can be reproduced by linking two addresses to an account, and logging the user renders  from `const { user } = usePrivy()` as seen
  // in the ConnectModal cmp.
  private getLinkedWallet() {
    return this.wallets.find((o) =>
      this.root.user.privyUser?.linkedAccounts?.find((acc) => {
        // @ts-ignore acc is a union type called `LinkedAccountWithMetadata` which is not exported to create a typeguard for. If address is present, it should be a `WalletWithMetadata`.
        return acc?.address === o.address
      })
    )
  }
}
