import {
  ProductUnion,
  ExclusivePostProduct,
  TipProduct,
  WithdrawalProduct,
  SubscriptionProduct,
  Media,
  VideoAsset,
  OnboardingUnion,
  CreatorOnboarding,
  MessagePackProduct,
} from 'graphql/types'
import { PostParentFeedStore } from 'models/Post'
import { ProfilePosts } from 'models/ProfilePosts'

export const isPostProduct = (
  product: ProductUnion
): product is ExclusivePostProduct | TipProduct => {
  return (
    product.__typename === 'ExclusivePostProduct' ||
    product.__typename === 'TipProduct'
  )
}

export const isMessagePackProduct = (
  product: ProductUnion
): product is MessagePackProduct => {
  return product.__typename === 'MessagePackProduct'
}

export const isSubProduct = (
  product: ProductUnion
): product is SubscriptionProduct => {
  return product.__typename === 'SubscriptionProduct'
}

export const isWithdrawalProduct = (
  product: ProductUnion
): product is WithdrawalProduct => {
  return product.__typename === 'WithdrawalProduct'
}

export const isVideoAsset = (media?: Media): media is VideoAsset => {
  return media?.__typename === 'VideoAsset'
}

export const isError = (error?: unknown): error is Error => {
  return error instanceof Error
}

export const isCreatorOnboarding = (
  onboarding?: OnboardingUnion
): onboarding is CreatorOnboarding => {
  return onboarding?.__typename === 'CreatorOnboarding'
}

export const isProfileFeedStore = (
  feedStore: PostParentFeedStore
): feedStore is ProfilePosts => {
  return feedStore._feedType === 'profile-feed'
}
