export const MIN_CHUNK_SIZE = 1024 * 1024 * 5 // 5mb
export const DEFAULT_CHUNK_SIZE = 1024 * 1024 * 50 // 50mb
export const MAX_CHUNK_SIZE = 1024 * 1024 * 128 // 128mb
export const DEFAULT_RETRIES = 6
export const DEFAULT_API_HOST = 'ws.api.video'

export declare type ChunkedUploadResponse = {
  readonly uploadedChunk?: boolean
  readonly isProcessing?: boolean
  readonly uploadedAsset?: boolean
  readonly post: { resp?: { public_id?: string } }
}

type RetryStrategy = (
  retryCount: number,
  error: VideoUploadError
) => number | null

interface Origin {
  name: string
  version: string
}

export interface CommonOptions {
  endpoint: string
  videoName: string
  mimeType?: string
  retries?: number
  retryStrategy?: RetryStrategy
  origin?: {
    application?: Origin
    sdk?: Origin
  }
}

export interface WithAccessToken {
  accessToken: string
  refreshToken?: string
  uploadId: string
  postId: string
}

export type VideoUploadError = {
  status?: number
  type?: string
  title?: string
  reason?: string
  raw: string
}

type HXRRequestParams = {
  parts?: {
    currentPart: number
    totalParts: number | '*'
  }
  onProgress?: (e: ProgressEvent) => void
  body: Document | XMLHttpRequestBodyInit | null
}

let PACKAGE_VERSION = ''
try {
  // @ts-ignore
  PACKAGE_VERSION = __PACKAGE_VERSION__ || ''
} catch (e) {
  // ignore
}

export const DEFAULT_RETRY_STRATEGY = (maxRetries: number) => {
  return (retryCount: number, error: VideoUploadError) => {
    if (
      (error.status && error.status >= 400 && error.status < 500) ||
      retryCount >= maxRetries
    ) {
      return null
    }
    return Math.floor(200 + 2000 * retryCount * (retryCount + 1))
  }
}

export abstract class AbstractUploader<T> {
  protected retries: number
  protected headers: { [name: string]: string } = {}
  protected onProgressCallbacks: ((e: T) => void)[] = []
  protected refreshToken?: string
  protected endpoint: string
  protected retryStrategy: RetryStrategy

  // Global cancel event to cancel all running uploader instances
  static globalCancelSignal?: EventTarget
  static setGlobalCancelSignal(signal: EventTarget) {
    AbstractUploader.globalCancelSignal = signal
  }

  constructor(options: CommonOptions & WithAccessToken) {
    this.endpoint = options.endpoint

    if (options.hasOwnProperty('accessToken')) {
      const optionsWithAccessToken = options as WithAccessToken
      if (!optionsWithAccessToken.uploadId) {
        throw new Error("'uploadId' is missing")
      }
      this.refreshToken = optionsWithAccessToken.refreshToken
      this.headers.Authorization = `Bearer ${optionsWithAccessToken.accessToken}`
    } else {
      throw new Error(`Invalid upload configuration`)
    }
    this.headers['NSFW-Origin-Client'] = 'asset-uploader:' + PACKAGE_VERSION
    this.retries = options.retries || DEFAULT_RETRIES
    this.retryStrategy =
      options.retryStrategy || DEFAULT_RETRY_STRATEGY(this.retries)

    if (options.origin) {
      if (options.origin.application) {
        AbstractUploader.validateOrigin(
          'application',
          options.origin.application
        )
        this.headers[
          'AV-Origin-App'
        ] = `${options.origin.application.name}:${options.origin.application.version}`
      }
      if (options.origin.sdk) {
        AbstractUploader.validateOrigin('sdk', options.origin.sdk)
        this.headers[
          'AV-Origin-Sdk'
        ] = `${options.origin.sdk.name}:${options.origin.sdk.version}`
      }
    }
  }

  public onProgress(cb: (e: T) => void) {
    this.onProgressCallbacks.push(cb)
  }

  protected parseErrorResponse(xhr: XMLHttpRequest): VideoUploadError {
    try {
      const parsedResponse = JSON.parse(xhr.response)

      return {
        status: xhr.status,
        raw: xhr.response,
        ...parsedResponse,
      }
    } catch (e) {
      // empty
    }

    return {
      status: xhr.status,
      raw: xhr.response,
      reason: 'UNKWOWN',
    }
  }

  protected sleep(duration: number): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(), duration)
    })
  }

  protected xhrWithRetrier(
    params: HXRRequestParams,
    signal?: EventTarget
  ): Promise<ChunkedUploadResponse> {
    return this.withRetrier(() => this.createXhrPromise(params, signal))
  }

  protected createFormData(
    uploadId: string,
    file: Blob,
    fileName: string,
    fileSize: number,
    fileExtension: string,
    mimeType: string,
    postId: string,
    startByte?: number,
    endByte?: number
  ): FormData {
    const chunk = startByte || endByte ? file.slice(startByte, endByte) : file
    const chunkForm = new FormData()
    // TODO: Could do a chunked upload via graphql in theory...
    // chunkForm.append("operations", `{
    //     "query": "mutation($postId: String!, $media: Upload!) { uploadPostMedia(postId: $postId, media: $media){postId}}",
    //     "variables": {
    //         "postId": "${this.postId}",
    //         "media": null
    //     }
    // }
    // `)
    // chunkForm.append("map", '{"_chunkedAsset":["variables.media"]}');
    // chunkForm.append("_chunkedAsset", chunk, fileName);
    chunkForm.append('isChunk', 'true')
    chunkForm.append('uploadId', uploadId)
    chunkForm.append('fileExtension', fileExtension)
    chunkForm.append('fileSizeBytes', fileSize.toString())
    chunkForm.append('mimeType', mimeType)
    chunkForm.append('postId', postId)
    chunkForm.append('chunk', chunk, fileName)
    return chunkForm
  }

  protected createUploadCompleteFormData(
    uploadId: string,
    fileName: string,
    fileExtension: string,
    fileSize: number,
    mimeType: string,
    postId: string
  ): FormData {
    const form = new FormData()
    form.append('isChunk', 'false')
    form.append('uploadId', uploadId)
    form.append('fileName', fileName)
    form.append('fileExtension', fileExtension)
    form.append('fileSizeBytes', fileSize.toString())
    form.append('mimeType', mimeType)
    form.append('postId', postId)
    return form
  }

  public doRefreshToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      reject('Not implemented')
      // const xhr = new window.XMLHttpRequest();
      // xhr.open("POST", `https://${this.apiHost}/auth/refresh`);
      // for (const headerName of Object.keys(this.headers)) {
      //     if (headerName !== "Authorization") xhr.setRequestHeader(headerName, this.headers[headerName]);
      // }
      // xhr.onreadystatechange = (_) => {
      //     if (xhr.readyState === 4 && xhr.status >= 400) {
      //         reject(this.parseErrorResponse(xhr));
      //     }
      // }
      // xhr.onload = (_) => {
      //     const response = JSON.parse(xhr.response);
      //     if (response.refresh_token && response.access_token) {
      //         this.headers.Authorization = `Bearer ${response.access_token}`;
      //         this.refreshToken = response.refresh_token;
      //         resolve();
      //         return;
      //     }
      //     reject(this.parseErrorResponse(xhr));
      // };
      // xhr.send(JSON.stringify({
      //     refreshToken: this.refreshToken
      // }));
    })
  }

  private createXhrPromise(
    params: HXRRequestParams,
    signal?: EventTarget
  ): Promise<ChunkedUploadResponse> {
    const xhr = new window.XMLHttpRequest()
    let cancel: () => void

    return new Promise((resolve, reject) => {
      console.log('creating request', this.endpoint)

      signal?.addEventListener('cancel', () => cancel())
      AbstractUploader.globalCancelSignal?.addEventListener('cancel', () =>
        cancel()
      )

      xhr.open('POST', `${this.endpoint}`, true)
      if (params.parts) {
        xhr.setRequestHeader(
          'Content-Range',
          `part ${params.parts.currentPart}/${params.parts.totalParts}`
        )
      }
      for (const headerName of Object.keys(this.headers)) {
        xhr.setRequestHeader(headerName, this.headers[headerName])
      }
      if (params.onProgress) {
        xhr.upload.onprogress = (e) => params.onProgress!(e)
      }
      xhr.onreadystatechange = (_) => {
        if (xhr.readyState === 4) {
          // DONE
          if (xhr.status === 401 && this.refreshToken) {
            return this.doRefreshToken()
              .then(() => this.createXhrPromise(params))
              .then((res) => resolve(res))
              .catch((e) => reject(e))
          } else if (xhr.status >= 400) {
            reject(this.parseErrorResponse(xhr))
            return
          }
        }
      }
      xhr.onerror = (e) => {
        reject({
          status: undefined,
          raw: undefined,
          reason: 'NETWORK_ERROR',
        })
      }
      xhr.ontimeout = (e) => {
        reject({
          status: undefined,
          raw: undefined,
          reason: 'NETWORK_TIMEOUT',
        })
      }
      xhr.onload = (_) => {
        if (xhr.status < 400) {
          console.log('xhr.response')
          console.log(xhr.response)
          resolve(JSON.parse(xhr.response))
        }
      }
      xhr.send(params.body)

      cancel = () => {
        xhr.abort()
        reject({
          status: undefined,
          raw: undefined,
          reason: 'REQUEST_CANCELLED',
        })
      }
    })
  }

  private async withRetrier(
    fn: () => Promise<ChunkedUploadResponse>
  ): Promise<ChunkedUploadResponse> {
    return new Promise(async (resolve, reject) => {
      let retriesCount = 0
      while (true) {
        try {
          const res = await fn()
          resolve(res)
          return
        } catch (e: any) {
          const retryDelay = this.retryStrategy(retriesCount, e)
          if (retryDelay === null || e.reason === 'REQUEST_CANCELLED') {
            reject(e)
            return
          }
          console.log(
            `video upload: ${
              e.reason || 'ERROR'
            }, will be retried in ${retryDelay} ms`
          )
          await this.sleep(retryDelay)
          retriesCount++
        }
      }
    })
  }

  private static validateOrigin(type: string, origin: Origin) {
    if (!origin.name) {
      throw new Error(`${type} name is required`)
    }
    if (!origin.version) {
      throw new Error(`${type} version is required`)
    }
    if (!/^[\w-]{1,50}$/.test(origin.name)) {
      throw new Error(
        `Invalid ${type} name value. Allowed characters: A-Z, a-z, 0-9, '-', '_'. Max length: 50.`
      )
    }
    if (!/^\d{1,3}(\.\d{1,3}(\.\d{1,3})?)?$/.test(origin.version)) {
      throw new Error(
        `Invalid ${type} version value. The version should match the xxx[.yyy][.zzz] pattern.`
      )
    }
  }
}
