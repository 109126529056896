import { SnackBar } from 'common/SnackBar'
import { darkToast } from 'common/SnackBar/config'
import snackBar from 'common/SnackBar/config'
import { toast } from 'components/common/Toast'
import { FOLLOW_PROFILE } from 'graphql/mutations/followProfile'
import { UNFOLLOW_PROFILE } from 'graphql/mutations/unfollowProfile'
import { VIEW_PROFILE, ViewProfileResponse } from 'graphql/queries/viewProfile'
import { AccountType, ImageAsset, PostFilter, Profile as ProfileData } from 'graphql/types'
import { FeedProfile, ProfilePaginatedResult } from 'interfaces'
import { urqlClient } from 'lib/urql/client'
import { makeAutoObservable } from 'mobx'
import { ProfilePosts } from './ProfilePosts'
import { RootStore } from './RootStore'

export type ProfileProps = Omit<FeedProfile, 'posts'> & {
  posts?: ProfileData['posts']
  postFilter?: PostFilter
}

export class Profile {
  // Stores / non-api fields
  // --
  root: RootStore
  postFilter: PostFilter
  posts: ProfilePosts

  // Fields
  // --
  profileId: ProfileData['profileId']
  username: ProfileData['username']
  accountType: AccountType
  displayName: ProfileData['displayName']
  isFollowing: ProfileData['isFollowing']
  referralUrl: ProfileData['referralUrl']
  messaging: ProfileData['messaging']
  avatar?: ProfileData['avatar']
  banner?: ProfileData['banner']
  bio?: ProfileData['bio']
  followers?: ProfilePaginatedResult
  following?: ProfilePaginatedResult
  tags?: ProfileData['tags']

  // These stats are not always queried therefor may not be hydrated yet on the instance.
  _followersCount?: ProfileData['followersCount'] = undefined
  _followingCount?: ProfileData['followingCount'] = undefined
  _postsCount?: ProfileData['postsCount'] = undefined
  _activeSubscriberCount?: ProfileData['activeSubscriberCount'] = undefined

  constructor(data: ProfileProps, root: RootStore) {
    this.root = root
    this.hydrate(data)
    makeAutoObservable(this)
  }

  hydrate({ posts, ...data }: ProfileProps) {
    // https://stackoverflow.com/questions/67266810/error-mobx-cannot-apply-observable-to-storeuser-field-not-found
    // https://github.com/vercel/next.js/issues/35721
    this.postFilter = data.postFilter ?? PostFilter.ALL
    this.accountType = data.accountType
    this.profileId = data.profileId
    this.referralUrl = data.referralUrl
    this.username = data.username
    this.avatar = data.avatar
    this.banner = data.banner
    this.bio = data.bio
    this.tags = data.tags
    this.displayName = data.displayName
    this.followers = data.followers
    this.following = data.following
    this.isFollowing = data.isFollowing ?? this.isFollowing ?? false
    this.messaging = data.messaging ?? {}
    // Profile store is rehydrated frequently via ProfileStore.load, so we need to ensure
    // we're keeping the child instances in sync with init vs hydrate.
    this.posts =
      this.posts instanceof ProfilePosts
        ? this.posts.hydrate(posts as Partial<ProfilePosts>)
        : new ProfilePosts(posts as Partial<ProfilePosts>, this.root, this)

    if (data.followersCount) this._followersCount = data.followersCount
    if (data.followingCount) this._followingCount = data.followingCount
    if (data.postsCount) this._postsCount = data.postsCount
    if (data.activeSubscriberCount) this._activeSubscriberCount = data.activeSubscriberCount

    return this
  }

  get followersCount() {
    return this._followersCount ?? 0
  }
  get followingCount() {
    return this._followingCount ?? 0
  }
  get postsCount() {
    return this._postsCount ?? 0
  }
  get activeSubscriberCount() {
    return this._activeSubscriberCount ?? 0
  }

  set followersCount(v: number) {
    this._followersCount = v
  }
  set followingCount(v: number) {
    this._followingCount = v
  }
  set postsCount(v: number) {
    this._postsCount = v
  }
  set activeSubscriberCount(v: number) {
    this._activeSubscriberCount = v
  }

  get followingStatus() {
    return this.isFollowing ? 'Following' : 'Follow'
  }

  get followingActionLabel() {
    return this.isFollowing ? 'Unfollow' : 'Follow'
  }

  get isOwner() {
    return this.root.user.profileId === this.profileId
  }

  get isCreator() {
    return this.accountType === AccountType.CREATOR
  }

  get isFan() {
    return this.accountType === AccountType.ADMIRER || this.accountType === AccountType.FAN
  }

  setAvatar(newAvatar: ImageAsset) {
    if (newAvatar) this.avatar = newAvatar
  }

  toggleFollowing() {
    this.isFollowing = !this.isFollowing
    if (this._followersCount && this._followersCount >= 0) {
      this.followersCount = this.isFollowing
        ? this.followersCount + 1
        : // Just incase
          Math.max(0, this.followersCount - 1)
    }
  }

  async toggleFollowProfile() {
    if (this.root?.ui.handleGatedAction()) return

    this.toggleFollowing()

    const opts = { profileId: this.profileId }

    const { data, error } = await urqlClient
      .mutation<boolean>(this.isFollowing ? FOLLOW_PROFILE : UNFOLLOW_PROFILE, opts)
      .toPromise()

    if (data === undefined || error) {
      toast({
        message: `Unable to ${this.isFollowing ? 'unfollow' : 'follow'}`,
        type: 'error'
      })
      this.toggleFollowing()
    }

    return this.isFollowing
  }

  async refetch() {
    const { data } = await urqlClient
      .query<ViewProfileResponse>(VIEW_PROFILE, {
        username: this.username,
        postOptions: { limit: 12 }
      })
      .toPromise()

    if (data?.viewProfile) {
      // TODO: Fix types
      // @ts-ignore
      this.hydrate(data.viewProfile)
    } else {
      toast({
        message: `Error fetching profile @${this.username}`,
        type: 'error'
      })
    }

    return this
  }
}
