import { makeAutoObservable } from 'mobx'
import { QUERY_SUGGESTIONS } from 'graphql/queries/suggestions'
import { Profile, ProfileProps } from './Profile'
import { RootStore } from './RootStore'
import { query } from 'lib/urql/query'

export class UserSuggestions {
  root: RootStore
  loading = false
  profiles: Profile[] = []
  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
    this.fetch()
  }

  async fetch() {
    // Return if already loading
    if (this.loading) return

    // Set loading to true
    this.loading = true
    const { data } = await query<{ suggestions: ProfileProps[] }>(QUERY_SUGGESTIONS)

    if (data?.suggestions) {
      this.profiles = data.suggestions.map((p) => this.root.profiles.load(p))
    }
    this.loading = false
  }
}
