import { NextPageContext } from 'next'
import Router from 'next/router'

export const nextContextRedirect = (
  ctx: NextPageContext | undefined,
  path: string
) => {
  const isServer = !!ctx?.req
  if (isServer) {
    ctx.res?.writeHead(307, { Location: path })
    ctx.res?.end()
  } else {
    Router.replace(path)
  }
  return {}
}
