import { SnackBar } from 'common/SnackBar'
import { darkToast, errorToast } from 'common/SnackBar/config'
import { toast } from 'components/common/Toast'
import { ErrorCodes } from 'config/errorCodes'
import {
  ARCHIVE_PLAN_MUTATION,
  CREATE_PLAN_MUTATION,
  UPDATE_PLAN_MUTATION
} from 'graphql/mutations/creatorPlans'
import { LIST_PLAN_OPTIONS } from 'graphql/queries/listUnconfiguredPlans'
import { VIEW_CREATOR_PLANS } from 'graphql/queries/viewCreatorPlans'
import {
  CreatePlanInput,
  CreatePlanMutation,
  ListUnconfiguredPlansQuery,
  MutationArchivePlanArgs,
  MutationUpdatePlanArgs,
  Plan,
  PlanOption,
  UpdatePlanInput,
  ViewCreatorPlansQuery
} from 'graphql/types'
import { request } from 'lib/gql/client'
import { mutation } from 'lib/urql/mutation'
import { makeAutoObservable } from 'mobx'
import { getGraphQLErrorByCode } from '../lib/urql/errors'
import { RootStore } from './RootStore'

export class CreatorPlans {
  root: RootStore
  plans: Plan[]
  unconfiguredPlans: PlanOption[]

  constructor(root: RootStore) {
    this.root = root
    this.plans = []
    this.unconfiguredPlans = []

    makeAutoObservable(this)
  }

  async getPlans() {
    const resp = await request<ViewCreatorPlansQuery>(
      VIEW_CREATOR_PLANS,
      {
        creatorId: this.root.user.account?.userId
      },
      { token: this.root.user.token }
    ).catch((err) => {
      if (getGraphQLErrorByCode(err, ErrorCodes.NULL_PLANS)) {
        this.plans = []
      } else {
        throw err
      }
    })

    if (resp) {
      this.plans = resp.viewCreatorPlans as Plan[]

      console.log('this.plans()')
      console.log('this.plans()')
      console.log('this.plans()', this.plans)
    }
  }

  /**
   * Get plans that have not been configured yet.
   */
  async fetchUnconfiguredPlans() {
    const resp = await request<ListUnconfiguredPlansQuery>(
      LIST_PLAN_OPTIONS,
      {},
      {
        token: this.root.user.token
      }
    )

    this.unconfiguredPlans = resp.listUnconfiguredPlans
    return this.unconfiguredPlans
  }

  async createPlan(input: CreatePlanInput) {
    const resp = await request<CreatePlanMutation>(
      CREATE_PLAN_MUTATION,
      { input },
      { token: this.root.user.token }
    )

    // TODO: the response does not contain an error
    // if (error) {
    //   toast(() => <SnackBar title="Could not create plan" />, errorToast)
    //   return
    // }

    if (resp.createPlan) {
      // @ts-ignore
      this.plans.push(resp.createPlan)

      this.unconfiguredPlans = this.unconfiguredPlans.filter(
        (p) => p.id !== input.subscriptionSettingId
      )

      toast({
        message: 'Plan created',
        type: 'success'
      })
    }
  }

  async archivePlan(planId: string) {
    const { error, data } = await mutation<{ archivePlan: boolean }, MutationArchivePlanArgs>(
      ARCHIVE_PLAN_MUTATION,
      {
        planId
      }
    )

    if (error) {
      toast({
        message: 'Could not archive plan',
        type: 'error'
      })
      return
    }

    if (data?.archivePlan) {
      await this.fetchUnconfiguredPlans()
      this.plans = this.plans.filter((p) => p.planId !== planId)
    }
  }

  async updatePlan(input: UpdatePlanInput) {
    const { error, data } = await mutation<{ updatePlan: Plan }, MutationUpdatePlanArgs>(
      UPDATE_PLAN_MUTATION,
      {
        input
      }
    )

    if (error) {
      toast({
        message: 'Could not update plan',
        type: 'error'
      })
      return
    }

    if (data) {
      const updatedPlan = data.updatePlan
      this.plans = this.plans.map((p) => (p.planId !== updatedPlan.planId ? p : updatedPlan))
      toast({
        message: 'Plan updated successfully',
        type: 'success'
      })
    }
  }
}
