export interface APIErrorFields {
  status: number
  message: string
  code?: string
}

export const DEFAULT_API_ERROR_PROPS = Object.freeze({
  status: 500,
  message: 'Unknown error occured',
})

// e.g throw new APIError({ status: res.status, message: res.message })
export class APIError extends Error {
  readonly status: number
  readonly code?: string | null

  // base constructor only accepts string message as an argument
  // we extend it here to accept an object, allowing us to pass other data
  constructor({ status, message, code }: APIErrorFields) {
    super(message)
    this.status = status
    this.code = code
  }
}

// `catch(err)` argument has unknown type, form a constant type.
export const getAPIError = (err: unknown) =>
  err instanceof APIError ? err : { ...DEFAULT_API_ERROR_PROPS }
