import { CombinedError } from 'urql'
import { GraphQLError } from 'graphql'
import { ErrorCodes } from 'config'

export interface ApiGraphQLError extends GraphQLError {
  // TODO: type all possible codes in nsfw/shared
  originalError: GraphQLError['originalError'] & { code: string }
}

// No way to type graphQLErrors via generics
export const getGraphQLErrors = (error?: CombinedError) =>
  error?.graphQLErrors as ApiGraphQLError[] | undefined

export const getMessageFromGQLErrors = (error?: CombinedError) => {
  const apiErrors = getGraphQLErrors(error)
  return (
    apiErrors?.[0]?.message || 'Something went wrong. Please contact support.'
  )
}

export const getGraphQLErrorByCode = (
  error: CombinedError,
  codes: string | string[]
) => {
  const _codes = ([] as string[]).concat(codes)
  return (
    getGraphQLErrors(error)?.filter((e) =>
      _codes.includes(e.originalError.code)
    ) || []
  )
}

export const isGraphQLError = (
  error: CombinedError | undefined,
  codes: string | string[]
) => error && getGraphQLErrorByCode(error, codes).length > 0

export const getCombinedErrorCode = (combinedError: CombinedError) => {
  const errors = getGraphQLErrors(combinedError)
  const error = errors?.[0]
  return error ? getGraphQLErrorCode(errors?.[0]) : undefined
}

export const getGraphQLErrorCode = (error: ApiGraphQLError) =>
  error.originalError.code

export const hasErrorCode = (
  code: ErrorCodes,
  stack?: CombinedError
): boolean => {
  return !!getGraphQLErrors(stack)?.some(
    (err) => err.originalError!.code === code
  )
}
