import { gql } from 'urql'

export const QUERY_EXCHANGE_RATE = gql`
  query ExchangeRate($input: ExchangeRateInput!) {
    exchangeRate(input: $input) {
      chainId
      spotPrice
      from {
        symbol
        amount
      }
      to {
        symbol
        amount
      }
    }
  }
`
