import { gql } from '@urql/core'
import { ApiMutationInput } from 'interfaces'

export const IS_EMAIL_AVAILABLE = gql`
  query IsEmailAvailable($input: IsEmailAvailableInput!) {
    isEmailAvailable(input: $input)
  }
`

export type IsEmailAvailableVars = ApiMutationInput<Record<'email', string>>
export interface IsEmailAvailableResponse {
  isEmailAvailable: boolean
}
