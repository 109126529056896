import { TokenOptions } from '@nsfw-app/crypto'
import { BigNumberish } from 'ethers'
import { TokenSymbol } from 'graphql/types'
import { makeAutoObservable } from 'mobx'
import { Chain } from 'viem'
import { RootStore } from './RootStore'

/**
 * The AccountStore supercedes the InjectedWallet (deprecated) store
 * and is responsible for managing a user's EOA/smart/embedded wallet
 */
export class AccountStore {
  root: RootStore

  balances = new Map<string | TokenSymbol, BigNumberish>()

  /**
   * Used to control when to sync balances
   */
  hasSyncedBalances = false

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
  }

  get address() {
    return this.root.wallets.connected?.address
  }

  getBalance(token: string | TokenSymbol) {
    const balance = this.balances.get(token)
    console.log(`Getting balance for [${token}]`, balance)
    return balance
  }

  async fetchBalance(tokenSymbol: TokenSymbol | string, chain: Chain): Promise<BigNumberish> {
    const { contract } = await this.root.contractRegistry.instance(tokenSymbol, chain)
    // @ts-ignore
    const balance = await contract.read.balanceOf([this.address])
    return balance
  }

  /**
   * Get balances for all enabled tokens on the current network
   */
  async syncBalances() {
    console.log('----- Syncing Balances -----')

    // Get all accepted tokens
    const tokens = this.root.network.acceptedTokens
    const chain = this.root.network.walletNetwork

    await Promise.all(
      tokens
        .filter((o) => o.isEnabled)
        .map(async (token: TokenOptions) => {
          console.log(`Fetching ${token.symbol} on [chain:${chain.id}]`)
          // Guards
          if (!this.address) throw new Error('No address to fetch balance')

          // Fetch balance
          const balance = await this.fetchBalance(token.symbol, chain).catch((err) => {
            console.error(`Error fetching balance for ${token.symbol}`, err)
            return 0n
          })

          this.balances.set(token.symbol, balance)
        })
    )

    this.setSyncedBalances(true)
  }

  setSyncedBalances(bool: boolean) {
    this.hasSyncedBalances = bool
  }
}
