import { flowResult, makeAutoObservable, reaction } from 'mobx'

import {
  CLEAR_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  TOGGLE_NOTIFICATION_READ
} from 'graphql/mutations/notifications'
import { QUERY_NOTIFICATIONS, QUERY_UNREAD_NOTIFICATIONS } from 'graphql/queries/notifications'
import {
  MutationDeleteNotificationArgs,
  MutationToggleNotificationReadArgs,
  Notification,
  PaginatedResult,
  SortBy,
  SortDirection
} from 'graphql/types'
import { asyncResult } from 'lib/mobx'
import { urqlClient } from 'lib/urql/client'
import { mutation } from 'lib/urql/mutation'
import { RootStore } from './RootStore'

export class NotificationStore {
  root: RootStore
  loading = false
  cursor?: string | null = undefined
  hasNextPage = false
  items: Notification[] = []
  unreadNotifications = 0

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
  }

  async fetch() {
    if (this.loading) return
    this.loading = true

    const { data, error } = await urqlClient
      .query<{ notifications: PaginatedResult }>(QUERY_NOTIFICATIONS, {
        options: {
          limit: 10,
          lastRecord: this.cursor,
          sortBy: SortBy.CREATED_AT,
          sortDirection: SortDirection.DESC
        }
      })
      .toPromise()

    if (!data || error) {
      this.loading = false
      console.error('Error fetching notifications', error)
      return
    }

    this.cursor = data.notifications.cursor
    this.hasNextPage = data.notifications.hasNextPage ?? false
    const newItems =
      (data.notifications.items as Notification[])?.filter((item) => {
        return !this.items.some(
          (existingItem) => existingItem.notificationId === item.notificationId
        )
      }) ?? []

    this.items = [...this.items, ...newItems]
    this.loading = false
  }

  async fetchUnread() {
    const { data, error } = await urqlClient
      .query<{ unreadNotificationsCount: number }>(QUERY_UNREAD_NOTIFICATIONS)
      .toPromise()

    if (!data || error) {
      console.error('Error fetching unread notifications count', error)
      return
    }
    // Reset query state, forcing a refetch next time user toggles notification drawer.
    if (data.unreadNotificationsCount > this.unreadNotifications) {
      this.clearQueryOptions()
    }
    this.unreadNotifications = data.unreadNotificationsCount
  }

  setRead(id: string, isRead = true) {
    const found = this.items.find((i) => i.notificationId == id)
    if (!found) {
      throw new Error('Could not find notification to mark as read')
    }
    found.isRead = isRead
  }

  async toggleNotificationRead(notificationId: string) {
    this.setRead(notificationId)
    const { error } = await mutation<{ isDeleted: boolean }, MutationToggleNotificationReadArgs>(
      TOGGLE_NOTIFICATION_READ,
      { notificationId }
    )
    if (error) {
      this.setRead(notificationId, false)
      console.error(error)
    }
    return this.fetchUnread()
  }

  async deleteNotification(notificationId: string) {
    const found = this.items.find((i) => i.notificationId == notificationId)
    if (!found) {
      throw new Error('Could not find notification to delete')
    }

    const index = this.items.indexOf(found)
    this.items.splice(index, 1)

    const { error } = await mutation<
      { notification: Notification },
      MutationDeleteNotificationArgs
    >(DELETE_NOTIFICATION, { notificationId })

    if (error) {
      this.items.splice(index, 0, found)
      console.error(error)
    }
  }

  async clearNotifications() {
    const { error, data } = await mutation(CLEAR_NOTIFICATIONS, {})
    if (error) {
      console.error('error clearing notifications', error)
    }
    if (data) {
      this.items = []
      this.unreadNotifications = 0
    }
  }

  // Used when closing the modal, clear the query options
  clearQueryOptions() {
    this.cursor = undefined
    this.items = []
    this.hasNextPage = false
  }
}
