import { gql } from 'urql'

export const TOGGLE_NOTIFICATION_READ = gql`
  mutation ToggleNotificationRead($notificationId: String!) {
    toggleNotificationRead(notificationId: $notificationId) {
      title
      userId
      notificationType
      createdAt
      postId
      isRead
      paymentAmount
      paymentProductType
      paymentCurrency
      withdrawalAmount
      withdrawalTxn
      withdrawalCurrency
    }
  }
`
export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($notificationId: String!) {
    deleteNotification(notificationId: $notificationId) {
      title
      userId
      notificationType
      createdAt
      postId
      isRead
      paymentAmount
      paymentProductType
      paymentCurrency
      withdrawalAmount
      withdrawalTxn
      withdrawalCurrency
    }
  }
`

export const CLEAR_NOTIFICATIONS = gql`
  mutation ClearNotifications {
    clearNotifications
  }
`
