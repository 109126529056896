import { gql } from '@urql/core'

export const UNFOLLOW_PROFILE = gql`
  mutation UnfollowProfile($profileId: String!) {
    unfollowProfile(profileId: $profileId) {
      isFollowing
    }
  }
`

export type UnfollowProfileVars = {
  profileId: string
}
