import { SnackBar } from 'common/SnackBar'
import { darkToast } from 'common/SnackBar/config'
import { toast } from 'components/common/Toast'
import { DEFAULT_HOME_ROUTE, FEED_PAGE_LIMIT } from 'config'
import { AppRoutes } from 'config/routes'
import { ADD_COMMENT } from 'graphql/mutations/addComment'
import { DELETE_POST_MUTATION } from 'graphql/mutations/deletePost'
import { TOGGLE_LIKE_POST, ToggleLikePostResponse } from 'graphql/mutations/toggleLikePost'
import { LOAD_MORE_COMMENTS } from 'graphql/queries/loadMoreComments'
import { VIEW_POST_WITH_COMMENTS } from 'graphql/queries/viewPost'
import {
  Comment,
  Post as PostData,
  PostStatus,
  PostType,
  SortBy,
  SortDirection
} from 'graphql/types'
import { Profile as ProfileData } from 'graphql/types'
import { FeedProfile } from 'interfaces'
import { gqlRequest } from 'lib/gql/gqlRequest'
import { NSFW_EVENT } from 'lib/tracking/types'
import uniqueId from 'lodash/uniqueId'
import { makeAutoObservable } from 'mobx'
import Router from 'next/router'
import { FeedPostImageAsset, PostImageAsset } from './FeedPostImageAsset'
import { FeedPostVideoAsset, PostVideoAsset } from './FeedPostVideoAsset'
import { PaginatedPosts } from './PaginatedPosts'
import { PostComments } from './PostComments'
import { Profile } from './Profile'
import { ProfilePosts } from './ProfilePosts'
import { RootStore } from './RootStore'
import { UserStore } from './UserStore'

export type PostParentFeedStore = PaginatedPosts | ProfilePosts

export type FeedPostAsset = FeedPostVideoAsset | FeedPostImageAsset

// TODO: naming, location?
export type PostProps = Omit<PostData, 'profile'> & {
  profile?: FeedProfile
  positionIndex?: number
  positionOrdinal?: number
}

export class Post {
  // Stores / non-api fields
  // --
  // TODO: create a FeedPost store so its less generic than just Post and make this required
  root?: RootStore = undefined
  parentFeed?: PostParentFeedStore = undefined
  profile?: Profile
  comments: PostComments
  assets: FeedPostAsset[]

  // Fields
  // --
  commentCount: PostData['commentCount']
  createdAt: PostData['createdAt']
  description?: PostData['description']
  isLiked: PostData['isLiked']
  isOwner: PostData['isOwner']
  isSubscribed: PostData['isSubscribed']
  isVisible: PostData['isVisible']
  likeCount: PostData['likeCount']
  permalink: PostData['permalink']
  postId: PostData['postId']
  price?: PostData['price']
  profileId: PostData['profileId']
  publishedAt?: PostData['publishedAt']
  status: PostData['status']
  type: PostData['type']
  // Row position index in virtualized feed
  feedPositionIndex?: number = undefined
  // Is defined if different to the virtual feed position index. The ordinal is the true position
  // in list of Posts from API (as virtual feed may have non post elements at positions).
  feedPositionOrdinal?: number = undefined
  // feed intersection observer state for post.. true unless set otherwise for simpler
  // compatibility with non-feed PostCard implementations.
  isIntersecting = true
  // Carousel mouseover for controls show/hide
  isMousingOver = false

  // TODO: review usage of the model for create post as it requires a very incomplete partial of PostData on init
  constructor(data: Partial<PostProps> = {}, root?: RootStore, parentFeed?: PostParentFeedStore) {
    if (root) this.root = root
    if (parentFeed) this.parentFeed = parentFeed
    this.hydrate(data)
    makeAutoObservable(this)
  }

  static coverAsset(post?: PostData | PostProps | null) {
    return post?.asset?.[0]?.media
  }

  hydrate({ asset = [], comments, profile, ...data }: Partial<PostProps> = {}) {
    // https://stackoverflow.com/questions/67266810/error-mobx-cannot-apply-observable-to-storeuser-field-not-found
    // https://github.com/vercel/next.js/issues/35721

    // Hydrating a Post from the Profile/ProfilePosts causes an infinite hydration loop.
    // We don't need to rehydrate the Post.profile when the Profile that contains the Post is up to date...
    this.profile =
      this.parentFeed instanceof ProfilePosts
        ? this.parentFeed.parent // The Post's Profile
        : profile
          ? this.root?.profiles.load(profile)
          : undefined
    this.comments = new PostComments(comments)
    this.assets =
      asset?.map((a) =>
        a.media.__typename === 'VideoAsset'
          ? new FeedPostVideoAsset(a as PostVideoAsset, this)
          : new FeedPostImageAsset(a as PostImageAsset, this)
      ) ?? []
    this.isLiked = data.isLiked ?? false
    this.isSubscribed = data.isSubscribed ?? false
    this.isOwner = data.isOwner ?? false
    this.isVisible = data.isVisible ?? false
    this.likeCount = data.likeCount ?? 0
    this.commentCount = data.commentCount ?? 0
    this.price = data.price ?? 0
    this.type = data.type ?? PostType.EXCLUSIVE
    this.status = data.status ?? PostStatus.DRAFT
    this.createdAt = data.createdAt ?? ''
    this.permalink = data.permalink ?? ''
    this.postId = data.postId ?? ''
    this.profileId = data.profileId ?? ''
    this.description = data.description ?? ''
    this.publishedAt = data.publishedAt ?? ''
    this.feedPositionIndex = data.positionIndex ?? undefined
    this.feedPositionOrdinal = data.positionOrdinal ?? undefined
    return this
  }

  setType(newType: PostType) {
    this.type = newType
  }

  setPrice(newPrice: number | string) {
    this.price = Number(newPrice)
  }

  setDescription(newDesc: string) {
    this.description = newDesc
  }

  setFeedPosition(index: number, ordinal?: number) {
    this.feedPositionIndex = index
    if (ordinal !== undefined) {
      this.feedPositionOrdinal = ordinal
    }
  }

  setIsIntersecting(isIntersecting: boolean) {
    this.isIntersecting = isIntersecting

    if (isIntersecting) {
      this.root?.analytics.debouncedTrack(NSFW_EVENT.FEED_VIEW, {
        feedType: this.feedType,
        page: this.feedPage,
        ordinal: this.feedOrdinal,
        postId: this.postId
      })
      if (!this.root) {
        console.error('Attempted tracking event from Post setIsIntecting with root instance')
      }
    }
  }

  setViewingAsset(index: number) {
    this.assets.forEach((asset) => asset.setViewing(false))
    this.assets[index].setViewing(true)
  }

  setIsMousingOver(bool: boolean) {
    this.isMousingOver = bool
  }

  get feedPage() {
    return this.feedOrdinal !== undefined
      ? Math.ceil(this.feedOrdinal / FEED_PAGE_LIMIT)
      : undefined
  }

  get feedOrdinal() {
    if (!this.feedPositionOrdinal) {
      return this.feedPositionIndex ? this.feedPositionIndex + 1 : undefined
    }
    return this.feedPositionOrdinal
  }

  get feedIndex() {
    return this.feedPositionIndex
  }

  get feedType() {
    // Cannot determine feed position of a Post that is not navigated through a feed i.e post/[postId] directly.
    if (!this.parentFeed) return

    return this.parentFeed instanceof PaginatedPosts
      ? this.parentFeed.type
      : // There are no feedTypes for profile feeds currently
        'PROFILE'
  }

  get coverAsset() {
    return this.assets?.[0]?.media
  }

  get coverAssetUrl() {
    return this.coverAsset?.url
  }

  get coverAssetHeight() {
    return this.coverAsset?.originalHeight
  }

  get coverAssetWidth() {
    return this.coverAsset?.originalWidth
  }

  estimatePostCardHeight(windowWidth: number) {
    const resolveFeedItemWidth = (width: number) => {
      switch (true) {
        case width >= 1024:
          return 511
        case width >= 544:
          return 544
        default:
          return width
      }
    }

    const height = this.coverAssetHeight
    const width = this.coverAssetWidth
    if (!height || !width) return 400

    const feedItemWidth = resolveFeedItemWidth(windowWidth)
    const aspectRatioHeight = (height / width) * feedItemWidth
    return (
      aspectRatioHeight +
      // topbar
      71 +
      // caption
      52 +
      // actions
      55 +
      // margin bottom
      24
    )
  }

  toggleLiked() {
    this.likeCount = this.isLiked ? this.likeCount - 1 : this.likeCount + 1
    this.isLiked = !this.isLiked
  }

  async deletePost() {
    const { data, error } = await gqlRequest<{ isDeleted: boolean }>(DELETE_POST_MUTATION, {
      postId: this.postId
    })

    if (error) {
      toast({
        message: error.message,
        type: 'error'
      })
      return
    }
    if (data) {
      toast({
        message: 'Post deleted',
        type: 'success'
      })
      this.root?.ui.closeAppErrorModal()
      setTimeout(() => {
        // Because of post detail => profile feed scroll restoration, delete from state to ensure
        // a feed refetch.
        this.root?.profiles.deleteById(this.profileId)
        // if post delete from post modal
        if (this.root?.ui.showPostModal) {
          this.root.ui.closePostModal()
        }
        // Profile should always exist, but just incase.
        const redirect = this.profile
          ? `${AppRoutes.PROFILE}/${this.profile?.username}`
          : DEFAULT_HOME_ROUTE
        this.root?.feed.resetFeeds()
        Router.push(redirect)
      }, 500)
    }
  }

  async reloadPost() {
    const { data } = await gqlRequest<{ viewPost: PostProps }>(VIEW_POST_WITH_COMMENTS, {
      postId: this.postId,
      // pagination options for comments
      options: {
        limit: 10,
        sortBy: SortBy.CREATED_AT,
        sortDirection: SortDirection.DESC
      }
    })

    if (data?.viewPost) {
      this.hydrate(data.viewPost)
    }
  }

  async toggleLikePost() {
    this.toggleLiked()

    const { data, error } = await gqlRequest<ToggleLikePostResponse>(TOGGLE_LIKE_POST, {
      input: {
        postId: this.postId,
        liked: this.isLiked
      }
    })

    if (!data || error) {
      this.toggleLiked()
      throw false
    }

    this.root?.analytics.track(NSFW_EVENT.FEED_POST_LIKE, {
      feedType: this.feedType,
      page: this.feedPage,
      postId: this.postId
    })

    return this.isLiked
  }

  async addComment(comment: string, user: UserStore) {
    const previousComments = this.comments.items
    const tempCommentId = uniqueId('TEMP_ID_')

    // ? we only select these fields for comment author (Profile), should we have an Author type?
    // ? this is somewhat the problem with using the api types, we may not be selecting all fields in client queries
    const author = {
      displayName: user?.account?.displayName || '',
      username: user?.account?.username || '',
      avatar: {
        url: user?.account?.avatar?.url || ''
      }
    } as ProfileData

    this.commentCount++
    this.comments.items = [
      {
        text: comment,
        commentId: tempCommentId,
        createdAt: new Date().toISOString(),
        createdBy: user.account?.userId as string,
        postId: this.postId,
        // TODO: Fix types
        // @ts-ignore
        post: this,
        author: author
      },
      ...previousComments
    ]

    const { data, error } = await gqlRequest<{ addComment: Comment }>(ADD_COMMENT, {
      input: {
        comment,
        postId: this.postId
      }
    })

    if (!data || error) {
      this.comments.items = previousComments
      this.commentCount--
      throw error
    }

    this.comments.items = (this.comments?.items).map((comment) =>
      comment.commentId === tempCommentId ? data.addComment : comment
    )
    this.root?.analytics.track(NSFW_EVENT.FEED_POST_COMMENT_ADD, {
      feedType: this.feedType,
      page: this.feedPage,
      postId: this.postId,
      commentLength: comment.length
    })
  }

  // Comments are "lazy" loaded, i.e fetched when needed. Method only runs if not hasNextPage & isEmpty.
  async fetchInitialComments() {
    if (this.comments.hasNextPage && this.comments.isEmpty) {
      await this.fetchComments(10, true)
    }
  }

  async fetchComments(limit = 10, refetch = false) {
    console.log('loading comments', refetch)
    this.comments.loading = true

    const { data, error } = await gqlRequest<{ viewPost: Post }>(LOAD_MORE_COMMENTS, {
      postId: this.postId,
      options: {
        limit,
        lastRecord: refetch ? '0' : this.comments.cursor,
        sortBy: SortBy.CREATED_AT,
        sortDirection: SortDirection.DESC
      }
    })

    if (!data || error) {
      this.comments.loading = false
      throw false
    }

    // Fetching more
    if (this.comments.items.length) {
      this.root?.analytics.track(NSFW_EVENT.FEED_POST_COMMENT_SHOW_MORE, {
        feedType: this.feedType,
        page: this.feedPage,
        postId: this.postId
      })
    }

    this.comments.items = refetch
      ? data.viewPost.comments.items
      : [...this.comments.items, ...data.viewPost.comments.items]
    this.comments.cursor = data.viewPost.comments.cursor
    this.comments.hasNextPage = data.viewPost.comments.hasNextPage
    this.comments.loading = false
    return this.comments.items
  }

  async reportProfile() {
    console.log('Todo: connect this to report profile API')
  }
}
