import { gql } from '@urql/core'
import { PAYMENTS_FRAGMENT } from '../fragments/payments.fragment'

export const CREATE_SALE_MUTATION = gql`
  mutation CreateSaleMutation($input: CreateSaleInput!) {
    createSale(input: $input) {
      orderId
      chainId
      status
      signer
      recipient
      currencyCode
      total
      createdAt
      token {
        symbol
        contract
      }
      customer {
        accountType
        profileId
      }
    }
  }
`

export const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrderMutation($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      orderId
      chainId
      status
      signer
      recipient
      currencyCode
      total
      createdAt
      token {
        symbol
        contract
      }
      customer {
        accountType
        profileId
      }
    }
  }
`

export const RECORD_PAYMENT_MUTATION = gql`
  mutation RecordPaymentMutation($input: RecordPaymentInput!) {
    recordPayment(input: $input) {
      status
      paymentId
      signer
      recipient
      transactionHash
      token {
        symbol
        contract
      }
    }
  }
`

export const CONFIRM_PAYMENT_MUTATION = gql`
  mutation ConfirmPaymentMutation($input: ConfirmPaymentInput!) {
    confirmPayment(input: $input) {
      status
      paymentId
      signer
      recipient
      transactionHash
      token {
        symbol
        contract
      }
    }
  }
`

export const CONFIRM_WITHDRAWAL_MUTATION = gql`
  # --- fragments
  ${PAYMENTS_FRAGMENT}

  mutation ConfirmWithdrawalMutation($input: ConfirmWithdrawalInput!) {
    confirmWithdrawal(input: $input) {
      status
      signer
      recipient
      token {
        symbol
        contract
      }
      # order.payments
      ...PaymentsParts
    }
  }
`
