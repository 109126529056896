import { gql } from 'urql'

export const CREATE_PLAN_MUTATION = gql`
  mutation CreatePlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      planId
      title
      description
      interval
      duration
      price
      currency {
        currencyCode
      }
    }
  }
`

export const UPDATE_PLAN_MUTATION = gql`
  mutation Update($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      planId
      title
      description
      interval
      duration
      price
      currency {
        currencyCode
      }
    }
  }
`

export const ARCHIVE_PLAN_MUTATION = gql`
  mutation ArchivePlan($planId: String!) {
    archivePlan(planId: $planId)
  }
`
