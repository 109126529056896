import { GqlApiError } from 'lib/gql/GqlApiError'
import { makeAutoObservable } from 'mobx'
import { Profile } from './Profile'

// TODO: implements DataStore<Profile>
export class SelectedProfile {
  username: string = ''

  isFetching = false
  private _profile?: Profile = undefined
  private _error?: GqlApiError = undefined

  constructor(data: Partial<SelectedProfile>) {
    this.profile = data.profile
    this.isFetching = Boolean(data.isFetching)
    this.username = data.username ?? ''
    makeAutoObservable(this)
  }

  get profile() {
    return this._profile
  }

  set profile(profile: Profile | undefined) {
    this._profile = profile
    if (profile) this.isFetching = false
  }

  get error() {
    return this._error
  }

  set error(error: GqlApiError | undefined) {
    this._error = error
    if (error) this.isFetching = false
  }

  resetFeed() {
    this.profile?.posts.resetFeed()
  }
}
