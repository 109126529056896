import { gql } from 'urql'

export const CORE_ORDER_FIELDS = gql`
  fragment OrderParts on Order {
    __typename
    orderId
    price
    chainId
    recipient
    status
    createdAt
    token {
      symbol
    }
    currencyCode
    total
    modifers {
      ... on PlatformFee {
        __typename
        unit
        key
        label
        value
      }
    }
  }
`
