import { gql } from 'urql'

export const QUERY_TAGS = gql`
   query tags($input: TagFilter!, $options: PaginationOptions) {
     tags(input: $input, options: $options) {
       items {
         ... on Tag {
          slug
          tagId
          key
          value
         }
       }
       cursor
       hasNextPage
     }
   }
 `
