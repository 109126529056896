import { makeAutoObservable } from 'mobx'
import { Comment, PaginatedResult } from 'graphql/types'
import { CommentPaginatedResult } from 'interfaces'

export interface PostComments extends CommentPaginatedResult {}
export class PostComments {
  items: Comment[] = []
  loading = false
  cursor?: string | null = undefined
  hasNextPage = true
  // The types are partial and guarded because of the PaginatedResult type
  constructor(data?: PaginatedResult) {
    if (data?.cursor) this.cursor = data?.cursor
    if (data?.hasNextPage) this.hasNextPage = data.hasNextPage
    this.items = (data?.items as Comment[]) ?? []
    makeAutoObservable(this)
  }

  get isEmpty() {
    return !this.items.length
  }
}
