import { gql } from 'graphql-request'

export const UPDATE_TAGS = gql`
  mutation UpdateTags($tagIds: [String!]!){
    updateTags(tagIds: $tagIds)
      {
        tags
          {
            tagId
          }
      }
  }
`
