import { CORE_PROFILE_FIELDS } from 'graphql/fragments'
import { gql } from 'urql'

export const QUERY_SUGGESTIONS = gql`
  ${CORE_PROFILE_FIELDS}
  query QuerySuggestions {
    suggestions {
      ...ProfileParts
    }
  }
`
