import { Network, TokenSymbol } from '@nsfw-app/crypto'
import { makeAutoObservable } from 'mobx'
import { RootStore } from '../RootStore'
import {
  Abi,
  Address,
  Chain,
  PublicClient,
  WalletClient,
  createWalletClient,
  custom,
  getContract
} from 'viem'
import { createPublicClient, http } from 'viem'

type RegistryKey = `${Network}-${TokenSymbol}`

interface ContractArgs {
  address: Address
  abi: Abi
  client: PublicClient
  wallet: WalletClient
  contract: any
}

export class ContractsRegistry {
  root: RootStore

  // Store initialised contracts with signer
  registry = new Map<RegistryKey, ContractArgs>()

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
    console.log('Initializing ContractsRegistry...')
  }

  /**
   * Returns an ethers [Contract] instance for the given token and chain
   * attached to the current signer
   */
  async instance(token: TokenSymbol | string, chain: Chain): Promise<ContractArgs> {
    if (!chain) throw new Error('No Chain provided')

    const key = `${chain.id}-${token}` as RegistryKey

    if (!this.registry.has(key)) {
      return this.registerContract({ key, token, chain })
    }

    const contract = this.registry.get(key)
    if (!contract) throw new Error(`No contract found for [${key}]`)

    return contract
  }

  private registerContract = async ({
    token,
    key,
    chain
  }: {
    token: TokenSymbol | string
    key: RegistryKey
    chain: Chain
  }) => {
    const metadata = this.root.network.acceptedTokens.find((o) => o.symbol === token)
    if (!metadata) {
      throw new Error(`No metadata found for token ${token}`)
    }

    // Initalise contract and save it to the registry
    const provider = await this.root.wallets.connected?.getEthereumProvider()
    if (!provider) throw new Error('No provider found')

    /**
     * TODO: https://viem.sh/docs/typescript#type-inference
     * TODO: https://viem.sh/docs/typescript#type-inference
     * TODO: https://viem.sh/docs/typescript#type-inference
     */
    const args = {
      address: metadata.contract as Address,
      abi: metadata.abi as Abi,
      public: createPublicClient({
        chain,
        transport: http()
      }),
      wallet: createWalletClient({
        chain,
        transport: custom(provider)
      })
    }

    const readContract = getContract({
      address: args.address,
      abi: args.abi,
      client: args.public
    })
    const writeContract = getContract({ address: args.address, abi: args.abi, client: args.wallet })

    // this.registry.set(key, {
    //   public: readContract,
    //   wallet: writeContract
    // })

    return {
      address: args.address,
      abi: args.abi,
      client: args.public,
      wallet: args.wallet,
      contract: readContract
    }
  }
}
