import { gql } from '@urql/core'
import { CORE_COMMENT_FIELDS, CORE_POST_FIELDS } from 'graphql/fragments'

export const VIEW_POST_WITH_COMMENTS = gql`
  ${CORE_POST_FIELDS}
  ${CORE_COMMENT_FIELDS}
  query ViewPostWithComments($postId: String!, $options: PaginationOptions) {
    viewPost(postId: $postId) {
      ...PostParts
      comments(options: $options) {
        hasNextPage
        cursor
        items {
          ... on Comment {
            ...CommentParts
          }
        }
      }
    }
  }
`
