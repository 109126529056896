import { Network, TokenOptions, TokenSymbol } from '@nsfw-app/crypto'
import { ErrorCodes } from 'config/errorCodes'
import { listEnabledTokens } from 'config/tokens'
import { WITHDRAWAL_ADDRESS } from 'config/wallets'
import { Contract, InfuraProvider, InterfaceAbi, formatUnits } from 'ethers'
import { GraphQLError } from 'graphql'
import { CREATE_WITHDRAWAL_MUTATION } from 'graphql/mutations/createWithdrawal'
import { CONFIRM_WITHDRAWAL_MUTATION } from 'graphql/mutations/orders'
import { QUERY_EXCHANGE_RATE } from 'graphql/mutations/query.exchangeRate'
import { VIEW_ORDER_QUERY } from 'graphql/queries/viewOrder'
import {
  TokenSymbol as ApiTokenSymbol,
  ConfirmWithdrawalInput,
  CreateWithdrawalInput,
  ExchangeRate,
  ExchangeRateInput,
  MutationConfirmWithdrawalArgs,
  MutationCreateWithdrawalArgs,
  Order,
  QueryExchangeRateArgs,
  QueryViewOrderArgs
} from 'graphql/types'
import { urqlClient } from 'lib/urql/client'
import { isGraphQLError } from 'lib/urql/errors'
import { mutation } from 'lib/urql/mutation'
import { query } from 'lib/urql/query'
import { makeAutoObservable } from 'mobx'
import { CombinedError } from 'urql'
import { RootStore } from './RootStore'
import { request } from 'lib/gql/client'
import { TOKEN_COOKIE } from 'config'
import Cookies from 'js-cookie'

export enum WithdrawalStatus {
  CONFIRMED = 'Confirmed', // When payment.status == confirmed
  STARTED = 'Submitting withdrawal', // When order.status == Started && payment.transactionHash == undefined
  PROCESSING = 'Processing withdrawal', //When payment.status == Submited && payment.transactionHash !== undefined
  NOT_STARTED = 'Not started',
  EXPIRED = 'Expired, try again'
}

const INFURA_API_KEY = process.env.NEXT_PUBLIC_INFURA_API_KEY

export class WithdrawalStore {
  root: RootStore

  withdrawalOrder?: Order = undefined

  isProcessing = false

  status: WithdrawalStatus = WithdrawalStatus.NOT_STARTED

  errors?: GraphQLError = undefined

  estimatedTokens = ''

  network: Network =
    process.env.NEXT_PUBLIC_NSFW_ENV === 'beta' ? Network.POLYGON : Network.POLYGON_AMOY

  contract?: Contract = undefined

  availableBalance = 0n

  usdcMetadata?: TokenOptions = undefined

  provider?: InfuraProvider = undefined

  isInitalised = false

  constructor(root: RootStore) {
    this.root = root

    makeAutoObservable(this)
  }

  async initalise() {
    console.log('initalise()', this.root.network.withdrawalNetwork.name)
    this.usdcMetadata = listEnabledTokens(this.root.network.withdrawalNetwork).find(
      (o) => o.symbol === TokenSymbol.USDC
    )
    if (!this.usdcMetadata) {
      throw new Error(`Unable to find USDC token metadata for [chainId:${this.network}]`)
    }

    this.provider = new InfuraProvider(this.network, INFURA_API_KEY)
    this.contract = new Contract(
      this.usdcMetadata.contract,
      this.usdcMetadata.abi as InterfaceAbi,
      this.provider
    )

    this.isInitalised = true
  }

  getAvailableBalance = async (): Promise<number> => {
    console.log('getAvailableBalance()', WITHDRAWAL_ADDRESS)
    const balance = await this.contract?.balanceOf(WITHDRAWAL_ADDRESS)

    console.log('balance()', balance)
    const decimalNumber = this.usdcMetadata?.decimals
    const currentValue = formatUnits(balance, decimalNumber)
    return Number.parseInt(currentValue)
  }

  setWithdrawalOrder = (order: Order) => {
    this.withdrawalOrder = order
  }

  setStatus = (status: WithdrawalStatus) => {
    this.status = status
  }

  get estimatedTokensDecial() {
    return Number.parseFloat(this.estimatedTokens).toFixed(4)
  }

  async waitForConfirmation(txnHash: string) {
    const resp = await this.provider?.getTransaction(txnHash)
    await resp?.wait(4)

    await this.confirmWithdrawal({
      orderId: this.withdrawalOrder!.orderId,
      transactionHash: txnHash
    })
  }

  async confirmWithdrawal(input: ConfirmWithdrawalInput) {
    const { error, data } = await mutation<
      { confirmWithdrawal: Order },
      MutationConfirmWithdrawalArgs
    >(CONFIRM_WITHDRAWAL_MUTATION, { input })

    if (error) {
      console.log(error)
      console.log('error confirming order')
    }

    if (data) {
      this.setStatus(WithdrawalStatus.CONFIRMED)
      this.setWithdrawalOrder(data.confirmWithdrawal)
    }
  }

  async createWithdrawal(input: CreateWithdrawalInput) {
    console.log('createWithdrawal()')
    this.isProcessing = true
    this.setStatus(WithdrawalStatus.STARTED)

    console.log(input)

    const resp = await request(
      CREATE_WITHDRAWAL_MUTATION,
      { input },
      { token: Cookies.get(TOKEN_COOKIE) }
    )

    console.log('----- createWithdrawal() -----')
    console.log(resp)

    // if (error) {
    //   switch (true) {
    //     case isGraphQLError(error as CombinedError, ErrorCodes.WITHDRAWAL_MIN_AMOUNT):
    //       this.root.ui.setApplicationError(ErrorCodes.WITHDRAWAL_MIN_AMOUNT)
    //       break
    //     case isGraphQLError(error as CombinedError, ErrorCodes.PENDING_WITHDRAWAL):
    //       this.root.ui.setApplicationError(ErrorCodes.PENDING_WITHDRAWAL)
    //       break
    //     default:
    //       this.root.ui.setApplicationError(ErrorCodes.DEFAULT)
    //   }
    //   this.isProcessing = false
    //   this.setStatus(WithdrawalStatus.NOT_STARTED)
    //   return
    // }

    if (resp.createWithdrawal) {
      this.withdrawalOrder = resp.createWithdrawal
    }
  }

  async viewOrder(orderId: string) {
    const { data, error } = await urqlClient
      .query<{ viewOrder: Order }, QueryViewOrderArgs>(VIEW_ORDER_QUERY, {
        orderId
      })
      .toPromise()

    if (error) {
      return
    }

    if (data) {
      return data.viewOrder
    }
  }

  async estimateTokens(from: string, to: ApiTokenSymbol, amount: number) {
    if (!amount) {
      this.setEstimatedTokens('0')
      return
    }

    return this._estimateTokens({
      from,
      to,
      amount: amount.toString(),
      chainId: this.root.network.withdrawalNetworkId
    })
  }

  private async _estimateTokens(input: ExchangeRateInput) {
    const { error, data } = await query<{ exchangeRate: ExchangeRate }, QueryExchangeRateArgs>(
      QUERY_EXCHANGE_RATE,
      {
        input
      }
    )

    if (error) {
      console.log(error)
      console.log('error estimating tokens')
    }

    if (data) {
      this.setEstimatedTokens(data.exchangeRate.to.amount)
    }
  }

  setEstimatedTokens(tokens: string) {
    this.estimatedTokens = tokens
  }
}
