import { gql } from 'urql'
import { CORE_PROFILE_FIELDS } from './profile.fragment'
import {
  CORE_POST_ASSET_FIELDS,
  POST_ASSET_FORMATS_FIELDS,
} from './postAssets.fragment'

export const CORE_POST_FIELDS = gql`
  ${CORE_PROFILE_FIELDS}
  ${CORE_POST_ASSET_FIELDS}

  fragment PostParts on Post {
    type
    postId
    isVisible
    description
    likeCount
    profileId
    isLiked
    publishedAt
    type
    isSubscribed
    isOwner
    price
    commentCount
    status
    profile {
      ...ProfileParts
    }
    asset {
      ...PostAssetParts
    }
  }
`

export const CORE_POST_WITH_ASSET_FORMATS_FIELDS = gql`
  ${CORE_PROFILE_FIELDS}
  ${POST_ASSET_FORMATS_FIELDS}

  fragment PostWithAssetFormats on Post {
    __typename
    type
    postId
    isVisible
    description
    likeCount
    profileId
    isLiked
    publishedAt
    type
    isSubscribed
    isOwner
    price
    commentCount
    status
    profile {
      ...ProfileParts
    }
    asset {
      ...PostAssetFormatsParts
    }
  }
`
