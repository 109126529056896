import { VIEW_PROFILE_POSTS, ViewProfilePostsResponse } from 'graphql/queries/viewProfilePosts'
import { SortBy, SortDirection } from 'graphql/types'
import { PostPaginatedResult } from 'interfaces'
import { urqlClient } from 'lib/urql/client'
import { makeAutoObservable } from 'mobx'
import { Post } from './Post'
import { Profile } from './Profile'
import { RootStore } from './RootStore'

// @ts-ignore
export interface IProfilePosts extends PostPaginatedResult {
  scrollIndex: number
  items: Post[] // Coerce "Pagable" to Post type
}

export class ProfilePosts implements IProfilePosts {
  // Quick field to differenciate type PostParentFeedStore (see guards.ts isProfileFeedStore).
  _feedType: 'profile-feed' | 'activity-feed' = 'profile-feed'
  root: RootStore
  items: Post[] = []
  loading = false
  error?: string
  scrollIndex = 0
  hasNextPage = false
  cursor?: string = undefined
  parent: Profile

  // Profile Feed UI State
  private _isBannerCollapsed = false

  constructor(data: Partial<ProfilePosts>, root: RootStore, parent: Profile) {
    this.root = root
    this.parent = parent
    this.hydrate(data)
    makeAutoObservable(this)
  }

  hydrate({ items, scrollIndex, loading, error, hasNextPage, cursor }: Partial<ProfilePosts> = {}) {
    if (loading !== undefined) this.loading = loading
    if (hasNextPage !== undefined) this.hasNextPage = hasNextPage
    if (error) this.error = error
    if (scrollIndex) this.scrollIndex = scrollIndex
    if (cursor) this.cursor = cursor
    if (items && items.length > 0) {
      this.scrollIndex = -1 // see useRestoreScroll.tsx
      // TODO: Fix types
      // @ts-ignore
      this.items = items.map((post) => new Post(post, this.root, this))
    }
    return this
  }

  // alias to match PaginatedPosts store
  get posts() {
    return this.items
  }

  get isBannerCollapsed() {
    return (
      // load collapsed on scroll restoration by checking scrollIndex
      this.scrollIndex > 0 || this._isBannerCollapsed
    )
  }

  set isBannerCollapsed(val: boolean) {
    this._isBannerCollapsed = val
  }

  setScrollIndex(index: number) {
    this.scrollIndex = index
  }

  // returning a new array instance causes jolt in virtual feed so mutating instead
  appendItems(items: Profile['posts']['items']) {
    this.items.push(...items)
    return this.items
  }

  /**
   * Reset feed; reload first page.
   */
  async resetFeed() {
    return this.fetchFeed(20, '0')
  }

  // flowResult type infer doesnt work with async generators, so explicity typing it here.
  async fetchFeed(limit = 20, cursor = this.cursor) {
    const refetch = cursor === '0' && this.items.length
    if (!refetch && (this.loading || !this.hasNextPage)) {
      return this.items
    }

    this.loading = true

    const { data } = await urqlClient
      .query<ViewProfilePostsResponse>(VIEW_PROFILE_POSTS, {
        profileId: this.parent.profileId,
        postFilter: this.parent.postFilter,
        postOptions: {
          limit,
          lastRecord: cursor,
          sortBy: SortBy.CREATED_AT,
          sortDirection: SortDirection.DESC
        }
      })
      .toPromise()

    // TODO: Fix types
    // @ts-ignore
    const newPosts = (data?.viewProfile?.posts.items || []).map((p) => new Post(p, this.root, this))
    if (refetch) {
      this.items = newPosts
      // See useRestoreScroll which watches for this value and scrolls to position 0
      this.scrollIndex = -1
    } else {
      this.appendItems(newPosts)
    }
    this.hasNextPage = Boolean(data?.viewProfile.posts.hasNextPage && newPosts.length > 0)
    this.loading = false
    this.cursor = data?.viewProfile.posts.cursor

    // set/reset error state
    // GQL error can return for individual posts, it seems they're excluded from the feed so we
    // continue to show posts.
    this.error = !data ? 'Could not retrieve posts' : undefined

    return this.items
  }
}
