import { gql } from '@urql/core'

export const QUERY_EARNINGS = gql`
  query QueryEarnings {
    earnings {
      analyticsInterval
      availableToWithdraw
      sales {
        unit
        total
        exclusivePosts {
          total
          count
        }
        subscriptions {
          total
          count
        }
        messages {
          total
          count
        }
        tips {
          total
          count
        }
      }
    }
  }
`

export const QUERY_EARNINGS_BY_MONTH = gql`
  query QueryEarningsByMonth {
    earnings {
      analyticsInterval
      availableToWithdraw
      sales {
        unit
        total
        exclusivePosts {
          total
          count
        }
        subscriptions {
          total
          count
        }
        tips {
          total
          count
        }
        messages {
          total
          count
        }
        byMonth {
          items {
            label
            exclusivePosts {
              count
              total
            }
            subscriptions {
              count
              total
            }
            tips {
              count
              total
            }
            messages {
              total
              count
            }
            total
            unit
          }
        }
      }
    }
  }
`
