import { gql } from '@urql/core'

export const IS_VERIFICATION_VALID = gql`
  query IsVerificationValid($token: String!) {
    isVerificationValid(token: $token)
  }
`

export type IsVerificationValidVars = Record<'token', string>

export interface IsVerificationValidResponse {
  isVerificationValid: boolean
}
