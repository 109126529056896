import { ErrorCodes } from 'config'
import { APIError, DEFAULT_API_ERROR_PROPS } from 'lib/api/APIError'

export interface GqlError {
  code: ErrorCodes
  message: string
}

// Convert GQL into JS Error instances with message, code & status props extracted.
// - Pick the first error from the list of GQL errors
// - Can be used to type catch errors with type guarding
// - Can define cases below for assigning status codes to API codes, and custom messages
export class GqlApiError extends APIError {
  errors: GqlError[] = []

  constructor(_errors: GqlError | GqlError[] = []) {
    console.error('GqlApiError: ', _errors)
    const [error, ...otherErrors] = ([] as GqlError[]).concat(_errors)
    if (!error) super(DEFAULT_API_ERROR_PROPS)

    const code = error?.code
    const message = error?.message

    switch (code) {
      case ErrorCodes.GENERIC_NOT_FOUND:
      case ErrorCodes.POST_NOT_FOUND:
        super({ message: message ?? 'Entity not found', status: 404, code })
        break
      // add cases as implemented.
      default:
        super(DEFAULT_API_ERROR_PROPS)
        break
    }

    this.errors = otherErrors
  }
}

// `catch(err)` argument has unknown type, form a constant type.
export const getGqlApiError = (err: unknown) =>
  err instanceof GqlApiError
    ? err
    : new GqlApiError({ ...DEFAULT_API_ERROR_PROPS, code: ErrorCodes.DEFAULT })
