import { gql } from 'urql'

export const PAYMENTS_FRAGMENT = gql`
  fragment PaymentsParts on Order {
    payments {
      ... on CryptoPayment {
        __typename
        paymentId
        status
        transactionHash
        signer
        recipient
        token {
          symbol
          contract
        }
        createdAt
        completedAt
      }
    }
  }
`
