import { gql } from 'urql'

export const VIEW_CREATOR_PLANS = gql`
  query ViewCreatorPlans($creatorId: String!) {
    viewCreatorPlans(creatorId: $creatorId) {
      planId
      description
      title
      price
      interval
      duration
    }
  }
`
