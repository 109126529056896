import { gql } from '@urql/core'
import { CORE_POST_WITH_ASSET_FORMATS_FIELDS } from 'graphql/fragments'
import { Profile } from 'models/Profile'

export const VIEW_PROFILE_POSTS = gql`
  ${CORE_POST_WITH_ASSET_FORMATS_FIELDS}
  query ViewProfilePosts(
    $profileId: String!
    $postFilter: PostFilter
    $postOptions: PaginationOptions
  ) {
    viewProfile(profileId: $profileId) {
      posts(filter: $postFilter, options: $postOptions) {
        ... on PaginatedResult {
          __typename
          cursor
          hasNextPage
          items {
            ... on Post {
              __typename
              ...PostWithAssetFormats
            }
          }
        }
      }
    }
  }
`

export interface ViewProfilePostsResponse {
  viewProfile: Pick<Profile, 'posts'>
}
