import { TokenOptions, TokenSymbol, resolveDecimalsFromSymbol } from '@nsfw-app/crypto'
import { toast } from 'components/common/Toast'
import { PaymentOptions } from 'components/modals/PaymentModal/types'
import { TOKEN_COOKIE } from 'config'
import { AcceptedCurrencies } from 'config/currencies'
import { BigNumberish, parseUnits } from 'ethers'
import { parseUnits as viemParseUnits, formatUnits as viemFormatUnits, Account } from 'viem'
import { CLAIM_REFERRAL_REWARD } from 'graphql/mutations/claimReferralReward'
import {
  CONFIRM_PAYMENT_MUTATION,
  CREATE_SALE_MUTATION,
  RECORD_PAYMENT_MUTATION,
  UPDATE_ORDER_MUTATION
} from 'graphql/mutations/orders'
import { QUERY_EXCHANGE_RATE } from 'graphql/mutations/query.exchangeRate'
import {
  ClaimReferralRewardInput,
  ConfirmPaymentInput,
  CreateSaleInput,
  CreateSaleMutationMutation,
  CryptoPayment,
  ExchangeRate,
  ExchangeRateInput,
  MutationClaimReferralRewardArgs,
  MutationConfirmPaymentArgs,
  MutationRecordPaymentArgs,
  MutationUpdateOrderArgs,
  Order,
  ProductType,
  QueryExchangeRateArgs,
  RecordPaymentInput,
  Referral,
  UpdateOrderInput
} from 'graphql/types'
import Cookies from 'js-cookie'
import { request } from 'lib/gql/client'
import { toFloatString } from 'lib/helpers'
import { NSFW_EVENT } from 'lib/tracking/types'
import { mutation } from 'lib/urql/mutation'
import { query } from 'lib/urql/query'
import { chainIdAsInt } from 'lib/web3/chains'
import { makeAutoObservable, toJS } from 'mobx'
import { Post } from './Post'
import { Profile } from './Profile'
import { RootStore } from './RootStore'

export enum TransactionCTA {
  PAY = 'Pay',
  PROCESSING = 'Processing',
  AWAITING_APPROVAL = 'Awaiting approval',
  APPROVE_TOKENS = 'Approve tokens',
  PAYMENT_CONFIRMING = 'Confirming payment',
  APPROVING_TOKENS = 'Approving tokens',
  AWAITING_ALLOWANCE_CHECK = 'Checking allowance',
  PENDING_USER_CONFIRMATION = 'Awaiting confirmation',
  CONFIRMED = 'Confirmed',
  ERROR = 'Contact support'
}

export enum TransactionStatus {
  TOKENS_APPROVED = 'Tokens approved',
  APPROVING_TOKENS = 'Approving tokens',
  AWAITING_TOKENS_APPROVAL = 'Awaiting approval',
  PROCESSING = 'Processing',
  SENDING_TOKENS = 'Sending tokens',
  SEND_TOKENS = 'Send tokens',
  AWAITING_PAYMENT = 'Awaiting payment',
  PENDING_USER_CONFIRMATION = 'Awaiting confirmation',
  CONFIRMED = 'Payment confirmed',
  ERROR = 'Payment failed',
  FLAGGED = 'Payment flagged'
}

type TxnTrackEvents =
  | NSFW_EVENT.ORDER_CHECKOUT
  | NSFW_EVENT.ORDER_SUMMARY
  | NSFW_EVENT.ORDER_COMPLETED
  | NSFW_EVENT.ORDER_CANCELED

export type CheckoutState = {
  isApprovingTokens: boolean
  hasRequiredAllowance: boolean
  isCompleted: boolean
  isProcessing: boolean

  status?: TransactionStatus
  buttonLabel?: TransactionCTA

  numberOfConfirmations: number
  transactionHash?: string
}

export interface ClaimReward {
  estimatedTokens?: string
  amountUSD?: string
  status?: string
}
export interface TransferEstimate {
  gasLimit: BigNumberish
  gasPrice: BigNumberish
}

// Profile is always required, post is optional
interface TransactionInitProps {
  profile: Profile
  paymentType: PaymentOptions
  post?: Post
}

const DEFAULT_CHECKOUT_STATE = {
  isApprovingTokens: false,
  hasRequiredAllowance: false,
  isCompleted: false,
  isProcessing: false,
  status: TransactionStatus.AWAITING_PAYMENT,
  buttonLabel: TransactionCTA.AWAITING_ALLOWANCE_CHECK,
  numberOfConfirmations: 0,
  transactionHash: undefined
}

export class TransactionStore {
  root: RootStore

  // Required number of confirmation
  REQUIRED_CONFIRMATIONS: number

  // UI state of the Transaction
  checkout: CheckoutState

  /**
   * The token we are paying with
   */
  paymentToken: TokenOptions

  // account we are paying
  profile: Profile

  // signers token allowance
  allowance?: BigNumberish

  // (optional) Post interacted with
  post?: Post

  paymentType: PaymentOptions

  // The Order from the backend
  order?: Order = undefined

  /**
   * Estimated tokens user has to pay
   * => [symbol, bigint, decimals]
   */
  estimatedTokens?: bigint = undefined

  /**
   * Users paymentToken balance
   * => [symbol, bigint, decimals]
   */
  tokenBalance?: [string, bigint, number] = undefined

  // The CryptoPayment from the backend
  payment?: CryptoPayment

  // (optional) If signer requires approval
  approvalTransactionHash?: string

  transferEstimate?: TransferEstimate = undefined

  // Rewards
  rewardReferral?: Referral = undefined

  constructor(root: RootStore) {
    this.root = root

    // defaults
    this.paymentToken = this.root.network.defaultAcceptedToken
    this.tokenBalance = undefined
    this.estimatedTokens = undefined
    this.checkout = DEFAULT_CHECKOUT_STATE
    this.REQUIRED_CONFIRMATIONS = 3

    makeAutoObservable(this)

    // --- subscriptions
    // If user changes their wallet
    // reaction(
    //   () => this.root.injectedWallet.accounts,
    //   (accounts) => {
    //     if (accounts.length && this.token?.symbol) {
    //       this.calculateWalletBalance(this.token.symbol)
    //       if (this.order?.orderId) {
    //         // Cannot change wallet just before making payment
    //         // TODO: better to take them back to the start
    //         this.root.ui.togglePaymentModal(false)
    //         this.reset()
    //         toast({
    //           message: 'Cannot change wallet after starting order, please start again',
    //           type: 'error'
    //         })
    //       }
    //     }
    //   }
    // )
  }

  get tokenBalanceFormatted() {
    if (!this.tokenBalance) return 0n

    const [, balance, decimals] = this.tokenBalance
    return viemFormatUnits(balance, decimals)
  }

  get formattedEstimatedTokens() {
    return this.estimatedTokens
      ? viemFormatUnits(this.estimatedTokens, this.paymentToken.decimals)
      : 0n
  }

  get formattedEstimatedTokensWithSymbol() {
    console.log('paymentToken', toJS(this.paymentToken))
    return this.paymentToken.symbol
      ? `${this.formattedEstimatedTokens} ${this.paymentToken.symbol}`
      : this.formattedEstimatedTokens
  }

  // Formats estimates tokens to big number
  // get formattedTokens() {
  //   if (!this.estimatedTokens) throw new Error('No estimated tokens found')

  //   const symbol = this.order?.token.symbol
  //   const tokensPayable = this.estimatedTokens
  //   const chainId = this.order?.chainId

  //   if (!symbol) throw new Error('Symbol not found')
  //   if (!chainId) throw new Error('No chainId found')

  //   const units = viemParseUnits(tokensPayable.toString(), this.paymentToken.decimals)

  //   console.log('formattedTokens()')
  //   console.log('formattedTokens()')
  //   console.log('formattedTokens()')
  //   console.log(units)

  //   return units
  // }

  get productType() {
    switch (this.paymentType) {
      case PaymentOptions.TIP_POST:
        return ProductType.TIP
      case PaymentOptions.TIP_PROFILE:
        return ProductType.DONATION
      case PaymentOptions.SUBSCRIBE_PROFILE:
        return ProductType.SUBSCRIPTION
      case PaymentOptions.EXCLUSIVE_POST:
        return ProductType.EXCLUSIVE_POST
      case PaymentOptions.MESSAGE_PACK:
        return ProductType.MESSAGE_PACK
      default:
        return ProductType.TIP
    }
  }

  // Check if amount to be paid is smaller than the wallet balance amount
  get hasRequiredBalance() {
    console.log('hasRequiredBalance()')
    const walletBalance = this.tokenBalance ? this.tokenBalance[1] : 0n
    const estimatedTokens = 0n

    console.log(walletBalance)
    console.log(this.estimatedTokens)
    console.log('hasRequiredBalance()', walletBalance > 0 && walletBalance >= estimatedTokens)

    return walletBalance > 0 && walletBalance >= estimatedTokens
  }

  get checkoutConfirmationsLabel() {
    const currentConfirmationCount =
      this.checkout.numberOfConfirmations > this.REQUIRED_CONFIRMATIONS
        ? this.REQUIRED_CONFIRMATIONS
        : this.checkout.numberOfConfirmations

    return `${currentConfirmationCount} / ${this.REQUIRED_CONFIRMATIONS}`
  }

  trackEvent(event: TxnTrackEvents) {
    this.root.analytics.track(event, {
      feedType: this.post?.feedType,
      page: this.post?.feedPage,
      postId: this.post?.postId,
      postType: this.post?.type,
      profileId: this.profile.profileId,
      paymentToken: this.order?.token.symbol,
      walletType: this.root.wallets.connectedWallet?.walletClientType ?? 'unknown',
      paymentType: this.paymentType,
      paymentValue: this.order?.total
    })
  }

  /**
   * We get the estimated tokens from the backend eg. 15.50
   * @param amount
   */
  setEstimatedTokens = (amount: string) => {
    this.estimatedTokens = viemParseUnits(amount, this.paymentToken.decimals)
  }

  setNumberOfConfirmations = () => {
    if (this.checkout.numberOfConfirmations < this.REQUIRED_CONFIRMATIONS) {
      this.setCheckoutState({
        numberOfConfirmations: this.checkout.numberOfConfirmations + 1
      })
    }
  }

  setClaimReferral = (ref?: Referral) => {
    if (ref) {
      this.rewardReferral = ref
    } else this.rewardReferral = undefined
  }

  async estimateTokens(from: string, to: TokenSymbol, amount: string) {
    console.log('estimateTokens()', from, to, amount)

    const chainId = this.root.network.walletNetwork.id

    if (!(amount && chainId)) {
      this.setEstimatedTokens('0')
      return
    }

    return this._estimateTokens({
      from,
      // @ts-ignore
      to,
      chainId,
      amount: toFloatString(amount)
    })
  }

  async fetchTokenBalance(tokenSymbol: TokenSymbol) {
    const tokens = await this.root.contractActions.getTokenBalance(tokenSymbol)
    const decimals = resolveDecimalsFromSymbol(this.root.network.walletNetwork.id, tokenSymbol)

    console.log('fetchTokenBalance', tokens)
    this.tokenBalance = [tokenSymbol, tokens as bigint, decimals]
  }

  /**
   * ----- MUTATIONS -----
   */

  async checkAllowance(tokenSymbol: TokenSymbol) {
    console.log('checkAllowance', tokenSymbol)

    if (!this.order) {
      throw new Error('Cannot check allowance before order is set')
    }

    this.allowance = await this.root.contractActions.allowance(
      tokenSymbol,
      // @ts-ignore
      this.order.recipient,
      this.order.signer
    )
  }

  async createSale(input: CreateSaleInput) {
    const resp = await request<CreateSaleMutationMutation>(
      CREATE_SALE_MUTATION,
      { input },
      { token: Cookies.get(TOKEN_COOKIE) }
    )

    if (!resp) {
      throw new Error('Error creating order')
    }

    // TODO: FIX TYPES
    // @ts-ignore
    this.setOrder(resp.createSale)
  }

  async createOrUpdateSale(amount: string, planId?: string, messageId?: string) {
    console.log('createOrUpdateSale()')
    console.log('createOrUpdateSale()')
    console.log('createOrUpdateSale()')
    console.log('createOrUpdateSale()')
    console.log('11111')
    const chainId = this.root.wallets.connected?.chainId
    console.log('2222')
    if (!chainId) throw new Error('No chainId found')

    console.log('3333')

    const address = this.root.wallets.connected?.address
    console.log('4444')
    if (!address) throw new Error('No address found')

    console.log('this.paymentToken')
    console.log('this.paymentToken')
    console.log('this.paymentToken')
    console.log(this.paymentToken)

    if (!this.paymentToken) throw new Error('No paymentToken set')

    console.log('5555', this.paymentToken)

    // this.setCheckoutState({ isProcessing: true })
    try {
      const input = {
        chainId: chainIdAsInt(chainId),
        signer: address,
        currency: AcceptedCurrencies.USD,
        amount: amount,
        tokenSymbol: this.paymentToken.symbol
      }

      console.log('input')
      console.log('input')
      console.log('input')
      console.log('input')
      console.log(input)

      if (this.order?.orderId) {
        // @ts-ignore
        await this.updateOrder({
          orderId: this.order.orderId,
          ...input
        })
      } else {
        // @ts-ignore
        await this.createSale({
          ...input,
          product: {
            postId: this.post ? this.post.postId : undefined,
            profileId:
              this.profile && this.paymentType === PaymentOptions.TIP_PROFILE
                ? this.profile.profileId
                : undefined,
            planId:
              planId && this.paymentType === PaymentOptions.SUBSCRIBE_PROFILE ? planId : undefined,
            messagePackId:
              messageId && this.paymentType === PaymentOptions.MESSAGE_PACK ? messageId : undefined,
            type: this.productType
          }
        })
        this.trackEvent(NSFW_EVENT.ORDER_SUMMARY)
      }

      // Ensure we have the allowance
      await this.checkAllowance(this.paymentToken.symbol)

      // Check if we have the required allowance
      this.resolveCheckoutStateFromAllowance()
    } catch (err) {
      console.log('Error creating order')
      console.log('Error creating order')
      console.log('Error creating order')
      console.log('Error creating order')
      console.log(err)
      toast({
        err,
        type: 'error',
        message: `Error ${this.order ? 'updating' : 'creating'} an order`
      })
    }
    this.setCheckoutState({ isProcessing: false })
  }

  async updateOrder(input: UpdateOrderInput) {
    const { error, data } = await mutation<{ updateOrder: Order }, MutationUpdateOrderArgs>(
      UPDATE_ORDER_MUTATION,
      { input }
    )

    if (error) {
      console.log(error)
      console.log('error updating order')
    }

    if (data) {
      console.log('updateOrder', data)
      this.setOrder(data?.updateOrder)
    }
  }

  async recordPayment(input: RecordPaymentInput) {
    const { error, data } = await mutation<
      { recordPayment: CryptoPayment },
      MutationRecordPaymentArgs
    >(RECORD_PAYMENT_MUTATION, { input })

    if (error) {
      console.log('error recording payment')
      console.log(error)
      throw error
    }

    if (data) {
      this.setPayment(data.recordPayment)
    }

    return this.payment
  }

  async confirmPayment(input: ConfirmPaymentInput) {
    const { error, data } = await mutation<
      { confirmPayment: CryptoPayment },
      MutationConfirmPaymentArgs
    >(CONFIRM_PAYMENT_MUTATION, { input })

    if (error) {
      console.log(error)
      console.log('error confirming order')
      throw error
    }

    if (data) {
      this.setPayment(data.confirmPayment)
      this.trackEvent(NSFW_EVENT.ORDER_COMPLETED)
    }

    return this.payment
  }

  async claimReferralReward(input: ClaimReferralRewardInput) {
    const { error, data } = await mutation<{ referral: Referral }, MutationClaimReferralRewardArgs>(
      CLAIM_REFERRAL_REWARD,
      {
        input
      }
    )

    if (error) {
      console.log(error)
      console.log('error claiming reward')
    }

    if (data) {
      console.log(data)
    }
  }

  private async _estimateTokens(input: ExchangeRateInput) {
    const { error, data } = await query<{ exchangeRate: ExchangeRate }, QueryExchangeRateArgs>(
      QUERY_EXCHANGE_RATE,
      {
        input
      }
    )

    if (error) {
      console.error('error estimating tokens', error)
    }

    if (data) {
      console.log('data.exchangeRate.to.amount')
      console.log('data.exchangeRate.to.amount')
      console.log('data.exchangeRate.to.amount')
      console.log('data.exchangeRate.to.amount')
      console.log(data.exchangeRate.to.amount)
      this.setEstimatedTokens(data.exchangeRate.to.amount)
      return this.estimatedTokens
    }
  }

  // ----- STATE
  setTransaction = async ({ post, profile, paymentType }: TransactionInitProps) => {
    this.post = post
    this.profile = profile
    this.paymentType = paymentType

    this.trackEvent(NSFW_EVENT.ORDER_CHECKOUT)
  }

  resolveCheckoutStateFromAllowance() {
    switch (true) {
      // TODO: allowance >= tokensRequired
      case this.allowance && Number(this.allowance) !== 0:
        return this.setCheckoutState({
          hasRequiredAllowance: true,
          isProcessing: false,
          buttonLabel: TransactionCTA.PAY,
          status: TransactionStatus.AWAITING_PAYMENT
        })
      case !this.allowance || Number(this.allowance) === 0:
        return this.setCheckoutState({
          isProcessing: false,
          buttonLabel: TransactionCTA.APPROVE_TOKENS,
          status: TransactionStatus.AWAITING_TOKENS_APPROVAL
        })
      default:
        break
    }
  }

  // Stores an Order from createOrder or updateOrder mutation
  setOrder(order: Order) {
    console.log('setOrder')
    console.log('setOrder')
    console.log('setOrder')
    console.log('setOrder')
    console.log('setOrder', order)
    this.order = order
    this.estimateTransfer()
  }

  setTransferEstimate(estimate: TransferEstimate) {
    this.transferEstimate = estimate
  }

  setPaymentToken(token: TokenOptions) {
    console.log('setPaymentToken()', toJS(token))
    this.paymentToken = token
  }

  setPayment(payment: CryptoPayment) {
    this.payment = payment
  }

  setCheckoutState(newState: Partial<CheckoutState>) {
    const oldState = this.checkout
    this.checkout = {
      ...oldState,
      ...newState
    }
  }

  reset() {
    this.checkout = {
      ...DEFAULT_CHECKOUT_STATE
    }
    this.order = undefined
    this.tokenBalance = undefined
    this.estimatedTokens = undefined
    this.trackEvent(NSFW_EVENT.ORDER_CANCELED)
  }

  /**
   * @deprecated, not really used in viem
   */
  async estimateTransfer() {
    const order = this.root.transaction.order
    if (!order) throw new Error('No current transaction order found')

    console.debug('estimateTransfer()', order.token.symbol)
    console.debug('estimateTransfer()', order.token.symbol)
    console.debug('estimateTransfer()', order.token.symbol)
    console.debug('estimateTransfer()', order.token.symbol)
    const chain = this.root.wallets.network
    if (!chain) throw new Error('No connected network found')

    const account = this.root.wallets.connected?.address as `0x${string}` | Account
    if (!account) throw new Error('No connected account found')

    const { address, abi, client } = await this.root.contractRegistry.instance(
      order.token.symbol,
      chain
    )

    const gas = await client.estimateContractGas({
      address: address,
      abi,
      functionName: 'transfer',
      args: [order.recipient, this.root.transaction.estimatedTokens],
      account
    })

    console.log('gas', gas)
    console.log('gas', gas)
    console.log('gas', gas)
  }
}
