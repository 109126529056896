import { gql } from 'graphql-request'
import { ApiMutationInput } from 'interfaces'

export const IS_USERNAME_VALID = gql`
  query IsUsernameValid($input: IsUsernameValidInput!) {
    isUsernameValid(input: $input) {
      isValid
      invalidCharacters
    }
  }
`

export type IsUsernameValidVars = ApiMutationInput<Record<'username', string>>

export interface IsUsernameValidResponse {
  isUsernameValid: {
    isValid: boolean
    invalidCharacters: string[]
  }
}
