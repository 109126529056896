import { gql } from '@urql/core'

// Sends an email to current address with a link to update email
export const CHANGE_EMAIL_MUTATION = gql`
  mutation ChangeEmail {
    changeEmail
  }
`

// Input for the new email address
export const UPDATE_EMAIL_MUTATION = gql`
  mutation UpdateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input)
  }
`

export const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input)
  }
`

export const CONFIRM_EMAIL_MUTATION = gql`
  mutation ConfirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input)
  }
`
