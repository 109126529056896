import { makeAutoObservable } from 'mobx'
import { RootStore } from './RootStore'
import { Chain } from 'viem'
import { polygon, polygonAmoy } from 'viem/chains'
import { listEnabledTokens } from 'config/tokens'
import { Network, TokenOptions } from '@nsfw-app/crypto'
import { isValidChain } from 'lib/web3/chains'
import { CHAINS } from 'config/chains'

export class NetworkStore {
  root: RootStore

  #withdrawalNetwork: Chain = process.env.NEXT_PUBLIC_NSFW_ENV === 'stage' ? polygonAmoy : polygon

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
  }

  listSupportedNetworks() {
    return Object.entries(CHAINS)
      .sort((a, b) => a[1].name.localeCompare(b[1].name))
      .map(([chainId, chain]) => ({
        id: chainId as unknown as Network,
        name: chain.name,
        symbol: chain.nativeCurrency.symbol
      }))
  }

  get isValidNetwork() {
    return isValidChain(this.root.wallets.chainId)
  }

  get walletNetwork() {
    console.log('walletNetwork()')
    console.log('WALLET NETWORK: ', this.root.wallets.network)

    return this.root.wallets.network
  }

  get withdrawalNetworkId() {
    return this.#withdrawalNetwork.id
  }

  get withdrawalNetwork() {
    return this.#withdrawalNetwork
  }

  get acceptedTokens() {
    console.log('acceptedTokens()', listEnabledTokens(this.root.network.walletNetwork))
    return listEnabledTokens(this.root.network.walletNetwork)
  }

  get defaultAcceptedToken() {
    console.log('defaultAcceptedToken()')
    console.log('defaultAcceptedToken()')
    console.log('defaultAcceptedToken()')
    console.log(this.acceptedTokens)

    const defaultToken = this.acceptedTokens.filter((token: TokenOptions) => {
      return token.isDefault
    })
    return defaultToken[0]
  }

  get gasToken() {
    return this.walletNetwork.nativeCurrency
  }
}
