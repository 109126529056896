import { gql } from '@urql/core'
import { CORE_POST_ASSET_FIELDS, CORE_PROFILE_FIELDS } from 'graphql/fragments'
import { Post } from 'models/Post'

export const FEED = gql`
  ${CORE_POST_ASSET_FIELDS}
  ${CORE_PROFILE_FIELDS}
  query Feed($type: FeedType!, $feedOpts: PaginationOptions) {
    feed(type: $type, options: $feedOpts) {
      hasNextPage
      cursor
      items {
        ... on Post {
          __typename
          type
          postId
          isVisible
          description
          likeCount
          profileId
          isLiked
          publishedAt
          type
          isSubscribed
          isOwner
          price
          commentCount
          status
          tags {
                key
                slug
                tagId
                value
                 }
          profile {
            ...ProfileParts
          }
          asset {
            ...PostAssetParts
          }
        }
      }
    }
  }
`

export interface FeedResponse {
  feed: {
    items: Post[]
    hasNextPage: boolean
    cursor: string
  }
}
