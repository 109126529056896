import { FEED_MAP } from 'config/routes'
import { FeedType, SortBy } from 'graphql/types'
import { getGqlApiError } from 'lib/gql/GqlApiError'
import { isServer } from 'lib/nextjs'
import { makeAutoObservable, reaction } from 'mobx'
import { PaginatedPosts } from './PaginatedPosts'
import { Post, PostProps } from './Post'
import { RootStore } from './RootStore'
import { SelectedProfile } from './SelectedProfile'

export type FeedBySort = `${FeedType}:${SortBy}` | FeedType

type Feeds = {
  [key in FeedBySort]?: PaginatedPosts
}

interface FeedStoreProps {
  selectedPost?: PostProps
  selectedProfileUsername?: string
  currentFeedType?: FeedBySort
}

export class FeedStore {
  root: RootStore

  // The currently selected or viewing feed type (i.e "following"). Hydrated from page query
  // string during data fetching.
  currentFeedType: FeedBySort = `${FeedType.LATEST}:${SortBy.RANDOM}`

  // Category activity feeds
  // todo: rename "feeds"
  items: Feeds = {}

  selectedProfile?: SelectedProfile = undefined

  // Setting post directly on FeedStore as currently no client loading/error state is required as
  // the post is being set for directly rendering in the post modal, or from SSR props.
  selectedPost?: Post = undefined

  constructor(root: RootStore, data: FeedStoreProps = {}) {
    this.root = root
    console.log('Initializing FeedStore...')
    makeAutoObservable(this)
    this.hydrate(data)

    reaction(
      () => this.root.user.account,
      (account, prevAccount) => {
        // unauthed user has become authed, rehydrate content as authed user
        if (!prevAccount && account) {
          // Reload content (post/feed images need unblurring etc)
          this.selectedPost?.reloadPost()
          this.selectedProfile?.resetFeed()
          // TODO: create a new method on feeds to rehydrate the full feed? if user is at page 100..
          // TODO: will need to paginate up/down from user current index and rehydrate items.
          this.resetFeeds()
        }
      }
    )
  }

  get currentFeedPath() {
    // @ts-ignore FIXME
    return FEED_MAP[this.currentFeedType]
  }

  get currentFeedStore() {
    return this.items[this.currentFeedType]
  }

  hydrate(props: FeedStoreProps = {}) {
    const { selectedPost, currentFeedType = `${FeedType.LATEST}:${SortBy.RANDOM}` } = props

    if (currentFeedType) {
      this.currentFeedType = currentFeedType
      this.items[currentFeedType] = new PaginatedPosts(this.root, {
        type: currentFeedType
      })
    }

    if (selectedPost !== undefined) {
      this.setSelectedPost(selectedPost)
    }

    this.clientHydrate(props)
    return this
  }

  private clientHydrate({ selectedProfileUsername }: FeedStoreProps) {
    if (isServer()) return

    if (this.items[this.currentFeedType] instanceof PaginatedPosts) {
      this.items[this.currentFeedType]?.fetchFeed()
    }

    if (selectedProfileUsername) {
      this.fetchProfileFeed(selectedProfileUsername)
    }

    if (this.selectedPost?.comments.isEmpty) {
      this.selectedPost.fetchComments()
    }
  }

  async resetCurrentFeed() {
    await this.items[this.currentFeedType]?.resetFeed()
  }

  async resetFeeds(type?: FeedType) {
    if (type) {
      await Promise.all(
        Object.entries(this.items).map(([key, feedTypeStore]) => {
          if (key.includes(type)) {
            return feedTypeStore.resetFeed()
          }
        })
      )
    } else {
      await Promise.all(
        Object.entries(this.items).map(([_, feedTypeStore]) => feedTypeStore.resetFeed())
      )
    }
  }

  setSelectedPost(post: Post | PostProps | null) {
    if (post) {
      if (post instanceof Post) {
        this.selectedPost = post
        if (this.selectedPost.feedPositionIndex) {
          this.selectedPost.parentFeed?.setScrollIndex(this.selectedPost.feedPositionIndex)
        }
      } else {
        this.selectedPost = new Post(post, this.root)
      }
    } else {
      this.selectedPost = undefined
    }
    return this.selectedPost
  }

  async fetchProfileFeed(username: string) {
    console.log('fetchProfileFeed(:username)', username)

    this.selectedProfile = new SelectedProfile({ isFetching: true, username })

    try {
      let instance = this.root.profiles.getByUsername(username)

      // Instance may be not previously loaded, or loaded without posts data.
      if (!instance?.posts.items.length) {
        instance = await this.root.profiles.fetch(username, {
          postOptions: {
            limit: 12
          }
        })
      }
      this.selectedProfile.profile = instance
    } catch (err) {
      console.warn('Load profile feed error')
      console.error('Load profile feed error', err)
      this.selectedProfile.error = getGqlApiError(err)
    }
  }
}
