import { gql } from '@urql/core'

export const MESSAGE_SETTINGS = gql`
  query ViewProfile($profileId: String) {
    viewProfile(profileId: $profileId) {
      username
      profileId
      accountType
      avatar {
        url
      }
      messaging {
        rateId
        rate
        unit
        audience
      }
    }
  }
`
