import { gql } from 'urql'

export const CORE_COMMENT_FIELDS = gql`
  fragment CommentParts on Comment {
    __typename
    commentId
    createdBy
    createdAt
    text
    postId
    author {
      displayName
      username
      avatar {
        url
      }
    }
  }
`
