import { parse } from 'graphql'
import { gql } from 'graphql-request'
import { IS_EMAIL_AVAILABLE, IS_USERNAME_AVAILABLE } from 'graphql/queries'
import { IS_USERNAME_VALID } from 'graphql/queries/isUsernameValid'
import {
  AccountType,
  CreatorOnboarding,
  IsEmailAvailableQuery,
  IsUsernameAvailableQuery,
  IsUsernameValidQuery,
  VerifyEmailMutation
} from 'graphql/types'
import { request } from 'lib/gql/client'
import { getCookie, isBrowser, setCookie } from 'lib/nextjs'
import { makeAutoObservable, reaction } from 'mobx'
import { Button } from 'primitives/index'
import { AppBannerKeys } from 'shared/Banner/types'
import { RootStore } from './RootStore'

const ACCEPTED_AGE_DISCLAIMER_COOKIE = 'acceptedAgeDisclaimer'

const CREATOR_KEYS = [
  'updatedBio',
  'setupMessaging',
  'createdSubscriptionPlan',
  'uploadedFirstPost'
  // 'firstWithdrawal'
]

// FIXME: Get the correct fan keys
const FAN_KEYS = [
  'setupMessaging',
  'updatedBio',
  'uploadedAvatar',
  'createdSubscriptionPlan',
  'uploadedFirstPost',
  'firstWithdrawal'
]

export class OnboardingStore {
  root: RootStore

  isFetching = false

  /**
   * The account object for the current user
   */
  user = undefined

  /**
   * Flag to show/hide the creator onboarding modal
   */
  showOnboardingModal = false

  /**
   * The index of the current active screen in the onboarding modal
   */
  activeScreenIndex = 0

  /**
   * Flag to show/hide the 18+ disclaimer modal
   */
  showAgeDisclaimerModal = getCookie(ACCEPTED_AGE_DISCLAIMER_COOKIE) ? false : true

  gettingStartedItems: { key: string; value: boolean }[] = []

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)

    reaction(
      () => this.root.user.onboarding?.data as CreatorOnboarding,
      (onboardingData: CreatorOnboarding) => {
        if (!onboardingData) return

        // Only show the Continue oboarding banner, when it is not open
        if (this.root.user.requiresKYCApproved && !this.showOnboardingModal) {
          this.continueOnboardingBanner()
        } else if (this.root.user.requiresKYCApproved && onboardingData.completedOnboarding) {
          this.completeOnboardingBanner()
        }
      },
      { fireImmediately: isBrowser() }
    )
  }

  calculateGettingStarted() {
    const keys = this.root.user.isCreator ? CREATOR_KEYS : FAN_KEYS
    const data = this.root.user.onboarding?.data

    this.gettingStartedItems = Object.entries(data ?? {})
      .filter(([key, _value]) => keys.includes(key))
      .map(([key, value]) => ({ key, value }))
  }

  get isGettingStartedComplete() {
    return this.gettingStartedItems.every((o) => o.value)
  }

  setActiveIndex(index: number) {
    this.activeScreenIndex = index
  }

  completeOnboardingBanner() {
    this.root.ui.displayBanner({
      key: AppBannerKeys.COMPLETE_ONBOARDING,
      variant: 'success',
      title: '❇️ Congratulations! You account has been verified!',
      actionLabel: 'Whats next?',
      action: () => {
        this.completeApplication()
      }
    })
  }

  getStartedBanner() {
    this.root.ui.displayBanner({
      key: AppBannerKeys.GET_STARTED,
      title: 'Complete some simple tasks to learn more about the platform.',
      action: () => {
        this.root.ui.openGettingStartedModal()
      }
    })
  }

  continueOnboardingBanner() {
    const status = this.root.user?.onboarding?.status

    if (status === 'PENDING') {
      this.root.ui.displayBanner({
        key: AppBannerKeys.KYC_APPROVAL,
        title: 'Your KYC application is currently being processed.',
        action: () => {
          this.openOnboardingModal()
        }
      })
      return
    }

    // Default banner
    this.root.ui.displayBanner({
      key: AppBannerKeys.KYC_APPROVAL,
      variant: 'error',
      title: 'Your creator application is incomplete.',
      action: () => {
        this.root.onboarding.openOnboardingModal()
      }
    })
  }

  openOnboardingModal() {
    // Hide the banner
    this.root.ui.hideBanner(AppBannerKeys.KYC_APPROVAL)
    this.showOnboardingModal = true
  }

  closeOnboardingModal() {
    if (this.root.user.requiresKYCApproved) {
      this.continueOnboardingBanner()
    }

    if (!this.root.onboarding.isGettingStartedComplete) {
      this.getStartedBanner()
    }

    this.showOnboardingModal = false
  }

  acceptAgeDisclaimerModal() {
    setCookie(ACCEPTED_AGE_DISCLAIMER_COOKIE, 'true')
    this.showAgeDisclaimerModal = false
  }

  async canClaimUsername(username: string): Promise<boolean> {
    const [isAvailable, isValid] = await Promise.all([
      this.isUsernameAvailable(username),
      this.isUsernameValid(username)
    ])

    if (!isAvailable) {
      throw new Error('Username is not available')
    }

    if (!isValid) {
      throw new Error('Username is not valid')
    }

    return isAvailable && isValid
  }

  private async isUsernameAvailable(username: string): Promise<boolean> {
    const resp = await request<IsUsernameAvailableQuery>(
      parse(IS_USERNAME_AVAILABLE),
      {
        input: { username }
      },
      { token: this.root.user.token }
    )
    return resp?.isUsernameAvailable
  }

  private async isUsernameValid(username: string): Promise<boolean> {
    const resp = await request<IsUsernameValidQuery>(
      parse(IS_USERNAME_VALID),
      {
        input: { username }
      },
      { token: this.root.user.token }
    )
    return resp?.isUsernameValid.isValid
  }

  async isEmailAvailable(email: string): Promise<boolean> {
    const resp = await request<IsEmailAvailableQuery>(
      IS_EMAIL_AVAILABLE,
      {
        input: { email }
      },
      { token: this.root.user.token }
    )
    return resp?.isEmailAvailable
  }

  async verifyEmail(email: string) {
    const resp = await request<VerifyEmailMutation>(
      parse(gql`
    mutation VerifyEmail($input: VerifyEmailInput!) {
      verifyEmail(input: $input)
    }
    `),
      {
        input: { email }
      },
      { token: this.root.user.token }
    )

    return resp.verifyEmail
  }

  async startApplication(type: AccountType) {
    const resp = await request(
      gql`
      mutation StartApplication($input: StartApplicationInput!) {
        startApplication(input: $input)
      }
    `,
      {
        input: {
          type: type.toUpperCase()
        }
      },
      { token: this.root.user.token }
    )

    console.log('----- ONBOARDING_FIELDS ----->')
    console.log(resp)

    // Refetch the user account (with the new relevant onboarding state)
    await this.root.user.fetchInitialAccount()

    return this.user
  }

  async completeApplication() {
    await request(
      gql`mutation CompleteApplication {
        completeApplication
      }`,
      {},
      { token: this.root.user.token }
    )

    // Refetch the user account (with the new relevant onboarding state)
    await this.root.user.fetchInitialAccount()

    return this.user
  }

  async acceptTermsOfService() {
    const resp = await request(
      gql`
      mutation UpdateOnboarding ($step: String!) {
        updateOnboarding(step: $step)
      }
    `,
      {
        step: 'accept-terms'
      },
      { token: this.root.user.token }
    )

    console.log('----- AcceptTermsOfService ----->')
    console.log(resp)

    // Refetch the user account (with the new relevant onboarding state)
    await this.root.user.fetchInitialAccount()

    return this.user
  }
}
